var MCMApp = MCMApp || {};

MCMApp.Pages = MCMApp.Pages || {};

MCMApp.Pages.UsersOverviewModel = Backbone.Model.extend({
    defaults: {
        searchingNow: false
    }
});
MCMApp.Pages.UsersOverviewView = Backbone.View.extend({

    formId: '#user-overview__search',


    events: {
        "click .user-overview__filter-roles li": "toggleListItems",
        "submit #user-overview__search": "searchSubmitted",
        "keyup #inputUserSearch": "searchKeyupListener"
    },

    initialize: function(options) {

        this.options = options || {};

        // bind to API event
        this.model.on('change:searchingNow', function(model) {
            if (model.get('searchingNow')) {
                $('#user-overview__lists').addClass('is-searching-now');
            } else {

                $('#user-overview__lists').removeClass('is-searching-now');
            }
        });
    },

    toggleListItems: function(e) {
        var $el = $(e.currentTarget);

        var role = $el.data('role');

        $('.user-overview__filter-roles li').removeClass('current-item');
        $el.addClass('current-item');


        if (role === "all") {

            //this means all
            $(".user-overview__list").removeClass("hidden");
        } else {
            $(".user-overview__list").each(function() {

                $(this).addClass("hidden");
            });
            $('#user-overview-list--' + role).removeClass('hidden');
        }
    },

    searchKeyupListener : function(e){
        var $el = $(e.currentTarget);
        var _this = this;

        //check if keyup timer running
        var keyupTimer = this.model.get('keyupTimer');

        //if running stop it first
        if(keyupTimer) {
            window.clearTimeout(keyupTimer);
            this.model.set('keyupTimer',false);

        }

        //run it again with nwe query
        this.model.set('keyupTimer',setTimeout(function(){
            _this.search($('#inputUserSearch').val());
        },500));

    },

    resetListItemFilters : function(){
        $('.user-overview__filter-roles li').removeClass('current-item');
        $('.user-overview__filter-roles--all').addClass('current-item');

        $(".user-overview__list").removeClass("hidden");
    },

    searchSubmitted: function(e) {
        var $el = $(e.currentTarget);
        e.preventDefault();

        if(this.model.get('searchingNow') === true) {
            return;
        }

        var value = $('#inputUserSearch').val();

        this.search(value);
    },


    search: function(value) {
        var _this = this;
        var ajaxUrl = $(this.formId).attr('action');
        var keyword = $('#inputUserSearch').val();
        keyword = encodeURIComponent(keyword);

        _this.model.set('searchingNow', true);

        $.post(ajaxUrl + '/' + keyword + '/', {}, function(response) {
            _this.model.set('searchingNow', false);
            $('#user-overview__lists').html(response);
            _this.resetListItemFilters();
        });

    }

});

if ($("#users-overview").length) {

    MCMApp.Pages.UsersOverview = new MCMApp.Pages.UsersOverviewView({
        "el": '#users-overview',
        'model': new MCMApp.Pages.UsersOverviewModel()
    });
}

var MCMApp = MCMApp || {};

MCMApp.Templates = MCMApp.Templates || {};


MCMApp.Templates.Users = {
    edit : function () {
        return new Vue({
            el : '#add-area-form-container',
            data : {
                changePassword : false,
                newPassword : ''
            },
            methods : {
                checkForm : function(e){
                    var $el = $(e.currentTarget);

                    if( this.changePassword && this.newPassword && this.newPassword.length < 8 ) {
                        alert('Password should be at least 8 characters');
                        e.preventDefault();
                    }
                }
            }
        });
    }
};
/**
 * manages meeting list item on modal calendar
 */

Vue.component('adboard-group-calendar-touchpoint', {
    template: '#adboard-group-calendar-touchpoint-template',
    props: {
        touchpoint: {
            type: Object,
            required: true
        },
        user: {
            type: Object,
            required: true
        }
    },
    created() {

        McmEventBus.$on('calendarMeetingDetailDisplayed', (touchpoint) => {

            console.log(touchpoint.id, this.touchpoint.id);
            if (this.touchpoint.id !== touchpoint.id) {
                this.meetingDisplayed = false;
            } else {
                this.meetingDisplayed = true;
            }
        });

        let invitation = this.getCurrentUserInvitation();
        this.status = (invitation.invitation_status);
    },
    data: function () {
        return {
            meetingDisplayed: false,
            status: 1
        };
    },

    computed: {
        statusInputName() {
            return 'status-' + this.touchpoint.id;
        },
        currentUserInvitation() {
            return this.getCurrentUserInvitation();
        }
    },

    watch: {
        meetingDisplayed(val, oldVal) {
            if (val) {
                McmPlatformMainElementsInit();
            }
        },
        touchpoint(val, oldVal) {
            let invitation = this.getCurrentUserInvitation();
            this.setStatus(invitation.invitation_status);
        },

    },
    methods: {
        toggleMeeting() {
            this.meetingDisplayed = !this.meetingDisplayed;
            if (this.meetingDisplayed) {
                McmEventBus.$emit('calendarMeetingDetailDisplayed', this.touchpoint);
            }
        },
        setStatus(status) {
            this.status = status;

            $.ajax({
                url: SiteVariables.urls.TouchpointInvitationsControllerHandler,
                type: 'PUT',
                data: {
                    touchpoint_id: this.touchpoint.id,
                    status: this.status
                },
                dataType: 'json',
                success: (data) => {

                    McmEventBus.$emit('AdboardGroupCalendarMeetingsRefreshNeeded');
                },
                error: (data) => {

                }
            });
        },
        getCurrentUserInvitation() {

            let invitations = this.touchpoint.touchpoint_invitations.filter((invitation) => {

                return invitation.user.id === this.user.id;
            });
            return _.first(invitations);
        }
    }
});
// Vue filter settings
//-------------------------------------------------------------


// Return moment from now
//-------------------------------------------------------------

Vue.filter('momentFromNow', function (value, input) {
    return moment(value).fromNow();
});


// Return moment format
//-------------------------------------------------------------
Vue.filter('momentFormat', function (value, input) {
    return moment(value).format(input);
});


// Format in 26 April 2017 16:33
//-------------------------------------------------------------
Vue.filter('dateFormatDateHour', function (value) {
    var dateObj = moment(value);
    if (dateObj.isValid()) {
        return dateObj.format('DD-MMMM-YYYY h:mm');
    }
    return null;
});


// Return date object for the format like (15 September 2016)
//-------------------------------------------------------------

Vue.filter('dateHumanFormat', function (value) {
    var dateObj = moment(value);
    if (dateObj.isValid()) {
        return dateObj.format('DD-MMMM-YYYY');
    }
    return null;
});


// Return date picker format
//-------------------------------------------------------------

Vue.filter('datePickerFormat', {
    // model -> view
    // formats the value when updating the input element.
    read: function (val) {
        if (val === '' || moment(val).isValid()) {
            return moment(val).format('DD-MM-YYYY');
        }
        return val;
    },
    // view -> model
    // formats the value when writing to the data.
    write: function (val, oldVal) {
        return val;
    }
});
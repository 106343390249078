/**
 * Accreditations form for hcp's to make
 */
Vue.component('touchpoint-accreditation-view', {
    template: '#touchpoint-accreditation-view-template',
    props: {
        url: {
            type: String,
            required: false
        },
        accreditationType: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            exam: {},
            loading: false,
            active: false,
            type: null,
            finished: false,
            redirect: '',
            validation: false,
            exceededAttempts: false,
            currentIndexQuestion: 0,
            score: {
                status: false,
                amount: 0
            },
            attempts: {
                post: 0,
                total: 0,
                reset: false
            },
            questions: {
                total: 0,
                selected: 0
            },
            error: {
                status: false,
                login: false,
                connection: false
            }
        };
    },
    computed: {

        /**
         * Count remaining attempts
         *
         * @returns {number}
         */
        remainingAttempts(){
            return 3 - this.attempts.post;
        },


        /**
         * Check if the current instance is polls
         *
         * @return {boolean}
         */
        pollsActive(){
            return this.type === 'polls';
        },


        /**
         * Check if the current instance is post exams
         *
         * @return {boolean}
         */
        postActive(){
            return this.type === 'post';
        },


        /**
         * Check if the current instance is pre exams
         *
         * @return {boolean}
         */
        preActive(){
            return this.type === 'pre';
        },


        /**
         * Check if opts are selected, this is used before submit
         *
         * @return {boolean}
         */
        allOptsSelected(){
            if (!this.pollsActive) {
                return this.questions.total === this.questions.selected;
            } else {
                return this.questions.selected > 0;
            }
        },


        /**
         * Check if exam passed and it is a post exams
         *
         * @todo: make the score dynamic
         *
         * @return {boolean}
         */
        passedAndPostActive(){
            const score = 70;

            return Boolean(this.score.amount > score && this.postActive);
        },

        /**
         * closePollModal Method
         */
        closePollModal(){
            McmEventBus.$emit('ondemandPollQuestionReplied', this.exam.accreditation_questions[this.currentIndexQuestion]);
            MCMApp.Modals.appModal.close();
        }
    },
    created() {
        if (this.url && this.type !== 'polls') {
            this.loadAccreditationData(this.url);
        }
    },
    events: {

        /**
         * Update selected answers count, this is checked with the total amount of questions before submitting
         * otherwise it will release an error
         */
        answerSelectedQuestion(index){
            this.validation = false;
            this.questions.selected += 1;

            this.$broadcast('timelineSelectedQuestion', index);
        },

        /**
         * Update current question
         * @param index
         */
        selectQuestionFromTimeline(index) {
            this.currentIndexQuestion = index;
            this.$broadcast('showQuestion', index);
        }
    },
    methods: {

        /**
         * Load accreditation data with a url given
         *
         * @param url
         */
        loadAccreditationData(url) {
            this.loading = true;

            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success: (data) => {
                    this.handleAttempts(data);
                    this.setLoadingData(data);
                    this.loading = false;
                },
                error: (data) => {
                    let errorMessage = data.responseJSON.message;

                    if (errorMessage === 'sorry, you need a higher permission to do this action') {
                        this.handleResponse('login');
                    } else {
                        this.handleResponse('connection');
                    }

                    this.loading = false;
                }
            });
        },


        /**
         * Submit accreditation form to its controller
         *
         * @param e
         */
        submitAccreditation(e) {
            e.preventDefault();

            if (!this.pollsActive && this.validation) {
                return false;
            }

            if (this.allOptsSelected) {
                const form = $('#accreditation--form');

                this.validation = false;
                this.loading = true;
                this.currentIndexQuestion = 0;

                $.ajax({
                    url: form.attr('action'),
                    type: form.attr('method'),
                    data: form.serialize(),
                    dataType: 'json',
                    success: (data) => {
                        if (data.status) {
                            if (this.pollsActive) {
                                this.finished = true;
                            } else {
                                this.setData(data);
                            }
                        } else {
                            this.handleResponse('connection');
                        }

                        this.loading = false;
                    },
                    error: () => {
                        this.handleResponse('connection');

                        this.loading = false;
                    }
                });
            } else {
                this.validation = true;
            }
        },

        /**
         * Handle error & success responses
         *
         * @param type
         * @param active
         */
        handleResponse(type, active = true){
            this.error.status = this.error[type] = active;
        },


        /**
         * Append a confirm message if user wants to skip the exams
         *
         * @param message
         */
        checkIfSkip(message){
            if (confirm(message)) {
                window.location = this.redirect;
            }
        },


        /**
         * Set data after submitting a form
         *
         * @param data
         */
        setData(data){
            this.finished = data.finished > 0;
            this.type = data.type;
            this.active = true;

            this.handleAttempts(data);
            this.handleScore(data);
        },


        /**
         * Handle accreditation based attempts
         *
         * @param data
         *
         */
        handleAttempts(data){
            this.attempts.post = data.attempts.post;
            this.attempts.total = data.attempts.total;
            this.attempts.reset = data.attempts.reset;

            let postAttempts = this.attempts.post;
            let reset = this.attempts.reset;

            if (postAttempts === 3 && reset !== true) {
                this.exceededAttempts = true;
            }
        },


        /**
         * Handle scores and set data
         *
         * @param data
         */
        handleScore(data){
            this.score.amount = data.resultScore;
            this.score.status = true;

            if (this.passedAndPostActive) {
                this.finished = true;
            }

            this.resetDataValues();
        },


        /**
         * Reset data values to reset the form
         */
        resetDataValues(){
            this.questions.selected = 0;

            this.$broadcast('resetValues');
        },


        /**
         * Handle exam data or show error otherwise
         *
         * @param data
         */
        handleExamData(data){
            if (!_.isEmpty(data.exam)) {
                this.exam = data.exam;
                this.questions.total = this.exam.accreditation_questions.length;
            } else {
                this.handleResponse('connection');
            }
        },


        /**
         * Set loading data being loaded
         *
         * @param data
         */
        setLoadingData(data){
            if (data.finished) {
                this.finished = true;
            } else {
                this.handleExamData(data);
            }

            if (data.redirect && data.redirect.length > 0) {
                this.redirect = data.redirect;
            }

            this.type = data.type;
        },


        /**
         * Show next question on next
         */
        showQuestion(type){
            if (type === 'next') {
                this.currentIndexQuestion++;
            }

            if (type === 'prev') {
                this.currentIndexQuestion--;
            }

            this.$broadcast('showQuestion', this.currentIndexQuestion);
        }

    },
});


/**
 * Accreditation question component
 */
Vue.component('touchpoint-accreditation-question', {
    template: '#touchpoint-accreditation-question-template',
    props: {
        question: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    data(){
        return {
            answerSelected: false,
            showQuestionIndex: 0
        };
    },
    computed: {

        /**
         * Hidden inputs with the question id
         *
         * @returns {string}
         */
        hiddenPollInput() {
            return '<input type="hidden" name="' + this.index + '[poll_id]" value="' + this.question.id + '" />';
        }
    },
    events: {

        /**
         * Set if a answers is selected for a questions
         */
        updateAnswersSelected(){
            if (!this.answerSelected) {
                this.answerSelected = true;

                this.$dispatch('answerSelectedQuestion', this.index);
            }
        },


        /**
         * Reset values to reset the form
         */
        resetValues(){
            this.answerSelected = false;
        },


        /**
         * Increment index of the question to not be hidden
         * @param index
         */
        showQuestion(index){
            this.showQuestionIndex = index;
        }
    }
});


/**
 * Accreditation answer component
 */
Vue.component('touchpoint-accreditation-answer', {
    template: '#touchpoint-accreditation-answer-template',
    props: {
        answer: {
            type: Object,
            required: true
        },
        pollIndex: {
            type: Number,
            required: true
        },
        answerIndex: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            selectedAnswer: undefined
        };
    },
    ready() {
        McmPlatformMainElementsInit();
        McmInitEditor();
    },
    computed: {

        /**
         * Name attribute generated
         *
         * @returns {string}
         */
        answerName() {
            return this.pollIndex + '[answer_id]';
        }
    },
    watch: {

        /**
         * Update if a answer has been selected, This is needed for validation in order to check if user
         * answerd all of the questions
         *
         * @param val
         * @param oldVal
         */
        selectedAnswer(val, oldVal) {
            if (this.checkSelectedAnswerValue(val)) {
                this.$dispatch('updateAnswersSelected');
            }
        }
    },
    methods: {

        /**
         * checkSelectedAnswerValue Method
         *
         * Return a value based on the value passed to check if a answer is selected
         *
         * @param val
         * @returns {boolean}
         */
        checkSelectedAnswerValue(val) {
            return val !== undefined && typeof val !== 'undefined' && typeof val === 'number';
        }
    }
});


/**
 * Timeline component
 */
Vue.component('touchpoint-accreditation-timeline', {
    template: '#touchpoint-accreditation-timeline-template',
    props: {
        index: {
            type: Number,
            required: true
        },
        currentQuestionIndex: {
            type: Number,
            required: true
        }
    },
    data(){
        return {
            selectedQuestionIndex: []
        };
    },
    events: {

        /**
         * Update selected question in the timeline
         */
        timelineSelectedQuestion(index){
            this.selectedQuestionIndex.push(index);
        }
    },
    computed: {

        /**
         * Check on selected question
         * @returns {boolean}
         */
        selectedQuestion(){
            return this.selectedQuestionIndex.indexOf(this.index) !== -1;
        },

        isCurrentQuestion()
        {
            return (this.index === this.currentQuestionIndex);
        },

        currentState(){
            //current q but not answered yet, show color bg white nmbr
            if (this.isCurrentQuestion && !this.selectedQuestion) {
                return {
                    bg: 'background-primary',
                    color: 'white-color',
                    number: true
                };
            }
            //curr. q and answered: show white bg  and colored tick
            if (this.isCurrentQuestion && this.selectedQuestion) {
                return {
                    bg: '',
                    color: 'text-primary',
                    number: false
                };
            }
            //not current q and not answered
            if (!this.isCurrentQuestion && !this.selectedQuestion) {
                return {
                    bg: '',
                    color: 'text-primary',
                    number: true
                };
            }
            //not current q and answered
            if (!this.isCurrentQuestion && this.selectedQuestion) {
                return {
                    bg: 'background-primary',
                    color: 'white-color',
                    number: false
                };
            }
        }
    },
    methods: {

        /**
         * Toggle questions
         */
        selectQuestion(){
            this.$dispatch('selectQuestionFromTimeline', this.index);
        }
    }
});
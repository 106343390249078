/**
 * Show toggle button on the sidebar for meetingpoint
 */

Vue.component('toggle-button-on-sidebar', {
    template: '#toggle-button-on-sidebar',
    props: {
        section : String,
        image :String,
        icon: {
            default: false,

        },
    },
    computed: {
        displayed: function () {
            if (this.$parent.currentStep === this.section) {
                return true;
            }
            return false;
        },
        imgSrc : function () {
            if(false === this.icon) {
                return '/img/meetingpoint/'+this.image+'.png';
            }
            return '';
        }
    },
    methods: {
        toggleSection: function (sectionName) {
            this.$dispatch('toggleSection', sectionName);
        }
    }
});
// Vue touchpoint mixin
// -- Generic touchpoint mixin for holding and updating data
//-------------------------------------------------------------

var MCMApp = MCMApp || {};

MCMApp.touchpointProjectUsersMixin = {
    computed: {
        involvedUserIds: function () {
            if (this.touchpoint.project.involved_users) {
                return _.pluck(this.touchpoint.project.involved_users, 'id');
            }
            return '';
        },
        informedUserIds: function () {
            if (this.touchpoint.project.informed_users) {
                return _.pluck(this.touchpoint.project.informed_users, 'id');
            }
            return '';
        }
    }
};
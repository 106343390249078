Vue.component('video-platform-hcp-messages', {
    template: '#video-platform-hcp-messages-template',
    props: {
        actionUrls: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            messages: false,
            activeMessageId: '',
            showReply: false,
            enableReplySubmit: false,
            search: '',
            loading: true,
            sendingReply: false,
            editorReplyMessage: ''
        };
    },
    ready() {
        this.fetchMessages();
    },
    computed: {
        activeMessage(){
            if(this.activeMessageId === ''){
                this.activeMessageId = this.messages[0].id;
                if(!this.messages[0].seen){
                    this.setSeen(this.activeMessageId);
                }
                return this.messages[0];
            } else {
                let _this = this;
                return this.messages.filter(function(obj){return obj.id === _this.activeMessageId;})[0];
            }
        },
        countUnreadMessages(){
            return this.messages.filter(function(obj){return !obj.seen;}).length;
        },
        filterdMessages(){
            return this.messages.filter((obj) => {
                return obj.user.fullname.toLowerCase().match(this.search.toLowerCase()) || obj.user.email.toLowerCase().match(this.search.toLowerCase());
            });
        }
    },
    watch: {
        showReply() {
            if(this.showReply){
                this.initEditor();
                $('html,body').animate({
                    scrollTop: $('.leave-reply-wrapper').offset().top - 80
                }, 1000);

            } else {
                this.enableReplySubmit  = false;
            }
        }
    },
    methods: {
        fetchMessages() {
            $.get(this.actionUrls.get, {}, (response) => {
                if (response.success === true) {
                    this.messages = response.data;
                    this.loading = false;
                    // console.log(response.data);
                }
            });
        },
        setSeen(id) {
            $.post(this.actionUrls.seen, {message_id: id}, (response) => {
                if (response.success === true) {
                    let currantMessage = this.messages.filter(function(obj){return obj.id === id;})[0];
                    currantMessage.seen = true;

                }
            });
        },
        sliceMessage(text, n){
            return text.slice(0, n);
        },
        formatDate(date){
            let beforeHalfDay = moment().subtract(12, 'hours');
            if( beforeHalfDay < moment(date)){
                return moment(date).format('LT') + ' (' + moment(date).fromNow() + ' )';
            } else {
                return moment(date).format('DD/MM/YYYY') + ' - ' + moment(date).format('LT');
            }
        },
        setActiveMessageId(id, seen){
            this.showReply = false;
            this.activeMessageId = id;
            if(!seen){
                this.setSeen(id);
            }
        },
        isActiveMessage(id){
            return this.activeMessageId === id;
        },
        showReplyContent(){
            this.showReply = !this.showReply;
        },
        initEditor(){
            let _this = this;
            $('.editor-textarea').trumbowyg({
                btns: [
                    ['viewHTML', 'formatting', 'removeformat'],
                    ['link'],
                    'btnGrp-semantic',
                    'btnGrp-justify',
                    'btnGrp-lists'
                ],
                svgPath: '/components/trumbowyg/dist/ui/icons.svg',
                removeformatPasted: true,
                autogrow: true
            }).on('tbwchange', function(){
                if($(this).val().length === 0){
                    _this.enableReplySubmit  = false;
                } else{
                    _this.editorReplyMessage = $(this).val();
                    _this.enableReplySubmit  = true;
                }
            });
        },
        sendReply(id) {
            if(this.enableReplySubmit){
                let _this = this;
                this.sendingReply = true;
                let message = this.editorReplyMessage;
                let currantMessageRepliedDate = new Date();
                let currantMessageById = this.messages.filter(function(obj){return obj.id === id;})[0];
                $.post(this.actionUrls.reply, {message_id: id, reply: message}, (response) => {
                    if (response.success === true) {
                        currantMessageById.reply = message;
                        currantMessageById.modified = currantMessageRepliedDate;
                        _this.sendingReply = false;
                    }
                });
            }


        }
    },
});

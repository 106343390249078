var MCMApp = MCMApp || {};

MCMApp.Forms = MCMApp.Forms || {};

MCMApp.Forms.HcpRegisterFormViewClass = Backbone.View.extend({

    events: {
        "submit form": "formSubmit",
        "click .switch-optin-content": "toggleOptinContents"

    },

    initialize: function () {
        $('#hcp-tooltip-trigger').attr('title', $('#hcp-registration-tooltip-content').html());

        //init material
        $.material.init();

        this.addDefaultToCountries();


        $('.has-tooltip').tooltip({
            template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'

        });

        //phone numbers
        $('.is-phone-number').mask('+99-999-999-9999', {});


    },

    addDefaultToCountries: function () {
        $('#country-id').prepend('<option value="0"></option>').val("0");
    },

    toggleOptinContents: function (e) {
        e.preventDefault();

        $('.hcp-register__half-optin-content,.hcp-register__full-optin-content').toggleClass('hidden');
    },


    formSubmit: function (e) {
        var _this = this;
        var $form = $(e.currentTarget);
        window.emailSent = $form.find("input[name=email]").val();

        //check for domains here for email
        //if whitelisted domains present check for
        if (whitelistedEmailDomains && whitelistedEmailDomains.length) {
            var finalWhitelistedEmailDomains = whitelistedEmailDomains.filter(function (emailDomain) {
                var regex = new RegExp(emailDomain + "$");
                console.log(regex.test(window.emailSent), emailDomain, window.emailSent);
                return regex.test(window.emailSent);
            });
            console.log('WHITELISTED', finalWhitelistedEmailDomains);

            if (finalWhitelistedEmailDomains.length === 0) {
                alert($('.whitelistedEmailAlertMessage').text());
                return false;
            }
        }


        if (!$form.hasClass('remote-registration-form')) {
            e.preventDefault();
        } else {
            return;
        }

        //check for country
        if ($('#country-id').val() == "0") {
            const selectCountryMsg = `<i class="fa fa-times"></i> ${SiteVariables.publicTranslations.pleaseSelectCountry}`;
            $form.find('#message-box').html(MCMApp.Helpers.Alerts.warning(selectCountryMsg));
            return false;
        }


        $form.find('.submit-button').addClass('hidden');

        // display loading message
        const sendingMsg = `<i class="fa fa-spinner fa-spin"></i> ${SiteVariables.publicTranslations.sending}...`;
        $form.find('#message-box').html(MCMApp.Helpers.Alerts.info(sendingMsg));


        $.post($form.attr('action'), $form.serialize(), function (response) {
            //response = $.parseJSON(response);
            if (response.newRecord === true) {
                var successMessage = "Successfully sent! You will receive an email after the approval process.";

                var $messageHtml = ($('#hcp-register-success-container').length) ? $('#hcp-register-success-container').html() : false;

                if ($messageHtml) {
                    $form.html($messageHtml);
                } else {
                    $form.html('<div class="message-box text-center"></div>');

                    var mgs = '<span class="message-box__tick"><i class="fa fa-check"></i></span>';

                    mgs += `<h3>${SiteVariables.publicTranslations.youHaveRegisteredForThisEvent}</h3>
                                <p>${SiteVariables.publicTranslations.confirmationEmailSent}</p>`;

                    $form.find('.message-box').html(mgs);
                }


                McmEventBus.$emit('UserRegisteredToTouchpointViaForm');

                MCMApp.Modals.appModal.closeAfter(10000);
            } else {
                $form.find('#message-box').html(MCMApp.Helpers.Alerts.danger('<i class="fa fa-times"></i> ' + SiteVariables.publicTranslations.alreadyRegisteredToEvent));

                $form.find('.submit-button').removeClass('hidden');
            }
        });
    }


});



Vue.component('email-campaigns-of-touchpoint', {
    template: '#email-campaigns-of-touchpoint-template',
    props: ['touchpointId', 'endpoint'],
    data: function () {
        return {
            loading: false,
            campaigns: [],
            campaignsToDelete: [],
        };
    },
    created: function () {
        McmEventBus.$on('touchpointEmailCampaignChanged', () => {
            this.update();
        });
    },
    ready: function () {
        this.update();
    },
    watch: {
        loading(val, oldVal) {
            if (val === false && oldVal === true) {
                setTimeout(() => McmPlatformMainElementsInit(), 100);
            }
        }
    },
    methods: {
        update: function () {
            const url = SiteVariables.urls.touchpointCampaignsController + '/index/' + this.touchpointId;

            this.loading = true;
            $.post(url, {}, (response) => {
                this.campaigns = response.campaigns;
                this.loading = false;
            });
        },
        openAddNewModal: function () {

            const data = {
                'action': 'add-to-touchpoint',
                'touchpoint_id': this.touchpointId
            };

            MCMApp.EmailCampaignModalActions.modalAction(data);
        },

        selectCampaign(campaignId, val) {

            if (val === true && !this.campaignsToDelete.includes(campaignId)) {
                this.campaignsToDelete = [...this.campaignsToDelete, campaignId];
                return;
            }

            if (val === false && this.campaignsToDelete.includes(campaignId)) {
                this.campaignsToDelete = _.without(this.campaignsToDelete, campaignId);
                return;
            }
        },

        openDeleteModal() {
            if (this.campaignsToDelete.length > 0) {
                const data = {
                    'action': 'delete-from-touchpoint',
                    'touchpoint_id': this.touchpointId,
                    'email_campaigns': this.campaignsToDelete
                };
                MCMApp.EmailCampaignModalActions.modalAction(data);
                this.campaignsToDelete = [];
            }
        },
    }
});

Vue.component('email-campaign-of-touchpoint', {
    template: '#email-campaign-of-touchpoint-template',
    props: {
        campaign: {
            type: Object,
            required: true
        },
        endpoint: {
            type: String,
            required: true
        },
        selectCampaign: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            report: false,
            campaignCheckbox: false,
            sendingInProgress: false,
        };
    },
    watch: {
        campaignCheckbox(val, oldVal) {
            this.selectCampaign(this.campaign.id, val);
        },
    },
    computed: {
        status: function () {
            //TODO: if scheduled
            return (this.campaign.is_sent) ? 'Sent' : 'Not sent';
        },
        sent: function () {
            if (this.campaign.sent_records.length > 0) {
                let records = this.campaign.sent_records;
                let freshDate = [...records].pop();

                if (freshDate.completed) {
                    this.sendingInProgress = false;
                    return true;
                } else {
                    this.sendingInProgress = true;
                    return false;
                }
            }
            return false;
        },
        sentDate: function () {
            var sentDate = '';
            if (this.campaign.sent_records.length > 0) {
                let records = this.campaign.sent_records;
                let freshDate = [...records].pop();

                const dateObject = new Date(Date.parse(freshDate.completed_at));
                return dateObject.toLocaleDateString();
            }
        },
        clickedPercentage: function () {
            if (!this.report) {
                return false;
            }

            return this.report.reportsClicked === 0 ? 0 : Math.round(100 * (this.report.reportsClicked / this.report.total));
        },
        openedPercentage: function () {
            if (!this.report) {
                return false;
            }
            return this.report.reportsOpened === 0 ? 0 : Math.round(100 * (this.report.reportsOpened / this.report.total));
        },
        previewLink() {
            return '/admin/message-templates/mail-templates/preview/' + this.campaign.id;
        },
        created() {
            let dateTime = this.campaign.created;

            const dateObject = new Date(Date.parse(dateTime));

            return dateObject.toLocaleDateString();
        },
        scheduled() {
            return this.campaign.scheduled_for ?
                moment(new Date(this.campaign.scheduled_for) ).format( 'DD-MM-YYYY HH:mm a'): false;

        },
    },
    ready: function () {
        if (this.campaign.sent_records.length > 0) {
            this.loadBasicReport();
        }
    },

    methods: {
        previewEmail: function () {
            MCMApp.ModalActions.touchpointMailTemplatePreview(this.campaign.mail_template_id);
        },
        sendCampaign: function () {

            let confirmFirst = confirm('This will start sending emails for this campaign, are you sure?');

            if (!confirmFirst) {
                return false;
            }

            const url = SiteVariables.urls.emailCampaignSenderController + '/index/' + this.campaign.id;
            $.post(url, {}, (response) => {
                McmEventBus.$emit('touchpointEmailCampaignSent', this.campaign.id);
            });
        },

        openEditModal: function () {
            const data = {
                'action': 'edit-on-touchpoint',
                'email_campaign_id': this.campaign.id
            };

            MCMApp.EmailCampaignModalActions.modalAction(data);
        },
        generateBasicReport: function () {
            const url = SiteVariables.urls.genericReportController + '/update-campaign-reports/' + this.campaign.id;
            var self = this;

            this.loading = true;
            $.post(url, {}, (response) => {
                self.loadBasicReport();
            });
        },

        loadBasicReport: function () {
            const url = SiteVariables.urls.genericReportController + '/campaign-report/' + this.campaign.id;

            this.loading = true;
            $.post(url, {}, (response) => {
                console.log(response);
                this.report = response;
                this.loading = false;
            });

        },
        duplicate() {
            const url = this.endpoint + '/duplicate/' + this.campaign.id;

            $.post(url, {}, (response) => {

                if (response.success) {
                    McmEventBus.$emit('touchpointEmailCampaignChanged');

                    McmHelpers.notify.success('Email campaign duplicated as: ' + response.data.emailCampaign.title);
                }
            });
        },
        openDetails() {
            const data = {
                'action': 'details-view',
                'email_campaign_id': this.campaign.id
            };

            MCMApp.EmailCampaignModalActions.modalAction(data);
        }
    }
});

Vue.component('touchpoint-goals-table', {
    template: '#touchpoint-goals-table-template',
    props: ['touchpoint']
});
// Users view/profile page actions
//-------------------------------------------------------------

/**
 *
 * @type {{}}
 */
var MCMApp = MCMApp || {};

/**
 *
 * @type {{InitEditUser: MCMApp.EditUser.InitEditUser}}
 */
MCMApp.EditUser = {

    /**
     *
     * @param userId
     * @returns {Vue}
     * @constructor
     */
    InitEditUser: function (userId) {

        return new Vue({
            el: '#user-profile-instance',
            data: {
                userId: userId,
                edit: false
            },
            watch: {
                edit: function () {
                    McmPlatformMainElementsInit();
                    McmInitEditor();
                }
            },
            created: function () {
                McmInitEditor();
                McmPlatformMainElementsInit();
            },
            events: {

                /**
                 * Update user function
                 * @param user
                 */
                userUpdated: function (user) {
                    this.edit = false;
                },

                /**
                 * Modal submit function
                 */
                userSubmitted: function () {
                    MCMApp.Modals.appModal.close();
                }
            },
            methods: {

                /**
                 *
                 * @returns {*}
                 */
                editUser: function () {
                    return MCMApp.UserModalActions.editUser(this.userId);
                }

            }
        });
    }
};
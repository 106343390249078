Vue.component('adboard-boards-popover-button', {
    template: '#adboard-boards-popover-button-template',
    props: {
        adboardGroups: {
            type: [Array,Boolean],
            required: true,
            default: []
        },
        currentGroup: {
            type: [Object, Boolean],
            default: false
        }
    },

    data() {
        return {
            isOpen: false
        };
    },

    watch : {
        isOpen(val,oldVal) {
            if(val === true) {
                document.addEventListener("click", this.popupListener);
                Vue.nextTick(() => {

                    $('.mcm-popover-card__nav').mCustomScrollbar({
                        theme: "dark"
                    });
                });
            } else {
                document.removeEventListener("click", this.popupListener);
            }
        }
    },

    methods: {
        toggle() {
            this.isOpen = !this.isOpen;
        },

        /**
         * Listen clicks and checks if the click is made outside of popover
         * @param event
         */
        popupListener(event) {
            const $el = $(event.target);

            const $parent = $(this.$el);

            if($el.closest($parent).length === 0) {
                this.toggle();
            }

        }
    }
});
var MCMApp = MCMApp || {};


/**
 * Adboard modal actions
 * @type {{addNewGroupModal: MCMApp.AdboardModalActions.addNewGroupModal, editGroupModal: (function(*=): boolean), addNewItemModal: (function(*=): boolean), createNewBoardOnItemCreation: (function(*=): boolean), editItemModal: (function(*=): boolean), uploadFileToItemModal: (function(*=): boolean), uploadNewFileToItemModal: (function(*=): boolean), addItemFinalScreen: (function(*=): boolean), showVersionsOfItem: (function(*=): boolean), approveItemWithFile: (function(*=, *=): boolean)}}
 */
MCMApp.AdboardModalActions = {

    /**
     * Get modal url
     * @return {string}
     */
    getUrl() {
        return SiteVariables.urls.ajaxModals + "/adboard";
    },

    /**
     * addNewGroupModal Method
     * @returns {boolean}
     */
    addNewGroupModal() {
        const data = {
            action: 'add-new-group'
        };

        MCMApp.Modals.appModal.open();
        MCMApp.Modals.appModal.loadContentWithData(this.getUrl(), data);

        return false;
    },

    /**
     * editGroupModal Method
     *
     * @param groupId
     *
     * @returns {boolean}
     */
    editGroupModal(groupId) {
        const data = {
            action  : 'edit-group',
            group_id: groupId
        };

        MCMApp.Modals.appModal.open();
        MCMApp.Modals.appModal.loadContentWithData(this.getUrl(), data);

        return false;
    },

    /**
     * addNewItemModal Method
     *
     * @param adboardGroupId
     *
     * @returns {boolean}
     */
    addNewItemModal(adboardGroupId) {
        const data = {
            action          : 'add-new-item',
            adboard_group_id: adboardGroupId
        };

        MCMApp.Modals.appModal.open();
        MCMApp.Modals.appModal.loadContentWithData(this.getUrl(), data);

        return false;
    },

    /**
     * createNewBoardOnItemCreation Method
     *
     * @param adboardItemId
     *
     * @returns {boolean}
     */
    createNewBoardOnItemCreation(adboardItemId) {
        const data = {
            action         : 'add-new-group-on-item-creation',
            adboard_item_id: adboardItemId
        };

        MCMApp.Modals.appModal.open();
        MCMApp.Modals.appModal.loadContentWithData(this.getUrl(), data);

        return false;
    },

    /**
     * editItemModal Method
     *
     * @param adboardItemId
     *
     * @returns {boolean}
     */
    editItemModal(adboardItemId) {
        const data = {
            action         : 'edit-item',
            adboard_item_id: adboardItemId
        };

        MCMApp.Modals.appModal.open();
        MCMApp.Modals.appModal.loadContentWithData(this.getUrl(), data);

        return false;
    },

    /**
     * uploadFileToItemModal Method
     *
     * @param adboardItemId
     *
     * @returns {boolean}
     */
    uploadFileToItemModal(adboardItemId) {
        const data = {
            action         : 'upload-file-to-item',
            adboard_item_id: adboardItemId
        };

        MCMApp.Modals.appModal.open();
        MCMApp.Modals.appModal.loadContentWithData(this.getUrl(), data);

        return false;
    },

    /**
     * For uploading file after the first one
     *
     * @param adboardItemId
     *
     * @returns {boolean}
     */
    uploadNewFileToItemModal(adboardItemId) {
        const data = {
            action         : 'upload-new-file-to-item',
            adboard_item_id: adboardItemId
        };

        MCMApp.Modals.appModal.open();
        MCMApp.Modals.appModal.loadContentWithData(this.getUrl(), data);

        return false;
    },

    /**
     * addItemFinalScreen Method
     *
     * @param adboardItemId
     *
     * @returns {boolean}
     */
    addItemFinalScreen(adboardItemId) {
        const data = {
            action         : 'add-item-final-screen',
            adboard_item_id: adboardItemId
        };

        MCMApp.Modals.appModal.open();
        MCMApp.Modals.appModal.loadContentWithData(this.getUrl(), data);

        return false;
    },

    /**
     * showVersionsOfItem Method
     *
     * @param adboardItemId
     *
     * @returns {boolean}
     */
    showVersionsOfItem(adboardItemId) {
        const data = {
            action         : 'see-item-versions',
            adboard_item_id: adboardItemId
        };

        MCMApp.Modals.appModal.open();
        MCMApp.Modals.appModal.loadContentWithData(this.getUrl(), data);

        return false;
    },

    /**
     * approveItemWithFile Method
     *
     * @param adboardItemId
     * @param currentFileId
     *
     * @return {boolean}
     */
    approveItemWithFile(adboardItemId, currentFileId) {
        const data = {
            action          : 'approve-current-version',
            adboard_item_id : adboardItemId,
            approved_file_id: currentFileId
        };

        MCMApp.Modals.appModal.open();
        MCMApp.Modals.appModal.loadContentWithData(this.getUrl(), data);

        return false;
    },

    /**
     * Show all adboard meetings
     *
     * @param adboardItemId
     *
     * @return {boolean}
     */
    showAllMeetings(adboardItemId) {
        const data = {
            action         : 'show-all-meetings',
            adboard_item_id: adboardItemId
        };

        MCMApp.Modals.appModal.open();
        MCMApp.Modals.appModal.loadContentWithData(this.getUrl(), data);

        return false;
    },

    /**
     * Shows survey edit / create modal
     */
    openScheduleMeetingSurveyModal(clientId) {

        let surveyId = parseInt($('.surveyId').val()),
            surveys = null,
            selectedSurvey = null,
            _this = this;

        $.ajax({
            url: SiteVariables.urls.surveys,
            async: false,
            data: {surveyId: surveyId},
            dataType: "json",
            success: ((data) => {
                _this.selectedSurvey = data.selectedSurvey;
            })
        });

        _handleModalActions('survey', {action: 'survey-form'}, 'md', {
            initialize: true,
            'id': 'app-modal-instance',
            'data': {
                surveyId: surveyId,
                selectedSurvey: _this.selectedSurvey,
                clientId: clientId
            }
        });
    }
};

// Default variables
//-------------------------------------------------------------

var MCMApp = MCMApp || {};
MCMApp.Templates = MCMApp.Templates || {};
MCMApp.Templates.Touchpoints = MCMApp.Templates.Touchpoints || {};


/**
 * Touchpoints edit page init
 * @type {{init: MCMApp.Templates.Touchpoints.EditPage.init}}
 */
MCMApp.Templates.Touchpoints.EditPage = {

    /**
     * Displayed sections data, needed for the add-touchpoint-form-container template
     */
    displayedSections() {
        return {
            title: false,
            date: false,
            participants: false,
            template: false,
            goals: false,
            speakers: false,
            broadcast_settings: false,
            publish_event: false,
            description: false,
            stakeholders: false,
            settingsPackages: false,
            event_program: false,
            video: false,
            budget_approval: false,
            emails: false,
            accreditation: false,
            calendar_title: false,
            calendar_description: false,
            template_layout: false,
            touchpoint_settings: false,
            accreditation_settings: false,
            ondemand: false,
            reports: false,
            metaData: false,
            template_layout_on_demand: false,
            ondemand_video: false,
            touchpoint_meta: false,
        };
    },


    /**
     * Subsection data's used in the vue in the vue component
     *
     * @returns {{participants: {listDetails: boolean}, accreditation: {questions: boolean}}}
     */
    subSections() {
        return {
            participants: {
                listDetails: false
            },
            accreditation: {
                questions: false,
            },
            polls: {
                questions: false
            }
        };
    },

    /**
     * Vue instance for easy flow edit
     *
     * @param touchpointId
     * @element #add-touchpoint-form-container
     * @returns {Vue}
     */
    init: function (touchpointId) {
        return new Vue({
            el: '#add-touchpoint-form-container',
            mixins: [
                //gets informed involved user ids
                MCMApp.touchpointProjectUsersMixin,

                //has methods to retrieve and manage accreditation data
                //from a top level for sub components
                MCMApp.touchpointEditAccreditationMixin,

                //Mixin for touchpoint ondemand video section
                MCMApp.touchpointEditOnDemandVideosMixin,

                //has some of the depracated functions and data
                MCMApp.touchpointEditDeprecatedMixin
            ],
            data: {
                //Displayed sections
                displayed: MCMApp.Templates.Touchpoints.EditPage.displayedSections(),
                //Displayed subsections
                subSections: MCMApp.Templates.Touchpoints.EditPage.subSections(),
                subSectionEnabled: false,
                transitionName: 'expandpanel',
                edit: false,
                globalSpeakerEditing: globalSpeakerEditing,
                overview: true,
                sendingMeetingRoomUrlEmails: false,
                sending: false,
                touchpoint: false,
                touchpointId: touchpointId,
                updatedTouchpointTypeId:false,
                updatedBroadcastSettingId: false,
                noPaddingScreens: noPaddingScreens,
                currentScreen: 'overview',
                saveEnabled: false,
                updating: false,
                tpOptions: {
                    zoomWebinar: false
                }
            },
            computed: {
                timezoneName: function() {
                    return this.touchpoint.timezone.description + ' (' + this.touchpoint.timezone.name + ')';
                },
                projectType: function () {
                    return this.touchpoint.type_name + ' - ' + this.touchpoint.broadcast_setting.name;
                },
                isPublished: function() {
                    let status = this.touchpoint.status;

                    status = status ? 'Published' : 'Not published';
                    return status;

                },
                automaticEmails: function() {
                    let numberOfCampaigns = this.touchpoint.email_event_checks;
                    numberOfCampaigns = Object.keys(numberOfCampaigns).length;
                    
                    let text = numberOfCampaigns + ' Automatic Emails';

                    return text;
                },
                campaignsEmails: function() {
                    let numberOfCampaigns = this.touchpoint.email_campaigns;
                    numberOfCampaigns = Object.keys(numberOfCampaigns).length;
                    
                    let text = numberOfCampaigns + ' Campaign Emails';

                    return text;
                },
                touchpointTypeId: function() {
                    if(this.updatedTouchpointTypeId) {

                        return this.updatedTouchpointTypeId;
                    } else {
                        return this.touchpoint.touchpoint_type_id;
                        
                    }
                },
                broadcastSettingId: function() {
                    if(this.updatedBroadcastSettingId) {

                        return this.updatedBroadcastSettingId;
                    } else {
                        return this.touchpoint.broadcast_setting.id;
                        
                    }

                }
            },
            created: function () {
                this.refreshTouchpoint();

                $('#app-modal').on('hidden.bs.modal', (e) => {

                    if (checkAppModalClosingSideEffects()) {
                        this.refreshTouchpoint();
                    }


                });

                McmEventBus.$on('openTouchpointEditSubSection', (section, subSection, data) => {
                    this.openSubSection(section, subSection, data);
                });
                McmEventBus.$on('closeEditBoxesOnTouchpointEditPage', (section, subSection, data) => {
                    this.edit = false;
                });
                McmEventBus.$on('refreshCurrentTouchpointNeeded', () => this.refreshTouchpoint());
            },
            watch: {

                /**
                 * Displayed speakers watcher
                 *
                 * @param val
                 * @param oldVal
                 */
                'displayed.speakers': function (val, oldVal) {
                    this.refreshInterface();
                },

                /**
                 * Displayed participants watcher
                 *
                 * @param val
                 * @param oldVal
                 */
                'displayed.participants': function (val, oldVal) {
                    this.refreshInterface();
                },

                /**
                 * Displayed description watcher
                 *
                 * @param val
                 * @param oldVal
                 */
                'displayed.description': function (val, oldVal) {
                    this.refreshInterface();
                },

                /**
                 * Displayed touchpoint_settings watcher
                 *
                 * @param val
                 * @param oldVal
                 */
                'displayed.touchpoint_settings': function (val, oldVal) {
                    this.refreshInterface();
                },


                /**
                 * Displayed publish_event watcher
                 *
                 * @param val
                 * @param oldVal
                 */
                'displayed.publish_event': function (val, oldVal) {
                    this.refreshInterface();
                },

                /**
                 * Displayed goals watcher
                 *
                 * @param val
                 * @param oldVal
                 */
                'displayed.goals': function (val, oldVal) {
                    this.refreshTouchpoint();
                },

                /**
                 * Edit watcher
                 *
                 * @param val
                 * @param oldVal
                 */
                edit(val, oldVal) {
                    if (val === true) {
                        this.refreshInterface();

                        //sometimes it is not updated on open needs a few miliseconds
                        if (true === this.edit) {
                            setTimeout(() => McmPlatformMainElementsInit(), 300);
                        }
                    }


                    console.log('edit',val,oldVal);

                    if (val === true) {
                        this.animateBody('bottom');
                    } else {
                        this.animateBody('top');
                    }
                },

                /**
                 *
                 * @param val
                 * @param oldVal
                 */
                updating: function (val, oldVal) {
                    if (true === val) {
                        $(this.$el).find('form').addClass('form-is-updating');
                    } else {
                        $(this.$el).find('form').removeClass('form-is-updating');

                    }
                },

                /**
                 *
                 * @param val
                 * @param oldVal
                 */
                overview: function (val, oldVal) {
                    if (val === true) {
                        _.each(this.displayed, (currentValue, index, arr) => {
                            this.displayed[index] = false;
                        });
                        this.currentScreen = 'overview';
                    }
                },

                /**
                 * Touchpoint use registration
                 *
                 * @param val
                 * @param oldVal
                 */
                'touchpoint.use_registration': function (val, oldVal) {
                    if (val !== undefined && oldVal !== undefined) {
                        this.triggerSaveForForm('participants');
                    }
                },

                /**
                 * Touchpoint use invitation
                 *
                 * @param val
                 * @param oldVal
                 */
                'touchpoint.use_invitation': function (val, oldVal) {
                    if (val !== undefined && oldVal !== undefined) {
                        this.triggerSaveForForm('participants');
                    }
                },


            },
            events: {


                touchpointUpdated: function (val) {
                    this.saveEnabled = false;
                    this.updating = false;

                    this.tpOptions.zoomWebinar = this.touchpoint.touchpoints_casts.provider === 'ZOOM';

                    Vue.nextTick(() => McmPlatformMainElementsInit());
                },

                touchpointUpdating: function (val) {
                    this.updating = true;
                },

                displayaction: function (val) {
                    this.$nextTick(() => {
                        this.refreshInterface();
                    });

                    _.each(this.displayed, (currentValue, index, arr) => {
                        if (currentValue === true) {
                            this.currentScreen = index;
                        }
                    });
                },

                /**
                 * Open sub section trigger method
                 *
                 * @param section
                 * @param subSection
                 * @param data
                 */
                openSubSection: function (section, subSection, data) {
                    this.openSubSection(section, subSection, data);
                }
            },
            methods: {

                toggleDisplayed: function (value) {
                    const key = (value)?value:event.target.dataset.item;
                    this.displayed[key] = (this.displayed[key] === false);
                },

                setTypeId(typeId) {
                    // this.touchpointTypeId = Number(typeId);
                    this.updatedTouchpointTypeId = Number(typeId);
                },

                setSettingId(settingId) {
                    this.updatedBroadcastSettingId = Number(settingId);
                },

                /**
                 * Manually trigger a submit event in the wake of current sections update
                 *
                 * @param sectionName
                 */
                triggerSaveForForm: function (sectionName) {
                    this.submitForm($('#tp-detail-form--' + sectionName));
                },

                /**
                 * Refresh touchpoint method
                 */
                refreshTouchpoint: function () {
                    this.$emit('touchpointUpdating');

                    const url = SiteVariables.urls.touchpointsController + '/view/' + this.touchpointId;

                    $.get(url, {}, (response) => {
                        this.$set('touchpoint ', response.touchpoint);
                        this.$emit('touchpointUpdated');
                    });
                },

                /**
                 * Close all displayed method
                 */
                closeAllDisplayed: function () {
                    this.disableSubsection();
                    for (let key in this.displayed) {
                        this.displayed[key] = false;
                    }
                },

                /**
                 * Disable subsection method
                 */
                disableSubsection: function () {
                    this.subSectionEnabled = false;
                },

                previewEmail: function (campaignId) {
                    MCMApp.ModalActions.touchpointMailTemplatePreview(campaignId);
                },

                /**
                 * Open mail template preview
                 */
                openMailTemplatePreview: function () {

                },
                /**
                 * Open speaker edit
                 *
                 * @param speakerId
                 */
                openSpeakerEdit: function (speakerId) {
                    MCMApp.SpeakerModalActions.editSpeaker(speakerId, true);
                },

                /**
                 * Uncheck if selected
                 *
                 * @param event
                 */
                uncheckIfSelected: function (event) {
                    event.preventDefault();
                    event.stopPropagation();

                    const $el = $(event.currentTarget);
                    let $input = $('#' + $el.attr('for'));

                    $(".template-package-label").removeClass('template-package-label--selected');
                    if ($input.is(':checked')) {
                        $input.prop('checked', false);
                        $('#tp-detail-form').prepend('<input type="hidden" name="template_package_id" value="" id="hidden-template-id">');

                    } else {
                        $input.prop('checked', true);
                        $el.addClass('template-package-label--selected');
                        $('#hidden-template-id').remove();
                    }
                },

                /**
                 * Toggle overview method
                 */
                toggleOverview: function () {
                    this.overview = !this.overview;
                },

                /**
                 * Open section
                 *
                 * @param event
                 */
                openSection: function (event) {
                    this.overview = false;
                    this.edit = false;
                    this.closeAllDisplayed();
                    // console.log(this.touchpoint);
                    const key = $(event.currentTarget).data('section');

                    this.displayed[key] = true;
                    this.$emit('displayaction', key);
                },

                /**
                 *
                 * Open overveiw section
                 *
                 * @param event
                 */
                openOverview: function(event) {
                    // console.log(this.touchpoint);
                    this.toggleOverview();
                },

                /**
                 * Open sub section method
                 *
                 * @param section
                 * @param subSection
                 * @param data
                 */
                openSubSection: function (section, subSection, data) {
                    _.each(this.subSections, (sectionDetails, sectionName) => {
                        _.each(sectionDetails, (subsectVal, subsectName) => {
                            this.subSections[sectionName][subsectName] = false;
                        });
                    });

                    this.subSectionEnabled = true;
                    this.subSections[section][subSection] = data;

                },

                /**
                 * Toggle Edit
                 */
                toggleEdit: function () {
                    this.edit = !this.edit;

                },

                /**
                 * Open event program manager
                 *
                 * @returns {*|boolean}
                 */
                openEventProgramManager: function () {
                    return MCMApp.ModalActions.openProgramManager(this.touchpoint.id);
                },

                /**
                 * Update current form
                 *
                 * @param event
                 */
                updateCurrentForm: function (event) {
                    let $form = $(event.currentTarget).parents('.tp-detail-form');
                    this.submitForm($form);
                },

                /**
                 * Submit form method
                 *
                 * @param $form
                 */
                submitForm: function ($form) {
                    const url = $form.prop('action');
                    const data = $form.serialize();
                    console.log(this.touchpointTopic);
                    this.$emit('touchpointUpdating');
                    $.post(url, data, (response) => {
                        this.edit = false;
                        this.refreshTouchpoint();

                        const parsedResponse = (response);

                        if (parsedResponse.success !== undefined && parsedResponse.success === false) {
                            _.each(parsedResponse.message, function (messageItem) {
                                _.each(messageItem, function (messageText) {
                                    $.snackbar({
                                        content: messageText,
                                        style: "text-center alert alert-danger",
                                        timeout: 5000
                                    });
                                });
                            });
                            return false;
                        }

                        $.snackbar({
                            content: 'Updated!',
                            style: "text-center alert alert-success",
                            timeout: 2000
                        });
                    });
                },

                /**
                 * Form submit trigger method
                 *
                 * @param event
                 */
                onSubmit: function (event) {
                    this.submitForm($(event.currentTarget));
                },


                submit() {
                    const url = this.$els.form.getAttribute('action');

                    // map values and post
                    let values = {
                        name: this.touchpoint.project.title,
                        description: this.touchpoint.description,
                        date: this.touchpoint.start_day,
                        end_date: this.touchpoint.end_day,
                        // time: this.touchpoint.eventstarttime,
                        // end_time: this.touchpoint.eventendtime,
                        // touchpoint_type_id: this.touchpointTypeId,
                        // broadcast_setting_id: this.broadcastSettingId,
                        // name: this.title,
                        // internal_description: this.description,
                        // date: this.startDate,
                        // end_date: this.endDate,
                        // eventstarttime: this.startTime,
                        // eventendtime: this.endTime,
                        // newResponse: true
                    };


                    // //add hidden inputs so it can include csrf token as well
                    // [...this.$els.form.querySelectorAll('input[type=hidden]')].forEach((el) => {
                    //     values[el.getAttribute('name')] = el.value;
                    // });

                    this.sending = true;
                    $.post(url, values, (response) => {
                        if (response.success === true) {
                            window.location.href = `/admin/touchpoints/manage/${response.data.id}`;
                        } else {
                            this.sending = false;
                        }
                    });
                },

                /**
                 * Send meeting room url emails
                 *
                 * @param e
                 */
                sendMeetingRoomUrlEmails: function (e) {
                    let _this = this;
                    let $bttn = $(e.currentTarget);
                    let url = '/admin/interactions/touchpoint-hcp/event-meeting-room-links/' + this.touchpoint.id;

                    this.sendingMeetingRoomUrlEmails = true;

                    $.get(url, {}, function (response) {
                        _this.sendingMeetingRoomUrlEmails = false;
                    });
                },


                /**
                 * Animate body and html to bottom of the page
                 *
                 * @param type string
                 * @return void
                 */
                animateBody: function (type) {
                    let heightPx;

                    console.log('oyoyoyo');

                    setTimeout(() => {
                        if (type === 'top') {
                            heightPx = 0;
                            $("html, body").animate({scrollTop: heightPx}, "slow");
                        } else {
                            //TODO: it was sending users to the bottom of the page when editing was true.
                            //TODO: Correct behavoir should be sending to current edit box

                            console.log('asadasoyoyoyo');
                            const editBox = document.querySelector('.inline-edit-box');
                            $("html, body").animate({scrollTop: editBox.offsetHeight}, "slow");
                        }
                    }, 300);
                },

                /**
                 * Refresh interface by initializing
                 * @return void
                 */
                refreshInterface() {
                    McmPlatformMainElementsInit();
                    McmInitEditor();
                },
                updateTouchpointSettings() {
                    console.log('saved');
                },

                streamPagePushAction(actionName) {
                    let checkConfirm = confirm('Are you sure to take action:' + actionName);
                    const url = '/admin/development/triggerMediaStreamPusher/' + this.touchpointId + '/' + actionName;
                    if (checkConfirm) {
                        $.get(url, {}, (response) => {
                            if (response.result === true) {
                                $.snackbar({
                                    content: 'Action completed successfully: ' + actionName,
                                    style: "text-center alert alert-success",
                                    timeout: 2000
                                });
                            }
                        }, "json");
                    }
                }
            }
        });
    }

};
Vue.component('video-platform-home-page-management-tabs-view-email', {
    template: "#video-platform-home-page-management-tabs-view-email-template",
    props: {
        hcps: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            from: "",
            subject: ""
        };
    },
    methods: {
        removeHCPFromList(id, target) {
            this.$emit('remove-hcp', {target:target, id:id});
        },
        sendInvitation() {

            if (this.hasHCPS && this.from && this.subject) {
                this.$emit('send-invitation', {
                    hcps: this.hcps,
                    from: this.from,
                    subject: this.subject
                });
            }

        }
    },
    computed: {
        hasHCPS() {
            return Object.keys(this.hcps.users).length || Object.keys(this.hcps.lists).length;
        }
    }
});
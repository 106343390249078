// Vue video platform loader mixin
// -- Generic mixin for holding and switching loader on and off
//-------------------------------------------------------------

var MCMApp = MCMApp || {};

MCMApp.videoPlatformLoaderMixin = {
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        startLoader() {
            this.loading = true;
        },
        stopLoader() {
            this.loading = false;
        },
    },
};
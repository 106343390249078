// Meetingpoint survey actions
//-------------------------------------------------------------

var MCMApp = MCMApp || {};

/**
 *
 * @type {{InitMeetingPointSurvey: MCMApp.MeetingpointSurvey.InitMeetingPointSurvey}}
 */
MCMApp.MeetingpointSurvey = {

    /**
     *
     * @param touchpointId
     * @param selector
     * @returns {Vue}
     * @constructor
     */
    InitMeetingPointSurvey: function (touchpointId, selector) {
        return new Vue({
            el: selector,
            data: {
                touchpointId: touchpointId
            },
            watch: {},
            created: function () {
                McmInitEditor();
                McmPlatformMainElementsInit();
            },
            events: {

                /**
                 * Survey submit event to close the modal
                 */
                surveySubmitted: function () {
                    MCMApp.Modals.appModal.close();
                }
            },
            methods: {

                /**
                 * Handle the xhr request to render template in a modal for survey
                 * @returns {*}
                 */
                triggerSurvey: function () {
                    return MCMApp.MeetingpointHcpModalActions.triggerMeetingpointSurveyAction(this.touchpointId);
                },

                /**
                 * Save method on submit
                 * @param e
                 */
                onSubmitSave: function (e) {
                    e.preventDefault();

                    var _this = this;
                    var data = $(e.currentTarget).serialize();
                    var url = e.currentTarget.getAttribute('action');

                    this.updateInProgress = true;


                    $.ajax({
                        url: url,
                        data: data,
                        success: function (response) {
                            this.updateInProgress = false;
                            if (typeof response !== 'object') {
                                response = JSON.parse(response);
                            }
                            if (response.id !== undefined) {
                                _this.$emit('surveySubmitted');
                            }
                            else {
                                throw 'Cant find xhr response.';
                            }
                        },
                        error: function (data) {
                            throw 'Cant find xhr action.';
                        }
                    });
                }
            }
        });
    }
};
// User login vue component
//-------------------------------------------------------------

/**
 *  User login component
 */
Vue.component('login', {
    template: '#login-template',
    props: {
        url: {
            type: String,
            required: false,
            default: '/users/login'
        },
        branding: {
            type: [Object, Boolean],
            requred: false
        },
        hideInput: {
            type: Array,
            required: false,
            default: []
        },
        helperEmail: {
            type: String,
            required: false
        },
        helperPassword: {
            type: String,
            required: false
        },
        redirect: {
            type: Boolean,
            requrired: false,
            default: true
        }
    },
    data(){
        return {
            email: null,
            password: null,
            loading: false,
            form: {
                filled: false,
                error: false,
                success: false,
                successMessage: false,
                serverError: false,
                disable: false
            },
            input: {
                password: true,
                email: true,
                resetPassword: true,
            }
        };
    },
    computed: {

        /**
         * Check if form fields is filled
         *
         * @return {boolean}
         */
        validateForm(){
            let validationCheck;

            if (!this.input.email) {
                validationCheck = Boolean(this.validatePassword);
            } else if (!this.input.password) {
                validationCheck = Boolean(this.validateEmail);
            } else {
                validationCheck = Boolean(this.validateEmail && this.validatePassword);
            }

            return validationCheck;
        },


        /**
         * Validate email
         *
         * @return {boolean}
         */
        validateEmail(){
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            return this.email ? Boolean(re.test(this.email)) : false;
        },


        /**
         * Validate password
         *
         * @return {boolean}
         */
        validatePassword(){
            return Boolean(this.password);
        },


        /**
         * Return class on the .form-group if email error is true
         *
         * @return {*}
         */
        emailError(){
            return (this.form.filled && !this.validateEmail) ? 'has-error' : null;
        },


        /**
         * Return class on the .form-group if password error is true
         *
         * @return {*}
         */
        passwordError(){
            return (this.form.filled && !this.validatePassword) ? 'has-error' : null;
        }
    },
    created(){
        this.setHiddenInputElements();
    },
    ready(){
        this.initMcmElements();
    },
    watch: {

        /**
         * Watch email data bindings
         */
        email(){
            this.resetFormFilled();
        },


        /**
         * Watch password data bindings
         */
        password(){
            this.resetFormFilled();
        }
    },
    methods: {

        /**
         * Init mcm elements for forms and etc
         */
        initMcmElements(){
            McmPlatformMainElementsInit();
            McmInitEditor();
        },


        /**
         * Log user in
         *
         * @param e
         */
        loginUser(e){
            const data = $(e.currentTarget).serialize();

            e.preventDefault();
            this.form.filled = true;

            if (this.validateForm) {
                this.submitLoginForm(data);
            }
        },


        /**
         * Reset form filled data in order to check again
         */
        resetFormFilled(){
            if (this.form.filled) {
                this.form.filled = this.form.error = false;
            }
        },


        /**
         * Redirect user if logged in
         *
         * @param data
         * @return {boolean}
         */
        redirectUserAfterLogin(data){
            if (!data.error) {
                this.redirectToDashboard(data);
            } else {
                this.handleAuthError(data);
            }

            this.loading = false;
        },


        /**
         * Return the error message back if error
         *
         * @param data
         */
        handleAuthError(data){
            this.form.error = data.error;
        },


        /**
         * Handle server error
         */
        handleServerError(){
            this.loading = false;
            this.form.serverError = true;
        },


        /**
         * Submit login form
         *
         * @param formData
         */
        submitLoginForm(formData){
            if (!this.form.disable) {
                this.loading = true;

                $.ajax({
                    url: this.url,
                    data: formData,
                    type: 'POST',
                    dataType: 'JSON',
                    success: (data) => this.handleSuccessLogin(data),
                    error: (err) => this.handleServerError()
                });
            }
        },


        /**
         * Redirect to dashboard if auth is successfull
         *
         * @param data
         *
         * @return {boolean}
         */
        redirectToDashboard(data){
            if (data.redirectUrl) {
                this.form.success = true;
                window.location = data.redirectUrl;
            }
        },


        /**
         * Highlight input icon
         *
         * @param elem
         * @param toggle
         *
         * @return {Boolean}
         */
        highlightInputIcon(elem, toggle){
            if (this.branding && !_.isEmpty(this.branding)) {
                const icon = $(elem);

                if (toggle) {
                    icon.css('color', this.branding.secondary_color);
                } else {
                    icon.attr('style', '');
                }

                return true;
            }

            return null;
        },


        /**
         * Set the component data for the inputs that needs to be hidden
         */
        setHiddenInputElements(){
            if (this.hideInput && this.hideInput.length > 0) {
                this.hideInput.forEach((value) => this.input[value] = false);
            }
        },


        /**
         * Handler after login success
         *
         * @param data
         */
        handleSuccessLogin(data){
            if (this.redirect) {
                this.redirectUserAfterLogin(data);
            } else {
                this.appendSuccessMessage(data);
            }
        },


        /**
         * Append success message in the component
         *
         * @param data
         */
        appendSuccessMessage(data){
            if (!_.isEmpty(data) && data.message && !data.error) {
                this.form.successMessage = data.message;
                this.form.success = this.form.disable = true;
            } else {
                this.handleAuthError(data);
            }

            this.loading = false;
        }
    },
});


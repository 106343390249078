Vue.component('adboard-files-pagination', {
    template: '#adboard-files-pagination-template',
    props: {
        parentFile: {
            type: Object,
            required: true
        },
        currentFile: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            itemsPerPage: 3
        };
    },
    computed: {
        /**
         * If has more than 1 children
         * @return {boolean}
         */
        needsPagination() {
            if (this.currentFile.id === this.parentFile.id) {
                return false;
            }
            return (this.parentFile.child_adboard_files !== undefined && this.parentFile.child_adboard_files.length > 1);
        },

        needsArrows()
        {
            return (this.files.length > 4);
        },

        files() {
            return this.parentFile.child_adboard_files;
        },

        filesForPagination() {
            // we will show 3 files at a time.
            // we will get items by mod 3
            // so we can show as 123 then 456 then 567
            // only for last part we will get last 3
            const files = this.parentFile.child_adboard_files;

            if (!this.needsArrows) {
                return files;
            }

            let groups = _.groupBy(files, (item)=> {
                return Math.ceil(item.sort / this.itemsPerPage);
            });

            let currentItemGroup = Math.ceil(this.currentFile.sort / this.itemsPerPage);

            return groups[currentItemGroup];

        },

        hasPrev(){
            return this.currentFile.sort > 1;
        },

        hasNext(){
            return (this.currentFile.sort !== this.files.length);
        }
    },

    watch: {
        currentFile(val, oldVal){
        }
    },

    ready()
    {
        //set current page next page etc initially
    },

    methods: {

        goToPrev(){
            const next = this.files.filter((file)=>file.sort === (this.currentFile.sort - 1));
            if(next.length) {
                this.setAsCurrentFile(next[0]);
            }
        },

        goToNext(){
            const next = this.files.filter((file)=>file.sort === (this.currentFile.sort + 1));
            if(next.length) {
                this.setAsCurrentFile(next[0]);
            }
        },

        goToFirst(){
            this.setAsCurrentFile(this.files[0]);
        },

        goToLast(){
            this.setAsCurrentFile(_.last(this.files));
        },

        setAsCurrentFile(file) {

            McmEventBus.$emit('adboardCurrentFileChanged', file);
        }
    }
});

var MCMApp = MCMApp || {};

MCMApp.VideoPlatformModalActions = {

    /**
     * Url of this actions. They all point to same endpoint and
     * differentiate on action parameter
     * This is a method due to late initialization of SiteVariables object
     * @return {string}
     */
    getUrl() {
        return SiteVariables.urls.ajaxModals + "/video-platform";
    },

    /**
     * viewVideoModal Method
     * @returns {boolean}
     */
    viewVideoModal(id, title, type) {
        const data = {
            action: 'view-video',
            title: title,
            id: id,
            type: type
        };

        MCMApp.Modals.appModal.openLarge({class: 'videos-view-modal video-platform-modal'});
        MCMApp.Modals.appModal.loadContentWithData(this.getUrl(), data);

        return false;
    },
    deleteVideoModal(id, title, global) {
        const data = {
            action: 'delete-video',
            id: id,
            title: title,
            global: global
        };

        MCMApp.Modals.appModal.open({class: 'videos-delete-modal video-platform-modal'});
        MCMApp.Modals.appModal.loadContentWithData(this.getUrl(), data);

        return false;
    },
    editVideoModal(buttonAction, global) {
        const data = {
            action: 'edit-video',
            dataAction: buttonAction,
            global: global
        };

        MCMApp.Modals.appModal.open({class: 'videos-delete-modal video-platform-modal'});
        MCMApp.Modals.appModal.loadContentWithData(this.getUrl(), data);

        return false;
    },
    inviteToWatchModal(videos) {
        const data = {
            action: 'invite-to-watch',
            videos: videos
        };

        MCMApp.Modals.appModal.open({class: 'invite-to-watch-modal video-platform-modal'});
        MCMApp.Modals.appModal.loadContentWithData(this.getUrl(), data);

        return false;
    },
    DenyRequestModal(id, firstname, lastname) {
        const data = {
            action: 'deny-request',
            id: id,
            firstname: firstname,
            lastname: lastname
        };

        MCMApp.Modals.appModal.open({class: 'videos-delete-modal video-platform-modal'});
        MCMApp.Modals.appModal.loadContentWithData(this.getUrl(), data);

        return false;
    },

    ApproveRequestModal(id, firstname, lastname) {
        const data = {
            action: 'approve-request',
            id: id,
            firstname: firstname,
            lastname: lastname
        };

        MCMApp.Modals.appModal.open({class: 'videos-delete-modal video-platform-modal'});
        MCMApp.Modals.appModal.loadContentWithData(this.getUrl(), data);

        return false;
    },

    UpdateHcpModal() {
        const data = {
            action: 'update-hcp'
        };

        MCMApp.Modals.appModal.open({class: 'videos-delete-modal video-platform-modal'});
        MCMApp.Modals.appModal.loadContentWithData(this.getUrl(), data);

        return false;
    },

    DeleteHcpModal(id, area_id, firstname, lastname) {
        const data = {
            action: 'delete-hcp',
            id: id,
            area_id: area_id,
            firstname: firstname,
            lastname: lastname
        };

        MCMApp.Modals.appModal.open({class: 'videos-delete-modal video-platform-modal'});
        MCMApp.Modals.appModal.loadContentWithData(this.getUrl(), data);

        return false;
    },

    DeleteHcpListModal(id, area_id, name) {
        const data = {
            action: 'delete-hcp-list',
            id: id,
            area_id: area_id,
            name: name
        };

        MCMApp.Modals.appModal.open({class: 'videos-delete-modal video-platform-modal'});
        MCMApp.Modals.appModal.loadContentWithData(this.getUrl(), data);

        return false;
    },

    InviteHcpModal() {
        const data = {
            action: 'invite-hcp'
        };

        MCMApp.Modals.appModal.open({class: 'videos-delete-modal video-platform-modal'});
        MCMApp.Modals.appModal.loadContentWithData(this.getUrl(), data);

        return false;
    },
    InviteHcpErrorModal(email) {
        const data = {
            action: 'invite-hcp-error',
            email: email,
        };

        MCMApp.Modals.appModal.open({class: 'videos-delete-modal video-platform-modal'});
        MCMApp.Modals.appModal.loadContentWithData(this.getUrl(), data);

        return false;
    },
    targetListsModal(status) {

        const data = {
            action: 'target-lists',
            status: status
        };

        MCMApp.Modals.appModal.open({class: 'target-lists-modal video-platform-modal'});
        MCMApp.Modals.appModal.loadContentWithData(this.getUrl(), data);

        return false;
    },
};

$('body').off('click', '.edit-video-container .btn-form-submit').on('click', '.edit-video-container .btn-form-submit', function () {
    if(document.readyState === 'complete') {
        MCMApp.VideoPlatformModalActions.editVideoModal($(this).data('action'), $(this).data('global'));
    }
});

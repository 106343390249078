Vue.component('create-touchpoint-wizard', {
    props: ['sectionId', 'initialTypeId'],
    data() {
        return {
            title: "",
            description: "",
            startDate: "",
            startTime: "",
            endDate: "",
            endTime: "",
            timezoneId: false,
            accreditation: false,
            dateInterval: false,
            touchpointTypeId: false,
            broadcastSettingId: false,
            visibleStep: 'one',
            sending: false,
            isMultipleDayEvent: false,
            durationHour: 1,
            durationMinute: 0,
            selectedPackageId: false,
        };
    },
    computed: {
        canGoToSecondStep() {
            const isEndDateGreater = this.startDateParsed.diff(this.endDateParsed) < 0;

            const dateFieldsFilled = this.startTime && this.startDate;

            return isEndDateGreater && dateFieldsFilled && this.title.length;
        },
        canSubmit() {
            const isEndDateGreater = this.startDateParsed.diff(this.endDateParsed) < 0;
            const typesSelected = this.touchpointTypeId && this.broadcastSettingId;

            return isEndDateGreater && typesSelected;
        },
        /**
         *
         * @return {*|moment.Moment}
         */
        startDateParsed() {
            //from dd-mm-yyyy to yyyy-mm-dd
            let dateStr = this.startDate.split('-').reverse().join('-');
            if (this.startTime) {
                dateStr += ` ${this.startTime}`;
            }

            const date = moment(dateStr);
            return date;
        },
        endDateParsed() {
            //from dd-mm-yyyy to yyyy-mm-dd
            let dateStr = this.endDate.split('-').reverse().join('-');
            if (this.endTime) {
                dateStr += ` ${this.endTime}`;
            }
            const date = moment(dateStr);
            return date;
        },

        currentStepNumber() {
            switch (this.visibleStep) {
                case 'one':
                    return 1;
                case 'two':
                    return 2;
            }
            return false;
        },
    },
    ready() {
        McmPlatformMainElementsInit();
        this.startDate = this.endDate = moment().add(1, 'day').format("DD-MM-YYYY");
        if (this.initialTypeId) {

            this.touchpointTypeId = Number(this.initialTypeId);
        }

        //somehow changes on date selections are not triggering events
        //so we are manually updating if it is changed
        this.dateInterval = setInterval(() => {

            if (!document.querySelector('.in #create-touchpoint')) {
                clearInterval(this.dateInterval);
            }
            if (this.$els.startdate && this.$els.startdate.value !== this.startDate) {
                this.startDate = this.$els.startdate.value;
            }
            if (this.$els.enddate && this.$els.enddate.value !== this.endDate) {
                this.endDate = this.$els.enddate.value;
            }
        }, 500);
    },

    watch: {
        touchpointTypeId(val) {
            if (!Number.isInteger(val)) {
                this.touchpointTypeId = Number(val);
                return;
            }
            this.broadcastSettingId = false;
        },
        broadcastSettingId(val) {
            if (!Number.isInteger(val)) {
                this.broadcastSettingId = Number(val);
            }
        },
        isMultipleDayEvent() {
            setTimeout(() => McmPlatformMainElementsInit(), 400);
        },
        startDate(val, oldVal) {
            //if start date changes when it is not multiple day, make end date same day
            this.endDate = val;
        },
        startDateParsed() {
            this.calculateEndTime();
        },
        durationHour() {
            this.calculateEndTime();
        },
        durationMinute() {
            this.calculateEndTime();
        }
    },
    methods: {
        openSecondStep() {
            this.visibleStep = "two";
        },
        openFirstStep() {
            this.visibleStep = "one";
        },
        openServicePackageSelection() {
            this.visibleStep = "service-packages";
        },
        setTypeId(typeId) {
            this.touchpointTypeId = Number(typeId);
        },
        setSettingId(settingId) {
            this.broadcastSettingId = Number(settingId);
        },
        submit() {
            const url = this.$els.form.getAttribute('action');
            //map values and post
            let values = {
                touchpoint_type_id: this.touchpointTypeId,
                broadcast_setting_id: this.broadcastSettingId,
                name: this.title,
                internal_description: this.description,
                date: this.startDate,
                end_date: this.endDate,
                eventstarttime: this.startTime,
                eventendtime: this.endTime,
                newResponse: true
            };

            //add hidden inputs so it can include csrf token as well
            [...this.$els.form.querySelectorAll('input[type=hidden]')].forEach((el) => {
                values[el.getAttribute('name')] = el.value;
            });

            this.sending = true;
            $.post(url, values, (response) => {
                if (response.success === true) {
                    window.location.href = `/admin/touchpoints/manage/${response.data.id}`;
                } else {
                    this.sending = false;
                }
            });
        },

        calculateEndTime()
        {
            const durationAdded = moment(this.startDateParsed).add(this.durationHour, 'h').add(this.durationMinute, 'm');
            if(durationAdded.isValid()){
                this.endTime = durationAdded.format('HH:mm');
            }
        }
    }
});


// Meetingpoint survey actions
//-------------------------------------------------------------

var MCMApp = MCMApp || {};
/**
 *
 * @type {{editForm: MCMApp.EmailCampaignFormTemplates.editForm}}
 */
MCMApp.EmailCampaignFormTemplates = {
    editForm: function () {
        return new Vue({
            el: '#edit-on-touchpoint-form',
            data : {
                emailName: '',
                subject: '',
                sender: '',
                template: false,
            },
            ready: function () {
                McmPlatformMainElementsInit();
                this.toggleDescriptionsIcs();
            },
            computed: {
                disabled: function () {
                    if(this.emailName && this.subject && this.sender && this.template) {
                        return true;
                    }
                    return false;
                }
            },
            methods: {
                submitForm: function (e) {
                    const $form = $(e.currentTarget);
                    const formData = $form.serialize();
                    const url = $form.attr('action');

                    $.post(url, formData, (response) => {
                        McmEventBus.$emit('touchpointEmailCampaignChanged');
                        MCMApp.Modals.appModal.close();
                    });
                },
                cancelDelete: function () {
                    MCMApp.Modals.appModal.close();
                },

                toggleDescriptionsIcs: function () {
                    const icsCheckBox = $('#ics-file-send-emails');
                    const icsContent = $('.ics-email-campaigns');

                    if (icsCheckBox.prop('checked')) {
                        icsContent.removeClass('hide');
                    } else {
                        icsContent.addClass('hide');
                    }
                },
                checkToggleIcs: function () {
                    this.toggleDescriptionsIcs();
                }
            }
        });
    },
    detailView: function (campaignId) {
        return new Vue({
            el: '#email-campaign-details',
            data : {
                loading : false,
                reports: false,
                campaignId
            },
            ready(){
                this.loadBasicReport();
            },
            methods : {
                loadBasicReport: function () {
                    const url = SiteVariables.urls.genericReportController + '/campaign-report/' + this.campaignId;

                    this.loading = true;
                    $.post(url, {}, (response) => {
                        console.log(response);
                        this.reports = response;
                        this.loading = false;
                    });

                },
            }
        });
    }
};
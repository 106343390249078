/**
 * Card displayed for adboard group on groups page
 */
Vue.component('adboard-item-card', {
    template: '#adboard-item-card-template',
    props: {
        adboardItem: {
            type: Object,
            required: true
        },
        deleteUrlItem: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            showActions: false
        };
    },
    computed: {

        /**
         * Set delete url
         * @return {string}
         */
        deleteUrl() {
            return this.deleteUrlItem + '/' + this.adboardItem.id;
        },
        backgroundStyles() {
            if (!this.adboardItem.background) {
                return {};
            }
            return {
                'backgroundImage': 'url(' + this.adboardItem.background + ')'
            };

        },
    },
    methods: {

        /**
         * Go to item view
         */
        goToView() {
            window.location.href = this.adboardItem.view_url;
        },

        /**
         * SHow all meetings modal
         * @return {*|boolean}
         */
        goToSchedule() {
            return MCMApp.AdboardModalActions.showAllMeetings(this.adboardItem.id);
        },

        /**
         * Toggle actions
         */
        toggleActions() {
            this.showActions = !this.showActions;
        },

        /**
         * Edit item modal
         * @return {*|boolean}
         */
        editItem() {
            window.location.href = this.adboardItem.edit_url;
            return;
        },

        /**
         * Delete item
         * @param message
         */
        deleteItem(message) {
            if (confirm(message)) {
                window.location = this.deleteUrl;
            }
        }
    }
});

var MCMApp = MCMApp || {};

MCMApp.Forms = MCMApp.Forms || {};

MCMApp.Forms.ProjectStakeholderModalFormViewModel = Backbone.Model.extend({

    default : {

    }
});


MCMApp.Forms.ProjectStakeholderModalFormViewClass = Backbone.View.extend({



    events: {
        "click .selection-box": "selectionBoxChange",
        "submit form": "formSubmit"
    },

    initialize: function () {

        McmPlatformMainElementsInit();

    },


    selectionBoxChange: function (e) {
        var _this = this;
        var $el = $(e.currentTarget);

        //unselect others
        var $boxesEl = $el.parent();
        $boxesEl.find('.selection-box').each(function () {

            $(this).find('input').prop('checked', false);

            $(this).removeClass('selection-box--selected');
        });
        //make this select this one's radio selected
        $el.find('input').prop('checked', true);
        $el.addClass('selection-box--selected');

        this.model.set('selected',true);

    },

    formSubmit : function(e){
        e.preventDefault();

        var _this = this;
        var $form = $(e.currentTarget);

        console.log($form.attr('action'),$form.serialize());

        $form.find('.btn').addClass('hidden');

        // give loading message
        $form.find('.message-box').html(MCMApp.Helpers.Alerts.info('<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>'));

        $.post($form.attr('action'),$form.serialize(),function(response){
            response = $.parseJSON(response);
            console.log(response);
            if( response.id > 0 ) {
                $form.find('.message-box').html(MCMApp.Helpers.Alerts.success('<i class="fa fa-check"></i> Update Successful'));
                // close modal
                MCMApp.Modals.appModal.closeAfter(1000);
                // refresh page
                window.location.reload();
            }
        });
    }


});
Vue.component('video-platform-home-page-management', {
    template: '#video-platform-home-page-management-template',
    props: {
        videos: {
            type: Object,
            required: true
        },
        actionUrls: {
            type: Object,
            required: true
        },
        global: {
            type: String | Number,
            default: 0
        },
    },
    ready(){
        $('[data-toggle="tooltip"]').tooltip();
    },
    data() {
        return {
            activeSections: {
                featuredVideos: true,
                newVideos: true,
                moreVideos: true
            },
            //Store Select Videos
            selectedVideos: {},
            disableSendVideoBtn: true,

            allowSelectVideos: false,
            selectOptions: {
                'enable-select': this.allowSelectVideos
            },
            showTabs: false,
            emailTemplate: false,
            hcps: {}
        };
    },
    methods: {
        //Sections accordion
        toggleActiveSection: function (item) {
            this.activeSections[item] = !this.activeSections[item];
        },
        storeSelectedVideos(id, title, $event) {
            if (this.selectedVideos[id]) {
                Vue.delete(this.selectedVideos, id);

                $event.currentTarget.classList.add("not-selected");
                // Disable submit button
                if (Object.keys(this.selectedVideos).length === 0) {
                    this.disableSendVideoBtn = true;
                }
            } else {
                $event.currentTarget.classList.remove("not-selected");
                this.selectedVideos[id] = title;

                // Enable submit button
                this.disableSendVideoBtn = false;
            }
        },
        sendSelectedItems() {
            if (Object.keys(this.selectedVideos).length !== 0) {
                this.getTabsData();
            }
        },
        getTabsData() {
            this.getEmailTemplate();
            this.getHCPS();
            this.showTabs = true;
        },
        getEmailTemplate() {
            $.post(this.actionUrls.emailTemplate, {videos: Object.keys(this.selectedVideos)}, (response) => {
                if (response.success === true) {
                    this.emailTemplate = response.data;
                }
            });
        },
        getHCPS() {
            $.get(this.actionUrls.getHCPS, {}, (response) => {
                if (response.success === true) {
                    this.hcps = response.data;
                }
            });
        },
        sendEmail(data) {
            data.videos = Object.keys(this.selectedVideos);

            $.post(this.actionUrls.emailSend, data, (response) => {
                if (response.success === true) {
                    MCMApp.VideoPlatformModalActions.inviteToWatchModal(Object.values(this.selectedVideos));

                    this.showTabs = false;
                    this.emailTemplate = false;
                    this.hcps = {};
                    this.allowSelectVideos = false;
                    this.disableSendVideoBtn = true;
                    this.selectedVideos = {};
                }
            });
        },
        //Allow user to select video
        enableSelectVideos() {
            this.allowSelectVideos = !this.allowSelectVideos;
            if (!this.allowSelectVideos) {
                this.restoreSelectedProps();
            }
        },
        restoreSelectedProps() {
            this.selectedVideos = {};
            this.disableSendVideoBtn = true;
        },
        updateParentVideos() {
            this.$emit("update");
        },
        hideTabs() {
            this.showTabs = false;
            this.emailTemplate = false;
        }
    }
});

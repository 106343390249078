/**
 * Media site stream component for touchpoints
 */
Vue.component('mediasite-poll', {
    template: '#mediasite-poll-template',
    props: {

        video: {
            type: Object,
            required: true
        },
        touchpointId: {
            type: [Number, String],
            required: true
        },
        pausable : {
            type:[Boolean,String],
            default:false
        }
    },
    data(){
        return {
            player: false,
            loading: true,
            playerViewTime: 0
        };
    },

    computed: {
        /**
         * Html id of player
         * @returns {string}
         */
        playerId(){
            return 'ms-player-' + this.video.id;
        },

        /**
         * Video uses a different player to hide standart buttons so we make sure player param is there
         * But if we are using in pausable mode we can use its own methods
         *
         * @returns {string}
         */
        videoUrl(){
            if(this.pausable) {
                return this.video.url;
            }
            return this.video.url + (this.video.url.indexOf("?") == -1 ? "?" : "&") + "player=MediasiteIntegration";
        }
    },

    ready(){
        this.initMediaSitePlayer();
    },
    methods: {


        /**
         * Init media site player on id player
         */
        initMediaSitePlayer(){
            this.player = new Mediasite.Player(this.playerId, {
                url: this.videoUrl,
                events: {
                    'ready': (eventData) => {
                    }
                }
            });



            this.loading = false;
        },

        currentTimeChanged (data){

            console.log(data);
        },

        /**
         * Plays the video
         */
        playVideo(){
            if (this.player.getPlayState() != 'playing') {
                this.player.play();
            }
        },
        /**
         * Plays the video
         */
        pauseVideo(){
            if (this.player.getPlayState() == 'playing') {
                this.player.pause();
            }
        }
    }
});

// Adboard item container
//--------------------------------------------------

Vue.component('adboard-item-container', {
    props: {
        adboardItemUrl: {
            type: String,
            reqiured: false
        },
        adboardItemId: {
            type: [Number, String],
            reqiured: false
        }
    },
    data() {
        return {
            adboardItem: false,
            currentFile: false,
            comments: false,
            replyToCommentId: '',
            replyToComment: false,
            isInfoOpen: true,
            isCommentsOpen: true,
            commentsLoading: false,
            pinCoordinates: false,
            imageSize: false,
            setImageTimer: false,
            stickyComments: false,
            zoomEnabled: false
        };
    },
    computed: {

        /**
         * pinStyle Method
         * @return {*}
         */
        pinStyle() {
            if (!this.pinCoordinates) {
                return false;
            }

            return {
                left: this.pinCoordinates.left + '%',
                top: this.pinCoordinates.top + '%',
            };
        },

        /**
         * currentFileComments Method
         * @return {Array}
         */
        currentFileComments() {
            let currentFileComments = [];
            if (this.comments && this.currentFile) {
                currentFileComments = _.filter(this.comments, comment => (comment.adboard_file_id === this.currentFile.id));
            }
            return currentFileComments;
        },

        /**
         * currentFileStickyComments Method
         * @return {String}
         */
        currentFileStickyComments() {
            return _.filter(this.currentFileComments, comment => (comment.pin_coordinates));
        },

        /**
         * approvedFile Method
         * @return {boolean}
         */
        approvedFile() {
            let approvedFile = false;

            if (this.adboardItem) {
                approvedFile = _.findWhere(this.adboardItem.adboard_files, {id: this.adboardItem.approved_file_id});
            }

            return approvedFile;
        },

        /**
         * stickyCommentsAssociated Method
         * @return {*}
         */
        stickyCommentsAssociated() {
            return _.pluck(this.stickyComments, 'id');
        },

        /**
         * currentParentFile Method
         * @return {*}
         */
        currentParentFile() {
            if (this.currentFile.parent_file_id > 0) {
                return this.adboardItem.adboard_files.filter(item => (item.id === this.currentFile.parent_file_id))[0];
            }

            return this.currentFile;
        }
    },
    created() {
        McmEventBus.$on('adboardCurrentFileChanged', this.setCurrentFile);
        McmEventBus.$on('comment-added', this.afterCommentAdded);
        McmEventBus.$on('adboardItemNeedsRefresh', this.fetchAdboardItem);
        $('#app-modal').on('hidden.bs.modal', () => this.fetchAdboardItem());


        McmEventBus.$on('adboardChildFilePageCounted', function (data) {
            McmHelpers.notify.success('Your file is successfully uploaded, conversion process should complete shortly.');
        });

        McmEventBus.$on('adboardChildFilesProcessingFinished',  (data)=> {
            McmHelpers.notify.success('Your file is ready to use.');
        });

    },
    ready() {
        this.fetchAdboardItem();
        this.fetchComments();
        this.adjustVersionListHeight();

        $(window).resize(() => {
            this.adjustCommentListingHeight();
            this.adjustVersionListHeight();

            //draw if it is on page and size changed
            this.$emit('new-pin-connection');
        });

        this.setImageTimer = setInterval(() => {
            this.calculatePinArea();
            this.adjustVersionListHeight();
            this.adjustCommentListingHeight();
        }, 500);


        const pusher = new Pusher('0673a2812f4cb4cc4a92', {
            cluster: 'eu',
            encrypted: true
        });

        const channel = pusher.subscribe('adboard-item-' + this.adboardItemId);
        channel.bind('file-processed', (pusherData) => {

            console.log('item binded', pusherData);
            this.fetchAdboardItem();

            McmEventBus.$emit('adboardChildFilesProcessingFinished', pusherData);
        });

        channel.bind('child-file-processed', (data) => {

            console.log('child file processed binded', data);

            McmEventBus.$emit('adboardChildFileProcessed', data);

        });
        channel.bind('page-counted', (data) => {

            console.log('page-counted', data);

            McmEventBus.$emit('adboardChildFilePageCounted', data);
        });


        $(window).resize(()=>{
            if(this.zoomEnabled) {
             //   this.imageZoomEnableDisable(true);
            }
        });

    },
    watch: {

        /**
         * Adjust comment listing height
         *
         * @param val
         * @param oldVal
         */
        comments(val, oldVal) {
            this.adjustCommentListingHeight();
        },

        /**
         * If not set yet or number of items changed update files set it to the first file
         *
         * @param val
         * @param oldVal
         */
        adboardItem(val, oldVal) {
            if (oldVal === false ||
                val.adboard_files.length !== oldVal.adboard_files.length ||
                this.currentFile === false
            ) {
                const firstAdboardFile = this.adboardItem.adboard_files[0];

                if (firstAdboardFile !== undefined && firstAdboardFile.child_adboard_files.length) {
                    this.currentFile = firstAdboardFile.child_adboard_files[0];
                } else {
                    this.currentFile = firstAdboardFile;
                }
            }



            //initially or if there is no file show upload modal
            if(val  && val.adboard_files.length === 0) {
                setTimeout(()=>{
                    this.uploadNewFile();
                },1000);
            }
        },


        /**
         * Set value to current file
         *
         * @param val
         * @param oldVal
         */
        approvedFile(val, oldVal) {
            if (val) {
                this.setCurrentFile(val);
            }
        },

        /**
         * Image size and emit new pin connection
         *
         * @param val
         * @param oldVal
         */
        imageSize(val, oldVal) {
            if (val.width !== oldVal.width) {
                this.$emit('new-pin-connection');
            }
        },

        /**
         * pinCoordinates
         *
         * @param val
         * @param oldVal
         */
        pinCoordinates(val, oldVal) {
            Belay.off();

            if (val !== false) {
                if (!this.isCommentsOpen) {
                    this.isCommentsOpen = true;
                }

                this.$emit('new-pin-connection');
            }
        },

        /**
         * Set sticky comments to current file
         * @param val
         * @param oldVal
         */
        currentFile(val, oldVal) {
            this.setStickyComments();
            this.zoomEnabled = false;
            this.pinCoordinates = false;
        },

        /**
         * Toggle comments
         *
         * @param val
         * @param oldVal
         */
        toggleComments(val, oldVal) {
            if (!val) {
                Belay.off();
            }
        },

        zoomEnabled(val, oldVal) {
            this.imageZoomEnableDisable(val);
        },

        isInfoOpen(val, oldVal) {
            if(this.zoomEnabled) {
                // if zoom is already enabled to retrigger it
                this.imageZoomEnableDisable(true);
            }
        }
    },
    events: {

        /**
         * Fetch comments
         * @param comments
         */
        'comments-fetched'(comments) {
            //assign comments
            this.comments = comments;

            this.setStickyComments();

            //stop loading action
            this.commentsLoading = false;

            //clear new pin coordinates
            this.pinCoordinates = false;
        },

        /**
         * Comment added event
         */
        'comment-added'() {
            this.fetchComments();
            this.clearCommentForm();

        },

        /**
         * Current file changed event
         * @param file
         */
        'current-file-changed'(file) {
            this.setCurrentFile(file);
            this.setStickyComments();
            this.adjustCommentListingHeight();
        },

        /**
         * Comment reply requested
         * @param comment
         */
        'comment-reply-requested'(comment) {
            this.replyToComment = comment;
            this.replyToCommentId = comment.id;

            //go to reply box
            $('body,html').animate({scrollTop: 0});
            $('#adboard-comment-message-input').focus();
        },

        /**
         * New pin connection event
         */
        'new-pin-connection'() {
            Belay.off();
            if (this.pinCoordinates) {
                drawConnection($('#adboard-item-pin-new'), $('#adboard-comment-message-input'));
            }
        }
    },
    methods: {

        /**
         * Toggle info method
         */
        toggleInfo() {
            this.isInfoOpen = !this.isInfoOpen;
        },

        /**
         * Toggle comments method
         */
        toggleComments() {
            this.isCommentsOpen = !this.isCommentsOpen;
        },

        /**
         * Fetch comments
         */
        fetchComments() {
            const url = SiteVariables.urls.adboardItemsCommentsController + '/index/' + this.adboardItemId;
            this.commentsLoading = true;

            $.get(url, {}, (response) => {
                this.$broadcast('comments-fetched', response.comments);
                this.$emit('comments-fetched', response.comments);
            });
        },

        /**
         * Fetch adboard item
         */
        fetchAdboardItem() {
            $.get(this.adboardItemUrl, {}, (response) => {
                this.adboardItem = response.adboardItem;
                this.$emit('adboard-item-fetched');
                this.$broadcast('adboard-item-fetched');
            });
        },

        /**
         * Clear comment form
         */
        clearCommentForm() {
            const adboardCommentMessageInput = $('#adboard-comment-message-input');

            adboardCommentMessageInput.val('');
            adboardCommentMessageInput.removeAttr('disabled');

            this.replyToComment = false;
            this.replyToCommentId = '';
        },

        /**
         * Adjust comment listing height
         */
        adjustCommentListingHeight() {
            const commH = $('#comments-section-container').height();
            const commSec = $('#comments-form-container').height();

            $('.adboard-item__comments-list').height(commH - commSec - 100);
        },

        /**
         * adjustVersionListHeight Method
         */
        adjustVersionListHeight() {
            const commH = $('#adboard-details-container').height();
            const commSec = $('#adboard-details-info').height();

            $('#adboard-versions-list').height(commH - commSec - 50);
        },

        /**
         * afterCommentAdded Method
         */
        afterCommentAdded() {
            this.fetchComments();
            this.clearCommentForm();
        },

        /**
         * Opens upload new file interface
         * @return {*|boolean}
         */
        uploadNewFile() {
            return MCMApp.AdboardModalActions.uploadNewFileToItemModal(this.adboardItem.id);
        },

        /**
         * Opens upload new file interface
         * @return {*|boolean}
         */
        approveCurrentFile() {
            return MCMApp.AdboardModalActions.approveItemWithFile(this.adboardItem.id, this.currentParentFile.id);
        },

        /**
         * Opens upload new file interface
         * @return {*|boolean}
         */
        showVersionsOfItem() {
            return MCMApp.AdboardModalActions.showVersionsOfItem(this.adboardItem.id);
        },

        /**
         * Edit item modal
         * @return {*|boolean}
         */
        editItem() {
            window.location.href = this.adboardItem.edit_url;
            return;
        },

        /**
         * Set current file
         * @param file
         */
        setCurrentFile(file) {
            this.currentFile = file;
        },

        /**
         * Set sticky comments of the current item by
         * filtering comments to get the sticky ones
         */
        setStickyComments() {
            let stickyComments = [];


            if (this.comments && this.currentFile) {
                //filter comments to get the sticky ones
                stickyComments = this.comments.filter((element) => {
                    return (element.pin_coordinates !== false && this.currentFile.id == element.adboard_file_id);
                });
                this.stickyComments = stickyComments.reverse();
            }
        },

        /**
         * Calculate pin area
         */
        calculatePinArea() {
            const $img = $('#adboard-item-viewed-image'),
                $pins = $('.adboard-item__doc-pins'),
                imageSize = {
                    width: $img.width(),
                    height: $img.height(),
                };

            this.imageSize = imageSize;

            $pins.offset($img.offset());
            $pins.width($img.width()).height($img.height());
        },

        /**
         * addPinnedComment Method
         * @param event
         */
        addPinnedComment(event) {
            const $img = $('#adboard-item-viewed-image'),
                offset = $img.offset(),
                pinCoordinate = {
                    left: event.pageX - (offset.left + 15),
                    top: event.pageY - (offset.top + 15)
                },
                imageSize = {
                    width: $img.width(),
                    height: $img.height(),
                };

            this.imageSize = imageSize;

            //convert them to percentage to saving
            const pinCoordinatesPerc = {
                left: Math.round((pinCoordinate.left * 100) / imageSize.width),
                top: Math.round((pinCoordinate.top * 100) / imageSize.height)
            };

            //attach this to comment form
            this.pinCoordinates = pinCoordinatesPerc;

            //focus on comment box
            $('#adboard-comment-message-input').focus();
        },

        /**
         * Cancel pinned comment
         */
        cancelPinnedComment() {
            this.pinCoordinates = false;
        },

        /**
         * Works on submit of a comment form
         * @param event
         */
        commentFormSubmit(event) {
            const $form = $('#adboard-comment-form'),
                url = $form.prop('action'),
                data = $form.serialize();
            this.commentsLoading = true;
            $('#adboard-comment-message-input').attr('disabled', 'disabled');


            console.log(data);

            $.post(url, data, () => this.$emit('comment-added'));
        },

        /**
         * Show all schedueld meetings
         * @return {*|boolean}
         */
        showAllMeetings() {
            return MCMApp.AdboardModalActions.showAllMeetings(this.adboardItem.id);
        },

        /**
         * Enable or disable imageZoom
         * TODO bad fn naming
         * @param val
         */
        imageZoomEnableDisable(val) {
            if (val) {
                //Enable ezPlus zoom plugin
                $('#adboard-item-viewed-image').ezPlus({
                    zoomType: 'inner',
                    cursor: 'crosshair',
                    responsive: true,
                });
            } else {
                //destroy ezPlus zoom plugin
                const action = 'hide';
                const plugin = $('#adboard-item-viewed-image').data('ezPlus');
                if (plugin) {
                    plugin.showHideZoomContainer(action);
                    plugin.showHideWindow(action);
                    plugin.showHideTint(action);
                    plugin.showHideLens(action);
                    plugin.destroy();
                }
            }
        },

        docScrolled(){

            console.log('scrolled',this.pinCoordinates);
            if(this.pinCoordinates) {
                this.pinCoordinates = false;
            }
        }

    }
});

/**
 * Media site stream component for touchpoints
 */
Vue.component('mediasite-ondemand-stream', {
    props   : {
        videoId          : {
            type    : [Number, String],
            required: true,
        },
        touchpointId     : {
            type    : [Number, String],
            required: true
        },
        accreditationMode: {
            type   : [Boolean, String],
            default: false,
        },
    },
    data() {
        return {
            loading       : true,
            playerViewTime: 0,
            video         : false,
            questions     : false,
        };
    },
    computed: {
        canDisplayVideo() {
            return (!!this.video && !!this.questions);
        }
    },
    created() {
        this.fetchContent();
    },
    methods : {
        fetchContent() {
            const url  = SiteVariables.urls.touchpointAccreditation + '/handle',
                  data = {
                      touchpointId: this.touchpointId,
                      videoId     : this.videoId,
                      action_name : 'pollView'
                  };
            $.ajax({
                url     : url,
                dataType: 'JSON',
                type    : 'POST',
                data    : data,
                success : (response) => {
                    if (response.error === false) {
                        this.questions = response.questions;
                        this.video     = response.video;
                    }
                },
                error   : response => console.error(response)
            });
        }
    }
});

// Adboard-schedule-meeting
//-------------------------------------------------------------

/**
 *  Adboard-schedule-meeting component
 */
Vue.component('adboard-schedule-meeting', {
    template: '#adboard-schedule-meeting-template',
    props: {
        url: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            type: 'add',
            loading: true,
            touchpoint: {
                description: '',
                name: '',
                eventendtime: '',
                eventstarttime: '',
            },
            adboardItem: {},
            error: {
                server: false,
                status: false,
                required: false,
            },
            success: {
                created: false,
                updated: false,
                status: false
            },
            responseTimeout: 10000,
            date: moment().format('DD-MM-YYYY'),
            startTime:false,
            endTime:false,
            required: {
                validation: false,
                inputs: ['name', 'description', 'eventstarttime', 'eventendtime']
            }
        };
    },
    computed: {

        /**
         * Create meeting
         * @return {boolean}
         */
        createType() {
            return Boolean(this.type === 'add');
        },

        /**
         * Update meeting
         * @return {boolean}
         */
        updateType() {
            return Boolean(this.type === 'edit');
        },


        /**
         * Check if touchpoint is set and not empty
         * @return {boolean}
         */
        touchpointSet() {
            return Boolean(this.touchpoint && !_.isEmpty(this.touchpoint));
        },

        /**
         * validateForm Method
         *
         * @return {boolean}
         */
        validateForm() {
            const requiredInputs = this.required.inputs,
                requiredInputsCount = requiredInputs.length;

            let filledInputCount = 0;

            requiredInputs.forEach((val) => {
                if (this.touchpoint[val] && this.touchpoint[val] !== '') {
                    filledInputCount++;
                }
            });

            return Boolean((requiredInputsCount === filledInputCount) && (this.date && this.date !== null));
        },

        timeIntervalOk() {

        }
    },
    created() {
        this.loadMeeting();
        McmEventBus.$on('dateChangedOnDatepicker', this.datePickerListener);


    },
    watch: {

        /**
         * Reinit elements due to re-render of element [Needed for calendar]
         */
        loading() {
            McmPlatformMainElementsInit();
            if (this.date !== null) {
                $('#adboard-schedule-meeting-calendar').datepicker("setDate", this.date);
            }
        },

        /**
         * Remove success message after this.responseTimeout seconds
         * @param val
         */
        'success.status'(val) {
            if (val === true) {
                //window.setTimeout(() => this.success.status = false, this.responseTimeout);
            }
        },


        'touchpoint.eventstarttime': function (val, oldVal) {


            const parts = val.split(':');

            let now = moment();

            now = now.hour(parts[0]);
            now = now.minute(parts[1]);

            if (now.isValid()) {
                if (this.touchpoint.eventendtime === '') {
                    this.touchpoint.eventendtime = now.add(1, 'hour').format('HH:mm');
                }
            }
        }
    },
    methods: {

        /**
         * Load meeting on component creation to set what type of action is needed [add || edit]
         */
        loadMeeting() {
            $.ajax({
                url: this.url,
                type: 'POST',
                data: {action_name: 'view'},
                dataType: 'JSON',
                success: (data) => this.setMeetingActionType(data),
                error: (data) => this.handleError(data)
            });
        },

        /**
         * Create / Edit meeting
         * @param e
         */
        createMeeting(e) {
            if (this.validate()) {
                const form = $(e.currentTarget),
                    url = this.url;
                this.loading = true;

                $.ajax({
                    url: url,
                    type: 'POST',
                    data: form.serialize(),
                    dataType: 'JSON',
                    success: (data) => this.handleMeetingCreation(data),
                    error: (data) => this.handleError(data)
                });
            }
        },

        /**
         * Set type of action needed for form, it should either be add or edit
         * @param data
         */
        setMeetingActionType(data) {
            this.loading = false;
            this.adboardItem = data.adboardItem;

            if (data.touchpoint && !_.isEmpty(data.touchpoint)) {
                this.touchpoint = data.touchpoint;
                this.type = 'edit';
            }

            //  this.date = this.touchpointSet ? moment(this.touchpoint.date).format('D-M-Y') : null;
        },

        /**
         * Handle the creation of meeting and set data
         * @param data
         */
        handleMeetingCreation(data) {
            if (data.response) {
                this.loading = this.error.status = this.error.server = false;
                this.success.status = true;
                this.success.created = this.createType;
                this.success.updated = this.updateType;
                this.type = 'edit';
                this.touchpoint = data.touchpoint;
            }
        },

        /**
         * Set error message if no response from server
         * @param data
         */
        handleError(data) {
            this.loading = false;
            this.error.status = this.error.server = true;
        },

        /**
         * Assign input values on vue instance dateSettings keys
         * to use in interface for validation, comparison, computed props etc.
         *
         * @param $input
         */
        datePickerListener($input) {
            const elementId = $input.attr('id');

            if (elementId === 'adboard-schedule-meeting-calendar') {
                this.date = $input.val();
            }
        },

        /**
         * Validate and set requred error if it is the case
         * @return {boolean|*}
         */
        validate() {
            this.required.validation = this.validateForm;
            this.error.server = false;
            this.error.status = this.error.required = !this.required.validation;

            return this.required.validation;
        },

        goToView() {
            window.location.href = this.adboardItem.view_url;
        }
    },
});


/**
 * Accreditations list in TP editing page
 */
Vue.component('touchpoint-accreditation-list', {
    template: '#touchpoint-accreditation-list-template',

    props: {
        accreditations: {
            type: [Array, Boolean]
        }
    },
    methods: {

        editAccreditation(accreditation){
            McmEventBus.$emit('needToEditTouchpointAccreditation', accreditation);
        },
        deleteAccreditation(accreditation){

            //TODO [Bora]: please set strings like these in php so that they can be translated.
            var confirmation = window.confirm('Do you want to delete this accreditation?');

            //TODO [Bora] please try not to leave open console logs in the scripts, you have been a very dirty boy Bora.
            console.log(confirmation);

            if (false === confirmation) {
                return false;
            }

            const url = SiteVariables.urls.accreditationsController + '/handle';

            $.ajax({
                url: url,
                type: 'delete',
                data: {accreditation_id: accreditation.id},
                dataType: 'json',
                success: (data) => {
                    McmEventBus.$emit('updateTouchpointAccreditationOnEditPage');
                },
                error: (data) => {
                    console.error(data);
                }
            });
        }
    }

});
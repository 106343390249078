var MCMApp = MCMApp || {};

/**
 * Touchpoint edit accreditation mixin
 *
 * @type {{}}
 */
MCMApp.touchpointEditAccreditationMixin = {
    data: {
        accreditationData: {
            accreditation: false,
            providers: false,
            countries: false
        },
        pollsData: {
            accreditation: false,
        }
    },
    computed: {

        /**
         * Number of questions in currently viewed accreditation subscreen (polls, accreditation questions etc)
         *
         * @returns {string}
         */
        currentAccreditationSubSectionQuestionCount(){
            return this.getCount(this.accreditationData);
        },

        /**
         * Number of questions in currently viewed accreditation subscreen (polls, accreditation questions etc)
         *
         * @returns {string}
         */
        currentPollsSubSectionQuestionCount(){
            return this.getCount(this.pollsData);
        }
    },
    watch: {

        /**
         * Update questions if questions section is displayed
         *
         * @param val
         * @param oldVal
         */
        'displayed.accreditation': function (val, oldVal) {
            if (val === true && this.accreditationData.providers === false) {
                this.updateAccreditationProviders();
            }
        }
    },
    created(){
        McmEventBus.$on('updateTouchpointAccreditationOnEditPage', this.updateAccreditationQuestions);
        McmEventBus.$on('needToEditTouchpointAccreditation', () => {
            this.displayed.accreditation_settings = true;
        });
    },
    ready(){
        this.updateAccreditationQuestions();
    },
    methods: {

        /**
         * Updates accreditation question data of current touchpoint
         */
        updateAccreditationQuestions(){
            const url = SiteVariables.urls.touchpointAccreditation + '/index/' + this.touchpointId;

            $.get(url, {}, (response) => {
                this.$set('accreditationData.accreditation', response.questions);
                this.$set('accreditationData.accreditations', response.accreditations);

                this.$set('pollsData.accreditation', response.pollQuestions);
            });

        },

        /**
         * Updates accreditation providers and unique countries data
         */
        updateAccreditationProviders(){
            const url = SiteVariables.urls.accreditationProviders + '/index/';

            $.post(url, {}, (response) => {
                this.accreditationData.providers = response.accreditationProviders;
                this.accreditationData.countries = response.countries;
            });
        },

        /**
         * Opens modal to add accreditation
         *
         * @returns {*}
         */
        addAccreditationQuestion() {
            let type;

            if (this.subSections.accreditation.questions) {
                type = 'accreditation';
            } else if (this.subSections.polls.questions) {
                type = 'polls';
            }

            return MCMApp.AccreditationModalActions.addQuestion(type, this.touchpointId);
        },


        /**
         * Get the count of all current questions
         *
         * @param typeObj
         * @returns {Number}
         */
        getCount(typeObj){
            return !typeObj.accreditation ? 0 : typeObj.accreditation.length;
        }

    }
};
Vue.component('video-platform-pagination', {
    template: '#video-platform-pagination-template',
    props: {
        pagination: {
            type: Object,
            required: true
        }
    },
    methods: {
        changePage(page) {
            if (page && this.pagination.page != page) {
                this.$emit('change-page', page);
            }
        },
    }
});

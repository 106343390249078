Vue.component('adboard-new-user-form', {
    template: '#adboard-new-user-form-template',

    props: {
        formCount: {
            type: Number,
            required: true
        }
    },
    methods: {
        close() {

            console.log(this.index);
            McmEventBus.$emit('deleteFormFromAdboardManagement', this);
        }
    }
});


Vue.component('adboard-manage-board-users', {
    data() {
        return {
            noOfNewUsers: 0,
        };
    },
    created() {
        McmEventBus.$on('deleteFormFromAdboardManagement', this.removeForm);

    },
    methods: {

        addNewForm(e) {
            this.noOfNewUsers++;
        },
        removeForm(){

        }
    }
});
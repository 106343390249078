// register modal component
Vue.component('modal-delete-list', {
    template: '#modal-delete-list-template',
    props: {
        hcp: {
            type: Object,
            required: false
        }
    },
    methods: {
        yes(){
            this.$emit('close');
            this.$emit('delete');
        }
    },
    computed: {

    }
});

Vue.component('video-platform-hcp-management-target-list', {
    template: '#video-platform-hcp-management-target-list-template',
    props: {},
    data() {
        return {
            loading: false
        };
    },
    ready() {
        McmPlatformMainElementsInit();
    },
    methods: {
        targetListSelected(e) {
            let extension = e.target.value.split('.').pop();
            if (extension === 'csv') {
                this.checkHeaders(e.target.files[0], (missingFields) => {
                    if (!missingFields.length) {
                        this.uploadTargetList(e.target.files[0]);
                    } else {
                        e.target.value = null;
                        MCMApp.VideoPlatformModalActions.targetListsModal({
                            message: 'missingColumn',
                            col: missingFields
                        });
                    }
                });
            } else {
                e.target.value = null; //reset
                MCMApp.VideoPlatformModalActions.targetListsModal({message: 'wrongExtension'});
            }
        },
        uploadTargetList(file) {
            this.loading = true;
            let form = $("#uploadTargetListForm")[0];
            let formData = new FormData(form);
            formData.append('path', file); // IE 11 fix, should pass file, or it pass empty values
            let _this = this; //use this in ajax to call parent
            let action = form.getAttribute('action');

            $.ajax({
                type: 'POST',
                url: action,
                data: formData,
                contentType: false,
                processData: false,
                success: (response) => {
                    if (response.success) {
                        MCMApp.VideoPlatformModalActions.targetListsModal({message: 'success'});
                        this.loading = false;
                        _this.$parent.switchTab(0); //on success switch to all sub tab
                    }
                },
                error: (response) => {
                    MCMApp.VideoPlatformModalActions.targetListsModal({message: 'serverError'});
                    this.loading = false;
                },
            });

            form.reset();
        },
        checkHeaders(file, callback) {
            let valuesArray = ['email', 'firstname', 'lastname', 'title', 'expertise', 'institution', 'language'];
            let reader = new FileReader();
            let dataRange = [];
            reader.onload = function (e) {
                if (reader.result)
                    reader.content = reader.result;

                let binaryFile = e ? e.target.result : reader.content;

                let workbook = XLSX.read(binaryFile, {type: 'binary'});
                let worksheet = workbook.Sheets[workbook.SheetNames[0]];

                let range = {s: {c: 0, r: 0}, e: {c: 6, r: 0}};

                /* Iterate through each element in the structure */
                for (let R = range.s.r; R <= range.e.r; ++R) {
                    for (let C = range.s.c; C <= range.e.c; ++C) {
                        let cell_address = {c: C, r: R};
                        let data = XLSX.utils.encode_cell(cell_address);
                        dataRange.push(worksheet[data].v);
                    }
                }

                callback(valuesArray.filter(element => dataRange.indexOf(element) === -1));
            };

            //readAsBinaryString function is not defined in IE
            //Adding the definition to the function prototype
            if (!FileReader.prototype.readAsBinaryString) {
                FileReader.prototype.readAsBinaryString = function (fileData) {
                    var binary = '';
                    var pk = this;
                    var reader = new FileReader();

                    reader.onload = function (e) {
                        var bytes = new Uint8Array(reader.result);
                        var length = bytes.byteLength;

                        for (var i = 0; i < length; i++) {
                            var a = bytes[i];

                            var b = String.fromCharCode(a);
                            binary += b;
                        }

                        pk.content = binary;
                        $(pk).trigger('onload');
                    };

                    reader.readAsArrayBuffer(fileData);
                };
            }

            reader.readAsBinaryString(file);
        }
    }

})
;

var MCMApp = MCMApp || {};

MCMApp.Templates = MCMApp.Templates || {};
MCMApp.Templates.MeetingPoint = MCMApp.Templates.MeetingPoint || {};


MCMApp.Templates.MeetingPoint = {

    dashboardInstance : function(areaId,templatesPackageCount){
        return   new Vue({
            el: '#meetingpoint-listing',
            data: {
                datesCollection: false,
                areaId: areaId,
                searchQuery: ''
            },
            created: function () {
                this.updateList();
                //listen modals on the page
                $('#app-modal').on('hidden.bs.modal', (e) =>{
                    this.updateList();
                });
            },
            computed: {
                url: function () {
                    return SiteVariables.urls.meetingpointController + '/index/' + this.areaId;
                }
            },
            events: {
                'dataUpdateNeeded': function () {
                    this.updateList();
                }
            },
            methods: {
                /**
                 * Update list of appointments
                 */
                updateList: function () {
                    $.get(this.url, {},  (response) => {
                        this.datesCollection = response.datesCollection;
                    });
                },
                /**
                 * Trigger modal for new appointment
                 * @returns {*}
                 */
                addNewAppointmentModel: function () {
                    if (templatesPackageCount) {
                        return MCMApp.MeetingpointModalActions.addNewAppointment(this.areaId);
                    } else {
                        return MCMApp.MeetingpointModalActions.createNewTemplate(this.areaId);
                    }
                },
                /**
                 * Create form submitted. dataUpdateNeeded triggered afterwards
                 * to refresh the list
                 * @param e
                 */
                onSubmitCreate: function (e) {
                    const data = $(e.currentTarget).serialize();
                    const url = e.currentTarget.getAttribute('action');
                    $.post(url, data,  (response) => {
                        this.$emit('dataUpdateNeeded');
                    });
                }
            }
        });
    }

};
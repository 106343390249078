var MCMApp = MCMApp || {};
MCMApp.VM = MCMApp.VM || {};

// User registration page for user_registrar role
//-------------------------------------------------------------

MCMApp.VM.UserRegistration = {
    view: function (touchpointId, elementId) {
        Vue.component('hcp-custom-registration-list',{
            template: '#hcp-custom-registration-list',
            props: ['touchpointId'],
            data: function () {
                return {
                    touchpoint: false,
                    registrations: false
                };
            },
            created: function () {
                var _this = this;
                this.updateData();

                console.log('yes here');
                //listen modals on the page
                $('#app-modal').on('hidden.bs.modal', function (e) {
                    _this.updateData();
                });
            },
            methods: {
                updateData: function () {
                    var _this = this;
                    $.get(SiteVariables.urls.userRegistrationController + '/view/' + this.touchpointId, {}, function (response) {
                        _this.touchpoint = response.touchpoint;
                        _this.registrations = response.registrations;
                    });
                },
            }
        });
    }
};

/**
 * Accreditation view in TP editing page
 */
Vue.component('touchpoint-accreditation-editing-box', {
    template: '#touchpoint-accreditation-editing-box-template',
    props: {
        touchpointId: {
            type: Number
        }
    },
    data(){
        return {
            providers: false,
            countries: false,
            form: {
                country_id: '',
                accreditation_provider_id: '',
                points: 1,
                accreditation_id: false,
            },
        };
    },
    computed: {
        /**
         * Filtered down list of providers on selected country
         * @returns {boolean}
         */
        countryProviders(){
            if (this.form.country_id > 0) {
                return this.providers.filter(item => item.country_id == this.form.country_id);
            }
            return false;
        }
    },
    created(){
        this.updateAccreditationProviders();

        McmEventBus.$on('needToEditTouchpointAccreditation', this.editAccreditation);
    },
    destroyed(){

    },
    ready(){
        McmPlatformMainElementsInit();
    },
    methods: {

        resetForm(){
            this.$set('form.country_id', '');
            this.$set('form.accreditation_provider_id', '');
            this.$set('form.points', 1);
            this.$set('form.accreditation_id', false);
        },

        /**
         * Updates accreditation providers and unique countries data
         */
        updateAccreditationProviders(){
            const url = SiteVariables.urls.accreditationProviders + '/index/';

            $.post(url, {}, (response) => {
                this.providers = response.accreditationProviders;
                this.countries = response.countries;
            });
        },

        submitForm(event){
            const $form = $(event.currentTarget);
            const url = $form.prop('action');

            let data = this.form;
            data.touchpoint_id = this.touchpointId;

            //if this is an update it should be put or else post
            const httpMethod = (this.form.accreditation_id === false) ? 'post' : 'put';

            $.ajax({
                url: url,
                type: httpMethod,
                data: data,
                dataType: 'json',
                success: (data) => {
                    McmEventBus.$emit('updateTouchpointAccreditationOnEditPage');
                    McmEventBus.$emit('closeEditBoxesOnTouchpointEditPage');
                    this.resetForm();
                },
                error: (data) => {
                    console.error(data);
                }
            });
        },

        /**
         * Loads accreditation to the form for editing
         * @param accreditation
         */
        editAccreditation(accreditation){
            this.$set('form.country_id', accreditation.accreditation_provider.country_id);
            this.$set('form.accreditation_provider_id', accreditation.accreditation_provider.id);
            this.$set('form.points', accreditation.points);
            this.$set('form.accreditation_id', accreditation.id);
        }
    }

});
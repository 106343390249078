Vue.component('permissions-selection',{
    template: '#permissions-selection',
    props: ['permissions','selected'],

});

Vue.component('permissions-selection-item',{
    template: '#permissions-selection-item-template',
    props: ['permission','selected'],

    computed : {
        isSelected : function(){
            return this.selected.indexOf(parseInt(this.permission.id)) > -1;
        },

        itemId : function () {
            return 'permission-'+this.permission.id;
        }
    }

});

// Adboard groups components
//-------------------------------------------------------------


/**
 * Main group listing in adboard. This component is a page container component
 * So it has a inline template instead.
 */
Vue.component('adboard-groups-dashboard', {
    data: function () {
        return {
            adboardGroups: [],
            users: false,
            selectedGroup: false,
            search: ''
        };
    },
    computed: {
        displayedUsers: function () {
            if (this.selectedGroup) {
                return this.selectedGroup.users;
            }
            return this.users;
        },

        /**
         * If there is a search input this filters adboard_items
         *
         * @returns {*}
         */
        adboardGroupsFiltered(){
            if (this.search.trim().length === 0) {
                return this.adboardGroups;
            }
            const items = this.adboardGroups.filter((item) => {
                return item.title.match(new RegExp(this.search, 'i'));
            });
            return items;
        }
    },

    ready: function () {
        this.updateAdboards();
        this.updateUsers();

        McmEventBus.$on('selected-group-toggled', (adboardGroup) => {
            this.toggleSelectedGroup(adboardGroup);
        });
        McmEventBus.$on('resetSelectedGroup', () => {

            this.selectedGroup = false;
        });

        $('#app-modal').on('hidden.bs.modal', (e) => {
            this.updateAdboards();
            this.updateUsers();
        });
    },
    methods: {
        updateAdboards() {
            const url = SiteVariables.urls.adboardGroupsController;
            $.get(url, {}, (response) => {
                if (response) {
                    this.adboardGroups = response.adboardGroups;
                }
            });
        },
        updateUsers() {
            const url = SiteVariables.urls.adboardGroupsUsersController;
            $.get(url, {}, (response) => {
                if (response) {
                    this.users = response.users;
                }
            });
        },
        toggleSelectedGroup(adboardGroup) {
            if (this.selectedGroup !== adboardGroup) {
                this.selectedGroup = adboardGroup;
            } else {
                this.selectedGroup = false;
            }
        },
        openAddNewBoardModal(){
            MCMApp.AdboardModalActions.addNewGroupModal();
        },
        redirectToAddNewBoard(){
            window.location.href = SiteVariables.urls.adboardGroupsAdd;
        },
        /**
         * Clears search input string
         */
        clearSearch(){
            this.search = '';
        },
    }
});


/**
 * Adboard groups sidebar
 */
Vue.component('adboard-groups-sidebar', {
    template: '#adboard-groups-sidebar-template',
    props: {
        selectedGroup: {
            required: true
        },
        adboardGroups: {
            type: Array,
            required: true
        }
    },
    methods: {
        resetSelectedGroup: function () {
            McmEventBus.$emit('resetSelectedGroup');
        }
    }
});


/**
 * Adboard groups content
 */
Vue.component('adboard-groups-content', {
    template: '#adboard-groups-content-template',
    props: {
        selectedGroup: {
            required: true
        },
        displayedUsers: {
            type: [Array, Boolean],
            required: false
        }
    },

    methods: {

        addNewMember: function () {
            MCMApp.UserModalActions.addUserWithInitialRole('adboard_member', window.location.href);
        },
        editGroup: function () {
            MCMApp.AdboardModalActions.editGroupModal(this.selectedGroup.id);
        },
        deleteGroup: function () {
            MCMApp.AdboardModalActions.editGroupModal(this.selectedGroup.id);
        }
    }
});


/**
 * Adboard memeber card
 */
Vue.component('adboard-member-card', {
    template: '#adboard-member-card',
    props: ['member']
});

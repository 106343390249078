Vue.component('video-platform-hcp-management-pending-requests', {
    template: '#video-platform-hcp-management-pending-requests-template',
    props: {
        actionUrls: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showDenyHcpModal: false,
            showDenyHcpModalSuccess: false,
            hcps: [],
            hcpsInitial: [],
            hcp: {
                info: {}
            },
            numOfRequests: 0,
            active_el: 0,
            search: '',
            loading: true
        };
    },
    created(){
        this.getInitials();
    },
    ready() {
        setTimeout(() => {
            this.getHcps();
        },500);
    },
    methods: {
        getHcps() {
            $.post(this.actionUrls.get, {}, (response) => {
                if (response.success === true) {

                    this.$emit('refresh');

                    if(response.data.hcps.length) {
                        this.loading = false;
                        this.hcps = response.data.hcps;
                        this.hcp = this.hcps[0];
                        this.active_el = this.hcps[0].id;
                        this.numOfRequests = response.data.hcps.length;
                    } else {
                        this.loading = false;
                    }
                }
            });
        },
        refresh() {
            $.post(this.actionUrls.get, {}, (response) => {
                if (response.success === true) {
                    if(response.data.hcps.length) {
                        this.hcpsInitial = response.data.hcps;
                        this.hcps = response.data.hcps;
                        this.getInitials();
                        this.numOfRequests = response.data.hcps.length;
                    }
                }
            });
        },
        getInitials() {
            $.post(this.actionUrls.get, {}, (response) => {
                if (response.success === true) {
                    if(response.data.hcps.length) {
                        this.hcpsInitial = response.data.hcps;
                    }
                }
            });
        },
        onUpdate(hcp) {
            this.hcp = hcp;
            this.refresh();
        },
        denyRequest() {

            this.loading = true;
            var url = "/admin/users/deny/" + this.hcp.id;
            $.post(url, (response) => {

                if (response.success === true) {
                    this.getHcps();
                    this.showDenyHcpModalSuccess = true;
                    this.loading = false;
                    if((this.numOfRequests - 1) == 0) {
                        this.selectNone(); //empty the form and disable all the fields in it.
                        this.active_el = 0; //this is just to disable the search field.
                        this.numOfRequests = 0; //this is just to display the message "No pending requests found..!"

                    }
                }
            });
            //MCMApp.VideoPlatformModalActions.DenyRequestModal(this.hcp.id, this.hcp.firstname, this.hcp.lastname);
        },
        approveRequest() {
            this.loading = true;
            var url = "/admin/users/approve/" + this.hcp.id;
            $.post(url, (response) => {

                if (response.success === true) {
                    this.getHcps();
                    MCMApp.VideoPlatformModalActions.ApproveRequestModal();
                    if((this.hcps.length - 1) == 0) {
                        this.selectNone(); //empty the form and disable all the fields in it.
                        this.active_el = 0; //this is just to disable the search field.
                        this.numOfRequests = 0; //this is just to display the message "No pending requests found..!"
                    }
                }
            });
        },
        selectRequest: function(hcp, approve = undefined) {
            this.refresh();
            this.hcp = hcp;
            this.activate(hcp.id);

            if (approve !== undefined){

                if (approve) {
                    this.approveRequest();
                } else {
                    this.showDenyHcpModal = true;
                }
            }
        },
        activate:function(el){
            this.active_el = el;
        },
        selectNone(){
            this.refresh();
            this.active_el = -1;
            this.hcp = {};
            this.hcp.info = {};
        }
    },
    computed: {
        filteredList: function () {
            return this.hcps.filter(hcp => {

                if(typeof(hcp.firstname) == 'undefined'){hcp.firstname = '';}
                if(typeof(hcp.lastname) == 'undefined'){hcp.lastname = '';}
                if(typeof(hcp.email) == 'undefined'){hcp.email = '';}
                if(typeof(hcp.info.title) == 'undefined'){hcp.info.title = '';}
                if(typeof(hcp.info.expertise) == 'undefined'){hcp.info.expertise = '';}
                if(typeof(hcp.info.institution) == 'undefined'){hcp.info.institution = '';}

                return (
                    hcp.firstname.toLowerCase().match(this.search.toLowerCase()) ||
                    hcp.lastname.toLowerCase().match(this.search.toLowerCase()) ||
                    hcp.email.toLowerCase().match(this.search.toLowerCase()) ||
                    hcp.info.title.toLowerCase().match(this.search.toLowerCase()) ||
                    hcp.info.expertise.toLowerCase().match(this.search.toLowerCase()) ||
                    hcp.info.institution.toLowerCase().match(this.search.toLowerCase())
                );
            });
        }
    }
});

Vue.component('adboard-menu-bar', {
    template: '#adboard-menu-bar-template',
    data: function () {
        return {
            adboardGroups: [],
        };
    },
    created(){
        this.updateAdboards();
    },
    methods : {
        updateAdboards() {
            const url = SiteVariables.urls.adboardGroupsController;
            $.get(url, {}, (response) => {
                if (response) {
                    this.adboardGroups = response.adboardGroups;
                }
            });
        },
    }
});

var MCMApp = MCMApp || {};

MCMApp.Forms = MCMApp.Forms || {};


MCMApp.Forms.ProgramEntryAlerts = {
    init: function () {
        $('.program-entry-alert').change(function () {
            MCMApp.Forms.ProgramEntryAlerts.handle($(this));
        });
    },

    handle: function ($el) {
        const entryId = $el.data('id');
        const isSelected = $el.is(':checked');

        const actionToSend = (isSelected) ? 'add' : 'delete';

        const url = SiteVariables.urls.programEntryAlertsController + '/' + actionToSend + '/' + entryId;
        
        $.post(url, {}, (response) => {
            console.log(response);
        });
    }
};
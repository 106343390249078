// register modal component
Vue.component('modal-deny-hcp', {
    template: '#modal-deny-hcp-template',
    props: {
        hcp: {
            type: Object,
            required: false
        }
    },
    methods: {
        yes(){
            this.$emit('close');
            this.$emit('deny');
        }
    },
    computed: {

    }
});

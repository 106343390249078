// Vue component
//-------------------------------------------------------------
Vue.component('community-thread-detail', {
    template: '<div><slot></slot></div>',
    props: ['threadId', 'url'],
    data: function () {
        return {
            thread: false,
            loading: false,
            /**
             * When in answering mode, parent to answer
             */
            answeringTo: false
        };
    },
    created: function () {
        McmEventBus.$on('newCommentPosted', this.onNewCommentPosted);
        McmEventBus.$on('newCommentPostedOnThread', () => this.updateThread());
    },

    ready: function () {
        this.updateThread();
    },
    events: {
        replyingTo: function (parentId) {
            this.answeringTo = parentId;
        },

    },
    methods: {
        updateThread: function (disableLoader) {
            //dont show loading if loader is disabled
            this.loading = !disableLoader;

            $.get(this.url, null, (response) => {

                this.loading = false;
                if (response.thread !== undefined) {
                    this.thread = response.thread;
                }
            });
        },

        onNewCommentPosted: function (threadId, parentId) {

            //disable main loading view if a reply is made
            const disableLoader = !!parentId;

            this.updateThread(disableLoader);
            this.answeringTo = false;

            return true;
        }
    }
});


Vue.component('community-thread-list', {
    template: '#community-thread-list-template',
    props: ['groupId'],
    ready: function (done) {
        this.update();
    },
    data: function () {
        return {
            threads: false,
            loading: true
        };
    },
    methods: {
        update: function () {
            var _this = this;
            var url = SiteVariables.urls.communityGroupsController + '/view/' + this.groupId;
            this.loading = true;
            $.get(url, {}, function (response) {


                _this.$set('loading', false);
                if (response.group.threads) {
                    _this.$set('threads', response.group.threads);
                }
            });
        }
    }
});


Vue.component('community-group-card', {
    template: '#community-group-card-template',
    props: {
        group: {
            type: Object,
            required: true
        }
    },
    created : function () {
        McmEventBus.$on('openCardDetail',this.handleCardDetailEvent);
    },
    data : function () {
        return {
            detailsOpen : false
        };
    },
    methods: {
        cardClicked: function (e) {
            McmEventBus.$emit('openCardDetail',this.group.id);
        },
        handleCardDetailEvent : function (cardIdToOpen) {
            //close if already opened
            if( this.detailsOpen ) {
                this.detailsOpen = false;
                return;
            }

            //opens clicked item if closed or closes opened one
            this.detailsOpen = (cardIdToOpen === this.group.id);
        }
    }
});


Vue.component('community-group-list', {
    template: '#community-group-list-template',
    props: ['communityId'],
    ready: function (done) {
        this.update();
    },
    data: function () {
        return {
            groups: false,
            loading: true
        };
    },
    methods: {
        update: function () {
            const url = SiteVariables.urls.communityCommunitiesController + '/view/' + this.communityId;
            this.loading = true;
            $.get(url, {}, (response) => {

                this.$set('loading', false);
                if (response.community.groups) {
                    this.$set('groups', response.community.groups);
                }
            });
        }
    }
});


//--------------------------------------------------
// Component for form input box
//--------------------------------------------------
Vue.component('community-post-message-box', {
    template: '#community-post-message-box-template',
    props: {

        threadId: {
            type: [String, Number, Boolean],
            required: false
        },

        parentId: {
            type: [String, Number, Boolean],
            required: false
        },
    },
    created: function () {
        McmEventBus.$on('newCommentPosted', this.onNewCommentPosted);
    },
    beforeDestroy: function () {
        McmEventBus.$off('newCommentPosted', this.onNewCommentPosted);
    },
    ready: function (done) {
        this.resetForm();
    },
    data: function () {
        return {
            message: ''
        };
    },


    methods: {
        resetForm: function () {
            this.message = '';
        },

        adjustTextArea: function (e) {
            var o = e.currentTarget;
            o.style.height = "1px";
            o.style.height = (25 + o.scrollHeight) + "px";
        },
        submitPostForm: function (e) {
            var _this = this;
            var $form = $(e.currentTarget);
            var url = $form.prop('action');
            var data = $form.serialize();
            $.post(url, data, (response) => {
                //this.$dispatch('newCommentPosted', this.threadId, this.parentId);
                McmEventBus.$emit('newCommentPosted', this.threadId, this.parentId);
                //console.log(_this.threadId, response);
            });
        },
        onNewCommentPosted: function (threadId) {
            console.log('New comment', threadId);
            this.resetForm();
            return true;
        }
    }
});


Vue.component('community-posts', {
    template: '#community-posts-template',
    props: ['posts'],
    data: function () {
        return {};
    }
});


Vue.component('community-post', {
    template: '#community-post-template',
    props: ['post'],
    data: function () {
        return {
            answeringMode: false,
        };
    },
    created: function () {
        McmEventBus.$on('newCommentPosted', this.onNewCommentPosted);
    },
    beforeDestroy: function () {
        McmEventBus.$off('newCommentPosted', this.onNewCommentPosted);
    },
    computed: {
        /**
         * We only reply to parent comment
         * but in the interface it looks
         * we reply to current child comment
         * But in reality we are passing parent_id
         * if it is a child comment
         * @returns {*}
         */
        replyToId: function () {
            if (this.post.parent_id > 0) {
                return this.post.parent_id;
            }
            return this.post.id;
        }
    },
    methods: {
        replyTo: function () {
            this.$dispatch('replyingTo', this.post.id);
            this.answeringMode = true;
        },
        onNewCommentPosted: function (threadId) {
            console.log('New comment in post', threadId);
            this.answeringMode = false;
            return true;
        }
    }
});


var MCMApp = MCMApp || {};

MCMApp.Pages = MCMApp.Pages || {};

MCMApp.Pages.SpeakersBureauModel = Backbone.Model.extend({
    defaults: {
        searchingNow: false,
        keyupTimer : false
    }
});
MCMApp.Pages.SpeakersBureauView = Backbone.View.extend({

    formId: '#speakers-bureau__search',


    events: {

        "submit #speakers-bureau__search": "searchSubmitted",
        "keyup #inputSearch": "searchKeyupListener"
    },

    initialize: function(options) {

        this.options = options || {};

        // bind to API event
        this.model.on('change:searchingNow', function(model) {
            if (model.get('searchingNow')) {
                $('.speakers-bureau__cards-container').addClass('is-searching-now');
            } else {

                $('.speakers-bureau__cards-container').removeClass('is-searching-now');
            }
        });
    },

    searchKeyupListener : function(e){
        var $el = $(e.currentTarget);
        var _this = this;

        //check if keyup timer running
        var keyupTimer = this.model.get('keyupTimer');

        //if running stop it first
        if(keyupTimer) {
            window.clearTimeout(keyupTimer);
            this.model.set('keyupTimer',false);

        }

        //run it again with nwe query
        this.model.set('keyupTimer',setTimeout(function(){
            _this.search($('#inputSearch').val());

        },500));

    },


    searchSubmitted: function(e) {
        var $el = $(e.currentTarget);
        e.preventDefault();

        if(this.model.get('searchingNow') === true) {
            return;
        }

        var value = $('#inputSearch').val();

        this.search(value);
    },


    search: function(value) {
        var _this = this;
        var ajaxUrl = $(this.formId).attr('action');
        var keyword = $('#inputSearch').val();
        keyword = encodeURIComponent(keyword);

        if(this.model.get('searchingNow') === true) {
            return;
        }

        _this.model.set('searchingNow', true);


        $.post(ajaxUrl + '/' + keyword + '/', {}, function(response) {
            _this.model.set('searchingNow', false);
            $('.speakers-bureau__cards-container').html(response);

        });

    }

});

if ($("#speakers-bureau__search").length) {

    MCMApp.Pages.SpeakersBureau = new MCMApp.Pages.SpeakersBureauView({
        "el": '#speakers-bureau',
        'model': new MCMApp.Pages.SpeakersBureauModel()
    });
}

var MCMApp = MCMApp || {};

MCMApp.Templates = MCMApp.Templates || {};
MCMApp.Templates.Touchpoints = MCMApp.Templates.Touchpoints || {};


MCMApp.Templates.Touchpoints.UserRegistrars = {
    init : function (UserRegistrarModal) {
        return new Vue({
            mixins: [MCMApp.touchpointMixin],
            el: '#user-registration-control',
            data: {
                touchpointId: UserRegistrarModal.touchpointId,
                selectedUsers: UserRegistrarModal.selectedUsers,
                users: UserRegistrarModal.addedUsers,
                newUserRange: 0
            },
            ready: function () {
                McmPlatformMainElementsInit();
            },
            events: {},
            methods: {
                refreshTouchpoint: function () {
                    var _this = this;
                    var data = {
                        action: 'user-registrar-control',
                        touchpoint_id: this.touchpointId,
                        ext: 'json'
                    };

                    $.post(SiteVariables.urls.ajaxModals + "/touchpoint-edit", data, function (response) {
                        console.log('RESPONSE', response);
                        _this.touchpoint = response.touchpoint;
                        _this.users = response.users;
                        _this.selectedUsers = response.selectedUsers;
                        _this.newUserRange = 0;
                        _this.$emit('touchpointUpdated', _this.touchpoint);
                    });
                },
            }
        });
    }
};
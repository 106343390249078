Vue.component('adboard-item-comments', {
    template: '#adboard-item-comments',
    props: ['comments', 'stickyComments'],
    watch: {
        comments: function (val, oldVal) {
            $.material.init();
        }
    }
});

Vue.component('adboard-item-comment', {
    template: '#adboard-item-comment',
    props: ['comment', 'stickyComments'],

    data: function () {
        return {
            replyMode: false,
        };
    },
    ready: function () {
        McmEventBus.$on('replyOpened', this.replyOpened);
    },
    computed: {
        stickyOrder: function () {
            if (this.stickyComments && this.stickyComments.indexOf(this.comment.id) > -1) {
                return this.stickyComments.indexOf(this.comment.id) + 1;
            }
            return false;
        }
    },
    methods: {
        replyOpened: function (commentId) {
            if (commentId == this.comment.id) {
                this.replyMode = true;
                //this.$dispatch('comment-reply-requested', this.comment);
                Vue.nextTick(() => {
                    const $item = $('#comment-to-' + this.comment.id);

                    $item.find('.adboard-comment-message-input').focus();

                    this.scrollToItem($item);

                });
            } else {
                this.replyMode = false;
            }
        },
        scrollToItem: function ($item) {
            if (!$item.length) {
                return;
            }
            let offsetToGo = ($item.offset().top - $item.parent().offset().top);

            $('.adboard-item__comments-list').animate({scrollTop: offsetToGo});
        },
        closeReply: function () {
            this.replyMode = false;

            this.scrollToItem($('#comment-' + this.comment.id));
        },
        reply: function () {
            McmEventBus.$emit('replyOpened', this.comment.id);
        },
        markCommentAsSolved: function () {
            const data = {
                is_solved: (this.comment.is_solved) ? 0 : 1
            };

            $.post(this.comment.edit_url, data, (response) => {
                this.$dispatch('comment-added');
            });
        },

        commentFormSubmit: function (event) {
            const $form = $(event.currentTarget);
            const url = $form.prop('action');
            const data = $form.serialize();

            $('.adboard-comment-message-input').attr('disabled', 'disabled');


            $.post(url, data, (response) => {
                // new comment added event pub.
                McmEventBus.$emit('comment-added');
            });
        },

    }

});
var MCMApp = MCMApp || {};

MCMApp.Forms = MCMApp.Forms || {};

MCMApp.Forms.PurchaseFormViewClass = Backbone.View.extend({

    events: {
        "click .purchase-comment__reply-link": "makeCommentReply",
        "click .remove-uploaded-file": "removeUploadedFile",
        "click .purchase-modal-action": "purchaseModalAction"
    },


    initialize: function () {

    },

    modal: function () {
        return MCMApp.Modals.appModal;
    },

    makeCommentReply: function (e) {
        var _this = this;
        var $el = $(e.currentTarget);
        var $comment = $el.parents('.purchase-comment');

        e.preventDefault();

        //highlight selected comment and unhighlight the rest
        this.commentHighlight($comment).cloneCommentBoxForReply($comment);


    },

    commentHighlight: function ($comment) {
        $('.purchase-comment').removeClass('current-replied-comment');

        $comment.addClass('current-replied-comment');

        return this;
    },

    cloneCommentBoxForReply: function ($comment) {
        //first remove other instances of
        $('.purchase-comments .comment-form-container').remove();

        //clone main form
        var $clone = $('#comment-form-container').clone();
        //remove id
        $clone.removeAttr('id');

        //change parent_id of comment
        var commentId = $comment.data('id');
        $clone.find('.comment-reply-input').val(commentId);

        $clone.find(".purchase-page__comment-textarea-container label").text('Send a reply');

        $clone.insertAfter($comment);

        $clone.find('textarea').focus();

        return this;
    },

    removeUploadedFile: function (e) {
        var _this = this;
        var $el = $(e.currentTarget);
        var $fileRow = $('#uploaded-file-' + $el.data("id"));

        var confirmed = confirm($el.data('text'));

        if (confirmed) {
            $fileRow.addClass("uploaded-file--removed");
            $fileRow.find("input").remove();

            this.messageForUnsavedUploadChanges();
        }
    },

    messageForUnsavedUploadChanges: function () {
        $('.purchase-page__upload-files-message').removeClass("hidden");
    },

    purchaseModalAction: function (e) {
        e.preventDefault();
        var _this = this;
        var $el = $(e.currentTarget);

        var action = $el.data('action');
        var purchaseId = $el.data('id');

        var url = SiteVariables.urls.ajaxModals + "/purchase";

        _this.modal().open();
        $.post(url, {action: action, purchase_id: purchaseId}, function (response) {
            _this.modal().content(response);

        });

    }


});


MCMApp.Forms.PurchaseForm = new MCMApp.Forms.PurchaseFormViewClass({
    "el": '#purchase-page'
});

var MCMApp = MCMApp || {};

MCMApp.Forms = MCMApp.Forms || {};

MCMApp.Forms.AddAreaFormViewClass = Backbone.View.extend({

    events: {
        "change .form-input-file": "imageUploadChange",
        "change #managers-list": "managersListChanged",
        "change .add-area-form__brand-select input[type=radio]": "toggleCustomizedBrands",

    },

    initialize: function() {
        this.managersListChanged(false);
    },

    imageUploadChange: function(e) {
        var $el = $(e.currentTarget);
        this.readURL(e.currentTarget);

    },

    readURL: function(input) {
        var $el = $(input);
        var previewElSelector = $el.data("preview");

        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function(e) {
                $(previewElSelector).attr('src', e.target.result);
            };


            reader.readAsDataURL(input.files[0]);
        }
    },

    toggleCustomizedBrands: function(e) {
        var $el = $(e.currentTarget);

        var dataValue = $el.val();
        if (dataValue === "1") {
            //show it
            $('#customized-branding-section').removeClass("hidden");

            $('html,body').delay(100).animate({
                scrollTop: $('#customized-branding-section').offset().top
            }, 500);
        } else {
            $('#customized-branding-section').addClass("hidden");

        }
    },


    managersListChanged: function(e) {
        var $el = $(e.currentTarget);

        var selectedManagers = $("#managers-list").val();
        var $radioContainer = $("#owner-selection-list");

        //is there any previously selected
        var checkedValue = $radioContainer.find(".owner-selection-list__input input:checked").val();


        //if no items selected hide all radio and deselect the selected
        if (selectedManagers === null) {
            //hide them all
            $radioContainer.find(".owner-selection-list__input").each(function() {
                $(this).addClass("hidden");
                $(this).find("input").removeAttr("checked");
            });

            $('#owner-selection-list-helper').removeClass('hidden');

        } else {

            $('#owner-selection-list-helper').addClass('hidden');
            //hide all but the ones in the list
            $radioContainer.find(".owner-selection-list__input").each(function() {

                $(this).addClass("hidden");
                $(this).find("input").removeAttr("checked");
            });


            _.each(selectedManagers, function(userId, key) {

                $("#owner_id-" + userId).removeClass("hidden");

                if (checkedValue === userId) {

                    $("#owner_id-" + userId).find("input").prop("checked", "checked");

                }

            });

        }
        // else show the selected items




    }


});

if ($("#add-area-form-container").length) {

    MCMApp.Forms.AddAreaForm = new MCMApp.Forms.AddAreaFormViewClass({
        "el": '#add-area-form-container'
    });
}

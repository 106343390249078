var MCMApp = MCMApp || {};

MCMApp.Forms = MCMApp.Forms || {};

MCMApp.Forms.AddTouchpointFormViewClass = Backbone.View.extend({

    events: {
        "change .form-input-file": "imageUploadChange",
        "click #add-more-custom-speaker": "addCustomSpeakerInput",
        "click #add-more-promotional-service": "addPromotionalServiceInput",
		"keyup .touchpoint-link-input" : "updateLinkInput",
		"change .video-url-input" : "getVideoPreview",
		//"paste .video-url-input" : "getVideoPreview",
        "change .add-area-form__brand-select input[type=radio]": "toggleCustomizedBrands",


    },

    initialize: function() {

    },

    addCustomSpeakerInput : function(){
        var $parentEl = $('#custom-speaker-inputs');
        var $template = $('#custom-speaker-template');
        var $clone = $template.clone();
        //reset value and append
        $clone.find('input').val('');
        $clone.appendTo($parentEl);
    },

    addPromotionalServiceInput : function(){
        var $parentEl = $('#promotional-service-inputs');
        var $template = $('#promotional-service-template');
        var $clone = $template.clone();
        //reset value and append
        $clone.removeAttr('id');
        $clone.find('input').val('');
        $clone.appendTo($parentEl);
    },

    updateLinkInput : function(e){
		var $el = $(e.currentTarget);
        $("#url-preview").text($el.val());
	},

    imageUploadChange: function(e) {
        var $el = $(e.currentTarget);
        this.readURL(e.currentTarget);

    },

    readURL: function(input) {
        var $el = $(input);
        var previewElSelector = $el.data("preview");

        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function(e) {
                $(previewElSelector).attr('src', e.target.result);
            };


            reader.readAsDataURL(input.files[0]);
        }
    },

    getVideoPreview : function(e){
        var _this =this;
		var $el = $(e.currentTarget);

        var data = {
            url : $el.val()
        };

        $('#tp-video-preview').html('<i class="fa fa-spinner fa-spin"></i>');
        //query embed code and title
        $.post(SiteVariables.urls.getUrlEmbed,data,function(response){

            _this.updateVideoPreview(response);
        });
	},

    updateVideoPreview : function(response){
        console.log(response);
        if(response.code) {

            $("#tp-video-preview").html(response.code);
            $("#video-name-input").val(response.name);
            $("#video-description-input").val(response.description);
            $(".video-name-input-container").removeClass("hidden");
        } else {

            $("#tp-video-preview").html('');
            alert('Embedding of this video is not possible.');

        }
    },

    toggleCustomizedBrands: function(e) {
        var $el = $(e.currentTarget);

        var dataValue = $el.val();
        if (dataValue === "1") {
            //show it
            $('#customized-branding-section').removeClass("hidden");

            $('html,body').delay(100).animate({
                scrollTop: $('#customized-branding-section').offset().top
            }, 500);
        } else {
            $('#customized-branding-section').addClass("hidden");

        }
    },

});


    MCMApp.Forms.AddTouchpointForm = new MCMApp.Forms.AddTouchpointFormViewClass({
        "el": '#add-touchpoint-form-container'
    });

Vue.component('modal-registration-field-translation', {
    template: '#modal-registration-field-translation',
    props: {
        setFieldTranslationsUrl: {
            type: String,
            required: true,
        },
        countries: {
            type: Array,
        },
        field: {
            type: Object,
        },
    },
    data() {
        return {
            translations: {},
            loading: false,
            error: false,
        };
    },
    watch: {
        field() {
            this.setCurrentTranslations();
        },
    },
    created() {
        this.setCurrentTranslations();
    },
    methods: {
        save() {
            this.loading = true;
            this.error = false;

            const payload = Object.assign({ field_id: this.field.id }, this.translations);
            $.post(this.setFieldTranslationsUrl, payload)
                .done(() => { this.$emit('close'); })
                .fail(() => {
                    this.error = true;

                    // Scroll to top of modal content
                    document.getElementById('registration-field-translation-content').scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth',
                    });
                })
                .always(() => { this.loading = false; });
        },
        setCurrentTranslations() {
            this.translations = this.field.registration_field_translations.reduce((translations, translation) => {
                translations[translation.country_id] = translation.label;
                return translations;
            }, {});
        },
    },
});

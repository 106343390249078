// Vue component hcp-listing-table
//-------------------------------------------------------------

Vue.component('hcp-listing-table', {
    template: '#hcp-listing-table',
    props: ['project'],
    ready: function (done) {
        this.update();
    },
    data: function () {
        return {
            projectsListings: false,
            loading: true
        };
    },
    watch: {
        projectsListings: function (val, oldVal) {
            McmPlatformMainElementsInit();
        }
    },
    methods: {
        update: function () {
            var _this = this;
            var url = SiteVariables.urls.projectsListingsController + '/index/' + this.project.id;
            this.loading = true;
            $.get(url, {}, function (response) {
                //console.log(response);
                _this.loading = false;
                if (response.projectsListings) {
                    _this.$set('projectsListings', response.projectsListings);
                }
            });
        },
        updateListOptin: function (listing) {
            //console.log('changed',listing);
            //send it to
        }
    }
});
// Vue touchpoint mixin
// -- Edit template package cards
//-------------------------------------------------------------

var MCMApp = MCMApp || {};

MCMApp.hasTemplatePackageEditCards = {
    uncheckIfSelected: function (event) {
        event.preventDefault();
        event.stopPropagation();
        var $el = $(event.currentTarget);
        var $input = $('#' + $el.attr('for'));
        $(".template-package-label").removeClass('template-package-label--selected');
        if ($input.is(':checked')) {
            $input.prop('checked', false);
            $('#tp-detail-form').prepend('<input type="hidden" name="template_package_id" value="" id="hidden-template-id">');
        } else {
            $input.prop('checked', true);
            $el.addClass('template-package-label--selected');
            $('#hidden-template-id').remove();
        }
    }
};
Vue.component('video-platform-all-videos-modal', {
    template: '#video-platform-all-videos-modal-template',

    props: {
        actionUrls: {
            type: Object,
            required: true
        },
        global: Boolean,
        label: String,
    },

    data() {
        return {
            newVideos: false,
            modalOpened: false,
            loadingNewVideos: true,
            pagination: false,
            videoImport: [],
            videosCount: 0,
            disableImportButton: false
        };
    },

    ready() {
        this.fetchVideosCount();
        $('[data-toggle="tooltip"]').tooltip();
    },

    methods: {
        fetchVideosCount() {
            $.get(this.actionUrls.getVideosCount, {}, (response) => {
                if (response.success === true) {
                    this.videosCount = response.data;
                }
            });
        },
        openNewVideos() {
            this.modalOpened = true;
            document.getElementsByTagName('body')[0].classList.add("modal-open");
            $.get(this.actionUrls.getAll, {}, (response) => {
                if (response.success === true) {
                    this.setNewVideos(response.data);
                    this.loadingNewVideos = false;
                    // console.log('Response success');
                }
            });
        },
        closeNewVideos() {
            document.getElementsByTagName('body')[0].classList.remove("modal-open");
            this.newVideos = false;
            this.modalOpened = false;
            this.loadingNewVideos = true;
        },
        isImported(video) {
            return (!$.isEmptyObject(video.child_video_platform_videos) || video.importedToGlobalLibrary);
        },
        importVideo(video, index, areaID = null) {
            if (!this.disableImportButton) {
                this.videoImport = [];
                this.videoImport[index] = true;
                const data = (this.global) ? {video: video} : {video_id: video.id, area: areaID};

                //  this.videoImport[video.id] = true;
                $.ajax({
                    url: this.actionUrls.import,
                    type: "POST",
                    data: data,
                    success: (response) => {
                        window.location.href = response.data.url;
                    }
                });
            }
            this.disableImportButton = true;

        },
        changePage(page) {
            this.loadingNewVideos = true;
            $.get(this.actionUrls.getAll + "?page=" + page, {}, (response) => {
                if (response.success === true) {
                    this.setNewVideos(response.data);
                    this.modalOpened = true;
                    this.loadingNewVideos = false;
                    $('.modal-body').scrollTop(0);
                }
            });
        },
        setNewVideos(data) {
            this.newVideos = data.videos;
            this.pagination = data.pagination;
            this.loading = false;
        },
        uploadedAt(date) {
            return moment(date).format('YYYY-MM-DD');
        },
        viewVideo(id, title, type) {
            MCMApp.VideoPlatformModalActions.viewVideoModal(id, title, type);
        },
    },

    created() {
        let $modal = $('#app-modal');
        let _this = this;
        $modal.on('hidden.bs.modal', function (e) {
            if ($modal.hasClass("delete-success")) {
                _this.fetchVideosCount();
            }
        });
    }
});

Vue.component('add-new-list-page', {

    data() {
        return {
            listName: '',
        };
    },
    ready(){
        McmPlatformMainElementsInit();
    },
    methods: {
        fileSelected(e) {
            const filename = e.target.value.replace(/.*[\/\\]/, '');

            if(this.listName === '') {
                this.listName = filename.substring(0 ,filename.lastIndexOf('.')) || filename;
            }
        },
    }
});
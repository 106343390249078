var PollQuestionCountdownTimer;

Vue.component('ondemand-poll-question', {
    template: '#ondemand-poll-question-template',
    props: {
        question: {
            type: Object,
            required: true
        },
        player: {
            type: Object,
            required: true
        },
        url: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            currentVideoTimeInSeconds: 0,
            currentAnswer: false,
            /**
             * Seconds to countdown for question
             */
            countdownSeconds: 30,
            countdown: false,
        };
    },

    computed: {
        /**
         * mm:ss or 12:24 format for countdown timer
         * to display in frontend
         *
         * @return string
         */
        countdownSecondsDisplayed() {
            const dateObj = new Date(null);
            dateObj.setSeconds(this.countdownSeconds);
            return moment(dateObj).format('mm:ss');
        },

        /**
         * Css class object to use with countdown timer
         * showing warning color for 20-10 sec and
         * danger class < 10
         * By default is shows success
         *
         * @return object
         */
        countdownLabelClass() {
            return {
                'label-success': (this.countdownSeconds > 20),
                'label-warning': (this.countdownSeconds <= 20 && this.countdownSeconds > 10),
                'label-danger': this.countdownSeconds <= 10,
            };
        }
    },

    ready() {
        McmPlatformMainElementsInit();

        this.startCountdown();
    },
    destroy() {
        this.stopCountdown();
    },

    watch: {
        countdownSeconds(val, oldVal) {
            if (val === 0) {
                this.stopCountdown();
                McmEventBus.$emit('ondemandPollQuestionSkipped', this.question);
            }
        }
    },

    methods: {


        /**
         * Save currently displayed answer and continue
         * After saving put the question id to list of displayed
         */
        saveAnswerAndContinue() {
            const data = {
                poll_id: this.question.id,
                answer_id: this.currentAnswer,
            };


            $.ajax({
                url: this.url,
                type: 'post',
                data: data,
                dataType: 'json',
                success: (response) => {
                    if (response.status) {

                        console.log('success', response);
                        //trigger the event for successful
                        this.successfullyReplied();
                    } else {
                        alert(response.status);
                    }
                },
                error: (response) => console.error(response),
            });

        },

        /**
         * Triggers the event after successful reply
         */
        successfullyReplied() {
            this.stopCountdown();
            McmEventBus.$emit('ondemandPollQuestionReplied', this.question, this.player);

        },

        startCountdown() {
            if (PollQuestionCountdownTimer) {
                clearInterval(PollQuestionCountdownTimer);
            }
            PollQuestionCountdownTimer = setInterval(() => {

                console.log(this.countdownSeconds);
                console.log(PollQuestionCountdownTimer);
                if (this.countdownSeconds > 0) {
                    this.countdownSeconds--;
                }
            }, 1000);
        },

        stopCountdown() {
            clearInterval(PollQuestionCountdownTimer);
        }
    }


});
var MCMApp = MCMApp || {};

MCMApp.Templates = MCMApp.Templates || {};
MCMApp.Templates.Touchpoints = MCMApp.Templates.Touchpoints || {};


MCMApp.Templates.Touchpoints.EasyFlow = {
    init: function (touchpoint, touchpointId, startSliderKey) {
        return new Vue({
            el: '#touchpoint-easy-flow-container',
            mixins: [
                MCMApp.Templates.Touchpoints.EasyFlow.dateMixin,
                MCMApp.touchpointProjectUsersMixin
            ],
            data: {
                displayed: {
                    title_and_type: false,
                    broadcast_settings: false,
                    date: false,
                    template: false,
                    goals: false,
                    speakers: false,
                    final: false,
                    stakeholders: false,
                    services: false,
                    services_compare: false,
                },

                flowSteps: [
                    'title_and_type',
                    'broadcast_settings',
                    // 'stakeholders',
                    'date',
                    //'goals',
                    // 'speakers',
                    'template',
                    // 'services',
                    // 'services_compare',
                    'final',
                ],

                currentStep: false,

                nextStep: false,

                prevStep: false,

                broadcast_setting_id: false,

                template_package_id: false,

                service_package_id: false,

                broadcastServices: false,

                updateInProgress: false,

                touchpoint: touchpoint,

                touchpointId: touchpointId,

                broadcastSlider: false,

            },

            ready: function () {
                //set broadcast_settings as first step
                this.setSteps('broadcast_settings');

                if (this.touchpoint.broadcast_setting_id > 0) {
                    this.broadcast_setting_id = this.touchpoint.broadcast_setting_id;
                }
                if (this.touchpoint.template_package_id > 0) {
                    this.template_package_id = this.touchpoint.template_package_id;
                }
                if (this.touchpoint.service_package_id > 0) {
                    this.service_package_id = this.touchpoint.service_package_id;
                }
                McmPlatformMainElementsInit();
                setTimeout(function () {
                    McmPlatformMainElementsInit();
                }, 1000);
            },


            watch: {
                'displayed.date': function (val, oldVal) {
                    McmPlatformMainElementsInit();
                },
                'displayed.stakeholders': function (val, oldVal) {
                    McmPlatformMainElementsInit();

                    //sometimes it is not updated on open needs a few miliseconds
                    setTimeout(()=>McmPlatformMainElementsInit(),300);

                },
                'displayed.services': function (val, oldVal) {
                    if (val === true) {
                        $('.modal-body,.modal-footer').addClass('modal-body--grey');
                        this.getBroadcastSettingsServices();
                    } else {

                        $('.modal-body,.modal-footer').removeClass('modal-body--grey');
                    }
                },
                'displayed.speakers': function (val, oldVal) {
                    McmPlatformMainElementsInit();
                    setTimeout(()=>McmPlatformMainElementsInit(),300);
                },

                'displayed.services_compare': function (val, oldVal) {

                    this.updateCompareList();

                },
                'displayed.broadcast_settings': function (val, oldVal) {

                    console.log('start key', startSliderKey, val);
                    if (val === true) {
                        this.broadcastSlider = $('.broadcast-settings-slider').bxSlider({
                            captions: false,
                            pager: false,
                            startSlide: 0
                        });

                        McmPlatformMainElementsInit();
                        /*
                        setInterval(function () {

                            McmPlatformMainElementsInit();
                            if (!this.broadcastSlider) {

                                this.broadcastSlider = $('.broadcast-settings-slider').bxSlider({
                                    captions: false,
                                    pager: false,
                                    startSlide: 0
                                });
                            }
                        }, 3000);
                        */
                    } else {
                        this.broadcastSlider.destroySlider();
                        this.broadcastSlider = false;
                    }
                },

                template_package_id: function (val, oldVal) {
                    if (val) {
                        $('#template-package-' + this.template_package_id).attr('checked', 'checked')
                            .attr('selected', 'selected');
                    }
                }

            },
            compiled: function () {
                McmPlatformMainElementsInit();
            },
            computed: {
                speakerIds: function () {
                    if (this.touchpoint.speakers) {
                        return _.pluck(this.touchpoint.speakers, 'id').join();
                    }
                    return null;
                },
                projectUserId : function(){
                    if (this.touchpoint.project.user_id) {
                        return this.touchpoint.project.user_id;
                    }
                    return null;
                }
            },
            methods: {
                toggleDisplayed: function (value) {
                    const key = $(event.target).data('item');
                    this.displayed[key] = (this.displayed[key] === false);
                },

                getBroadcastSettingsServices: function () {
                    const url = SiteVariables.urls.serviceTypesController + '/index/' + this.broadcast_setting_id;
                    $.get(url, {}, (response) => {
                        this.broadcastServices = JSON.parse(response);
                    });
                },

                setSteps: function (currentStep) {
                    var stepName = currentStep;
                    this.displayed[stepName] = true;
                    this.currentStep = stepName;

                    var currentIndex = this.flowSteps.indexOf(this.currentStep);

                    if (this.flowSteps[currentIndex + 1]) {
                        this.nextStep = this.flowSteps[currentIndex + 1];
                    } else {
                        this.nextStep = false;
                    }

                    if (this.flowSteps[currentIndex - 1]) {
                        this.prevStep = this.flowSteps[currentIndex - 1];
                    } else {
                        this.prevStep = false;
                    }
                    console.log(this.prevStep);
                },

                closeAllDisplayed: function () {
                    //TODO remove from here just trying


                    for (let key in this.displayed) {
                        this.displayed[key] = false;
                    }
                },

                refreshTouchpoint: function () {
                    this.$emit('touchpointUpdating');

                    const url = SiteVariables.urls.touchpointsController + '/view/' + this.touchpointId;
                    $.get(url, {}, (response) => {
                        this.$set('touchpoint ', response.touchpoint);
                        this.$emit('touchpointUpdated');
                    });
                },

                destroySlider: function () {
                    this.broadcastSlider.destroySlider();
                },

                openPrevStep: function () {
                    if (this.prevStep) {
                        this.closeAllDisplayed();
                        this.setSteps(this.prevStep);
                    }
                },

                openNextStep: function () {
                    if (this.nextStep) {
                        this.setSteps(this.nextStep);
                    }
                },

                updateCompareList: function () {
                    const url = SiteVariables.urls.servicePackagesController + '/compare/' + this.service_package_id;

                    $.get(url, {}, function (response) {
                        $('#services_compare_content').html(response);
                    });
                },

                makeCurrentBroadcast: function (event) {
                    this.broadcast_setting_id = $(event.target).data('id');
                },

                makeCurrentTemplatePackage: function (event) {
                    $(event.target).find('.template-package-value').change();
                },

                makeCurrentServicePackage: function (event) {
                    const bcId = $(event.target).data('id');
                    this.service_package_id = bcId;
                },

                resetServicePackage: function () {
                    this.service_package_id = false;
                },

                selectionBoxChange: function (e) {
                    const $el = $(e.currentTarget);

                    //unselect others
                    const $boxesEl = $el.parent();
                    $boxesEl.find('.selection-box').each(function () {

                        $(this).find('input').prop('checked', false);

                        $(this).removeClass('selection-box--selected');
                    });
                    //make this select this one's radio selected
                    $el.find('input').prop('checked', true);
                    $el.addClass('selection-box--selected');
                },

                onSubmit: function (event) {

                    let direction = $(event.currentTarget).data('direction');
                    if (!direction) {
                        direction = 'next';
                    }
                    const $form = $('#tp-detail-form');
                    const url = $form.prop('action');
                    const data = $form.serialize();

                    this.updateInProgress = true;
                    $.post(url, data, (response) => {

                        this.touchpoint = response.touchpoint;
                        this.closeAllDisplayed();
                        if (direction == 'prev') {
                            this.openPrevStep();
                        } else {
                            this.openNextStep();
                        }

                        this.updateInProgress = false;
                    });

                },

                addCustomSpeakerInput: function () {
                    const $parentEl = $('#custom-speaker-inputs');
                    const $template = $('#custom-speaker-template');
                    const $clone = $template.clone();
                    //reset value and append
                    $clone.find('input').val('');
                    $clone.appendTo($parentEl);
                },

                onSubmitProject: function (event) {
                    let direction = $(event.currentTarget).data('direction');
                    if (!direction) {
                        direction = 'next';
                    }

                    const inputs = [
                        '#user-id',
                        '#involved-users-ids',
                        '#informed-users-ids'
                    ];
                    this.updateInProgress = true;
                    let data = {};

                    const url = $(event.target).data('url');

                    console.log(url);

                    _.each(inputs, function (el, key) {
                        data[$(el).attr('name')] = $(el).val();
                    });

                    $.post(url, data, (response) => {
                        //this.touchpoint = JSON.parse(response);
                        this.refreshTouchpoint();
                        this.closeAllDisplayed();
                        if (direction == 'prev') {
                            this.openPrevStep();
                        } else {
                            this.openNextStep();
                        }
                        this.updateInProgress = false;
                    });

                },

            }
        });
    },
    /**
     * Includes date related data and computed props, methods
     * created and destroyed events
     */
    dateMixin: {
        created: function () {
            McmEventBus.$on('dateChangedOnDatepicker', this.datePickerListener);
        },
        destroyed: function () {
            McmEventBus.$off('dateChangedOnDatepicker', this.datePickerListener);
        },
        data: {
            dateSettings: {
                start_date: false,
                end_date: false,
                start_time: false,
                end_time: false,
            }
        },
        computed: {
            datesCorrectlySet: function () {
                //dates should be set and end date should be same of after start date
                let datesSet = true;

                //set it to false even if one of the keys are empty
                _.each(this.dateSettings, (el, key) => {
                    if (!this.dateSettings[key]) {
                        datesSet = false;
                    }
                });

                if(this.dateSettings.start_date > this.dateSettings.end_date) {
                    datesSet = false;
                } else if(this.dateSettings.start_date === this.dateSettings.end_date) {
                    if(this.dateSettings.start_time > this.dateSettings.end_time) {
                        datesSet = false;
                    }
                }

                return datesSet;
            },

            startDateFormatted: function () {
                return ((this.touchpoint.date) ? moment(this.touchpoint.date).format('DD-MM-Y') : '');
            },
            endDateFormatted: function () {
                return ((this.touchpoint.end_date) ? moment(this.touchpoint.end_date).format('DD-MM-Y') : '');
            },
            startTimeFormatted: function () {

                return ((this.touchpoint.time) ? moment(this.touchpoint.time).format('HH:mm') : '');
            },
            endTimeFormatted: function () {
                return ((this.touchpoint.end_time) ? moment(this.touchpoint.end_time).format('HH:mm') : '');
            }
        },

        methods: {
            /**
             * Assign input values on vue instance dateSettings keys
             * to use in interface for validation, comparison, computed props etc.
             * @param $input
             */
            datePickerListener: function ($input) {
                // console.log('changed', $input, $input.attr('name'));

                const inputName = $input.attr('name');
                const val = $input.val();

                switch (inputName) {
                    case 'date':
                        this.dateSettings.start_date = val;
                        let minimumDate = new Date(val);
                        $('#end_date').datepicker('option', 'minDate', val); 
                        break;
                    case 'end_date':
                        this.dateSettings.end_date = val;
                        break;
                    case 'eventstarttime':
                        this.dateSettings.start_time = val;

                        break;
                    case 'eventendtime':
                        this.dateSettings.end_time = val;
                        break;
                    default:
                        break;
                }

            }
        }
    }
};
Vue.component('adboard-group-notification-button', {
    template: '#adboard-group-notification-button-template',
    props: {
        adboardGroup: {
            type: [Object, Boolean],
            required: false,
            default: false
        },
        allBoards: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            notifications: [],
            timer: false,
            isOpen: false
        };
    },
    created() {
        if (this.allBoards === true) {
            this.initAllBoards();
        }
    },

    ready() {

    },
    watch: {
        adboardGroup(val, oldVal) {
            if (oldVal === false) {
                this.init();
            }
        },
        isOpen(val, oldVal) {
            if (val === true) {
                document.addEventListener("click", this.popupListener);
                Vue.nextTick(() => {

                    $('.mcm-popover-card__nav').mCustomScrollbar({
                        theme: "dark"
                    });
                });
            } else {
                document.removeEventListener("click", this.popupListener);
            }
        }
    },
    methods: {
        initAllBoards() {
            if (this.allBoards === false) {
                return;
            }
            this.getAllBoardNotifications();

            //query comments every 10 sec
            this.timer = setInterval(this.getAllBoardNotifications, 10000);
        },
        init() {
            if (this.allBoards === true) {
                return;
            }
            this.getNotifications();

            //query comments every 10 sec
            this.timer = setInterval(this.getNotifications, 10000);
        },
        getNotifications() {
            const data = {
                action_name: 'board',
                adboard_group_id: this.adboardGroup.id,
                is_seen: 0
            };

            $.post(SiteVariables.urls.adboardUserNotifications, data, (response) => {
                this.notifications = response.notifications;
            });
        },
        getAllBoardNotifications() {
            const data = {
                action_name: 'boards',
                is_seen: 0
            };

            $.post(SiteVariables.urls.adboardUserNotifications, data, (response) => {
                this.notifications = response.notifications;
            });
        },
        toggle() {
            this.isOpen = !this.isOpen;
        },

        /**
         * Listen clicks and checks if the click is made outside of popover
         * @param event
         */
        popupListener(event) {
            const $el = $(event.target);

            const $parent = $(this.$el);

            if ($el.closest($parent).length === 0) {
                this.toggle();
            }

        }
    }
});
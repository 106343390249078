// Accreditation questions page
//-------------------------------------------------------------

/**
 * Accreditation questions component
 */
Vue.component('accreditation-questions', {
    template: '#accreditation-questions-template',
    props: {
        accreditationData: {
            type: Object
        },
        type: {
            type: String
        },
        touchpointId: {
            type: Number
        }
    },
    data() {
        return {
            loading: false,
            response: {
                data: {},
                msg: null,
                alertClass: null,
                trigger: false
            },
            responseQuestionAdd: {},
            removeQuestionTrigger: false,
            addQuestionTrigger: false,
            notSavedAnswer: []
        };
    },
    computed: {
        questions(){
            return this.accreditationData.accreditation;
        }
    },
    watch: {

        /**
         * Watch changes in all questions and trigger events accordingly
         *
         * @param val
         * @param oldVal
         */
        questions: function (val, oldVal) {
            if (val.length === oldVal.length) {
                return;
            }

            this.loading = false;

            if (this.removeQuestionTrigger) {
                this.$emit('removeQuestionEventTrigger', this.response);
            } else if (this.addQuestionTrigger) {
                this.$emit('addQuestionEventTrigger', this.responseQuestionAdd);
            }

        }
    },
    created() {
        //Register sort event handler
        McmEventBus.$on('accreditationQuestionsSorted', this.updateQuestionSort);

        //Remove question event handler
        McmEventBus.$on('accreditationQuestionRemoveRequest', this.removeQuestion);
    },
    ready(){
        //initialize sortable question list on start
        this.initSortable();
    },
    destroyed(){
        //Remove question event handler before destroying
        McmEventBus.$off('accreditationQuestionsSorted', this.updateQuestionSort);
        McmEventBus.$off('accreditationQuestionRemoveRequest', this.removeQuestion);
    },
    events: {

        /**
         * Add question
         *
         * @param response
         */
        addQuestionEventTrigger: function (response) {
            if (!response.error) {
                this.response.alertClass = 'alert-success';
            } else {
                this.response.alertClass = 'alert-danger';

                if (response.notSavedAnswer.length > 0) {
                    this.notSavedAnswer = response.notSavedAnswer;
                }
            }

            this.response.msg = response.response;
            this.addQuestionTrigger = false;
            this.response.trigger = true;
        },


        /**
         * Remove question
         *
         * @param response
         */
        removeQuestionEventTrigger: function (response) {
            if (response.data.response) {
                this.response.alertClass = 'alert-success';
                this.response.msg = response.data.successMsg;
            } else {
                this.response.alertClass = 'alert-danger';
                this.response.msg = response.data.errorMsg;
            }

            this.removeQuestionTrigger = false;
            this.response.trigger = true;
        }
    },
    methods: {

        /**
         * Remove question method
         *
         * @param questionId
         */
        removeQuestion: function (questionId) {
            if (questionId > 0) {
                const url = SiteVariables.urls.accreditationQuestions + '/' + questionId;

                this.loading = true;
                this.removeQuestionTrigger = true;

                $.ajax({
                    url: url,
                    type: 'delete',
                    success: (data) => {
                        this.response.data = data;
                        McmEventBus.$emit('updateTouchpointAccreditationOnEditPage');
                        this.loading = false;
                    },
                    error: () => {
                        this.loading = false;
                    }
                });
            }
        },


        /**
         * Sort questions
         *
         * @param sortedIds
         */
        updateQuestionSort(sortedIds){
            const data = {
                    sorted_ids: sortedIds,
                    touchpointId: this.touchpointId
                },
                url = SiteVariables.urls.touchpointAccreditation + '/sort';

            $.post(url, data, () => {
                McmEventBus.$emit('updateTouchpointAccreditationOnEditPage');
            });
        },

        /**
         * Hide alert box message
         */
        hideAlert: function () {
            this.response.alertClass = 'hide';
        },

        /**
         * Initialize sortable list of questions
         */
        initSortable(){
            const $accreditationContainer = $('.accreditation-questions-container');

            $accreditationContainer.sortable({
                update: (event, ui) => {
                    const sortedIds = $accreditationContainer.sortable('toArray', {
                        attribute: 'data-question'
                    });

                    McmEventBus.$emit('accreditationQuestionsSorted', sortedIds);
                }
            });
            $('.has-tooltip').tooltip();
        }
    }
});


/**
 * Accreidtation question entity component
 */
Vue.component('accreditation-question-entity', {
    template: '#accreditation-question-entity-template',
    props: {
        question: {
            type: Object,
            required: true
        },
        viewOnly: {
            type: Boolean,
            required: false
        }
    },
    methods: {

        /**
         * Remove question method
         *
         * @param confirmMsg
         */
        removeQuestion: function (confirmMsg) {
            const concur = confirm(confirmMsg);

            if (concur) {
                McmEventBus.$emit('accreditationQuestionRemoveRequest', this.question.id);
            }
        },


        /**
         * Load question the relevant question
         *
         * @returns {*}
         */
        loadQuestion: function () {
            return MCMApp.AccreditationModalActions.loadQuestion(this.question.id, this.question.touchpoint_id);
        },


        /**
         * Edit question the relevant question
         *
         * @returns {*}
         */
        editQuestion: function () {
            return MCMApp.AccreditationModalActions.editQuestion(this.question.id, this.question.touchpoint_id);
        }
    }
});

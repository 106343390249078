var MCMApp = MCMApp || {};

MCMApp.Pages = MCMApp.Pages || {};

MCMApp.Pages.ListingsUploadModelClass = Backbone.Model.extend({

    defaults: {

    },

    initialize: function() {

    },

});

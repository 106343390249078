Vue.component('touchpoint-reports', {
    template: '#touchpoint-reports-template',
    props: ['touchpoint', 'reportResult'],
    ready: function (done) {
        this.getData();
    },
    data: function () {
        return {
            loading: true
        };
    },
    methods: {
        getData: function () {
            var self = this;

            // @todo change the url
            var url = 'http://demo.multichannelmanager.com/reports/generic/events-json/registration_confirmation';
            var method = 'GET';
            var data = {};

            $.ajax({
                url: url,
                type: method,
                data: data,
                dataType: 'json',
                success: (data) => {
                    console.log(data.data);
                    self.reportResult = data.data;
                    self.runCharts();
                    self.loading = false;
                },
                error: (data) => {
                    console.error(data);
                    // this.handleError('connectionError', true);
                }
            });

        },

        runCharts: function () {
            this.barChart($(".touchpoint-reports-chart__types"));
            this.lineChart($(".touchpoint-reports-chart__clicks"));
            this.doughnutChart($(".touchpoint-reports-chart__opens"));
        },

        barChart: function (ctx) {
            var self = this;
            var report = self.reportResult;
            var myChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: ["Total Clicks", "Total Mails", "Total Opens", "Unique Clicks", "Unique Opens"],
                    datasets: [{
                        label: '',
                        data: [
                            report.totalClicks,
                            report.totalMails,
                            report.totalOpens,
                            report.uniqueClicks,
                            report.uniqueOpens
                        ],
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255,99,132,1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)'
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero:true
                            }
                        }]
                    }
                }
            });
        },

        lineChart: function (ctx) {
            var myChart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
                    datasets: [{
                        label: '# of Votes',
                        data: [12, 19, 3, 5, 2, 3],
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255,99,132,1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero:true
                            }
                        }]
                    }
                }
            });
        },

        doughnutChart: function (ctx) {
            var myChart = new Chart(ctx, {
                type: 'doughnut',
                data: {
                    datasets: [{
                        data: [10, 20, 30]
                    }],

                    // These labels appear in the legend and in the tooltips when hovering different arcs
                    labels: [
                        'Red',
                        'Yellow',
                        'Blue'
                    ]
                }
            });
        },
    }
});
Vue.component('broadcast-setting-packages-table', {
    template: '#broadcast-setting-packages-table-template',
    props: ['broadcastSettingId'],
    data() {
        return {
            packagesData: false
        };
    },
    ready() {
        this.getBroadcastSettingServices();
    },
    watch: {
        broadcastSettingId(val) {
            if (val) {
                this.getBroadcastSettingServices();
            } else {
                this.packagesData = false;
            }
        }
    },
    methods: {
        /**
         * Gets data we need to build support service selection interface
         */
        getBroadcastSettingServices() {
            const url = '/admin/broadcast-settings/get-list/' + this.broadcastSettingId;
            $.get(url, {}, (response) => {
                if (response.success) {
                    this.packagesData = response.data;
                }
            });
        },
    }
});
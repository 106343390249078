var MCMApp = MCMApp || {};


MCMApp.ValidationRules = {} || MCMApp.ValidationRules;


MCMApp.ValidationRules.clientFormsValidation = {

    rules : [ {name: 'email',rules: 'required|valid_email'},{name: 'password',rules: 'required'}],

    init : function(){

    }

};

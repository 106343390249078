// Vue component - Speakers index sub page
//-------------------------------------------------------------

/**
 * Speakers component parent wrapper
 */
Vue.component('speakers-wrapper', {
    props: {
        url: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: false,
            default: 'approved'
        }
    },
    methods: {
        updateList: function (disableLoader) {
            this.loading = !disableLoader;
            $.get(this.url, null, (response) => {
                if (response.speakers !== undefined && response.speakers.length > 0) {
                    this.speakers = response.speakers;
                    this.$broadcast('controlsData', response);
                } else {
                    this.speakers = false;
                }
                this.loading = false;
            });
        },
        checkIfIE: function () {
            let ua = window.navigator.userAgent;
            let msie = ua.indexOf("MSIE ");

            return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
        }
    },
    events: {
        searchQueryUpdate: function (val) {
            this.$broadcast('searchQueryUpdate', val);
        }
    },
    data: function () {
        return {
            speakers: [],
            loading: false
        };
    },
    ready: function () {
        this.updateList();
        this.$broadcast('pageType', this.type);
    },
    watch: {
        loading: function (val) {
            if (val === false) {
                if (!this.checkIfIE()) {
                    McmPlatformMainElementsInit();
                }
                this.$broadcast('updateOptsClass', 'hide');
            }

        }
    }
});

/**
 * Render the speakers table template
 */
Vue.component('speakers-table', {
    template: '#speakers-table-template',
    props: {
        speakers: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    events: {
        rowChecked: function (checked, speakerId) {
            if (checked) {
                this.speakersList.push(speakerId);
            } else {
                let index = this.speakersList.indexOf(speakerId);
                if (index > -1) {
                    this.speakersList.splice(index, 1);
                }
            }
        },
        pageType: function (type) {
            this.type = type;
        },
        searchQueryUpdate: function (val) {
            if (typeof val == 'boolean') {
                this.globalCheck = val ? val : '';
            } else {
                this.searchText = val;
            }
        },
        updateOptsClass: function (val) {
            this.showOptClass = val;
        }
    },
    methods: {
        handleSpeakers: function (url) {
            let speakerslist = this.speakersList;
            $.ajax({
                url: url,
                type: 'post',
                data: {speakers: speakerslist},
                success: (data) => {
                    if (data.response) {
                        this.$parent.updateList();
                        this.speakersList = [];
                    }
                }
            });
        },
        sortvia: function (sortparam, order) {
            this.order = this.order * -1;
            this.sortparam = sortparam;
        }
    },
    computed: {
        searchFields: function () {
            let fields = ['name', 'specialization'];
            let itemToPush = 'events';
            if (this.type === 'approved') {
                itemToPush = 'languages';
            }
            fields.push(itemToPush);
            this.fields = fields;
            return fields;
        }
    },
    data: function () {
        return {
            type: false,
            speakersList: [],
            fields: [],
            searchText: '',
            globalCheck: '',
            sortparam: '',
            order: 1,
            showOptClass: 'hide'
        };
    }
});

/**
 * Render each speakers table row
 */
Vue.component('speaker-table-row', {
    template: '#speaker-table-row-template',
    props: {
        speaker: {
            type: Object,
            required: false
        },
        type: {
            type: String,
            required: false,
            default: 'approved'
        }
    },
    computed: {
        languageTooltipText: function () {
            if (this.speaker && this.speaker.languages.length > 1) {
                let names = _.pluck(this.speaker.languages, 'name');
                return names.join(',');
            } else {
                return '';
            }
        }
    },
    methods: {
        showOpts: function () {
            let checboxes = $('.mcm-overview-row .checkbox input');
            let opts = $('#list-speakers-options');
            let check = 0;
            checboxes.each(function () {
                if ($(this).prop('checked') === true) {
                    check++;
                }
            });

            if (check > 0) {
                this.$dispatch('updateOptsClass', null);

            } else {
                if (!opts.hasClass('hide')) {
                    this.$dispatch('updateOptsClass', 'hide');
                }
            }
        }
    },
    watch: {
        checked: function (val, oldVal) {
            let checked = val ? val : false;
            this.$dispatch('rowChecked', checked, this.speaker.id);
        }
    },
    data: function () {
        return {
            checked: false
        };
    }
});

/**
 * Render and manage speaker controls
 */
Vue.component('speakers-search-controls', {
    template: '#speakers-search-controls-template',
    events: {
        pageType: function (type) {
            this.type = type;
        },
        controlsData: function (data) {
            this.data = data;
        }
    },
    watch: {
        search: function (val) {
            this.$dispatch('searchQueryUpdate', val);
        },
        global: function (val) {
            this.$dispatch('searchQueryUpdate', val);
        }
    },
    computed: {
        events: function () {
            let events = [];
            if (typeof this.data.speakers !== 'undefined') {
                this.data.speakers.forEach(function (value) {
                    value.touchpoints.forEach(function (value) {
                        events.push(value.name);
                    });
                });
            }
            return _.uniq(events);
        }
    },
    data: function () {
        return {
            search: '',
            global: false,
            type: false,
            data: {}
        };
    }
});
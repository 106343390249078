/**
 * Card displayed for adboard group on groups page
 */
Vue.component('adboard-group-view', {
    props: {
        sourceUrl: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            adboardGroup: false,
            adboardGroups: false,
            touchpoints: {},
            search: '',
            sidebarOpen: true,
        };
    },
    computed: {

        /**
         * If there is a search input this filters adboard_items
         *
         * @returns {*}
         */
        adboardItems() {
            if (this.search.trim().length === 0) {
                return this.adboardGroup.adboard_items;
            }

            return this.adboardGroup.adboard_items.filter((item) => {
                return item.title.match(new RegExp(this.search, 'i'));
            });
        }
    },
    created() {
        this.updateData();
        $('#app-modal').on('hidden.bs.modal', () => this.updateData());
        McmEventBus.$on('adboardGroupViewDataNeedsRefresh', this.updateData);
        McmEventBus.$on('AdboardSidebarToggled', (val) => {
            this.sidebarOpen = val;
        });
    },

    ready(){
        $('.mcm-popover-card__nav').mCustomScrollbar({
            theme: "dark"
        });
    },
    methods: {

        /**
         * Update board object from server
         */
        updateData() {
            $.get(this.sourceUrl, {}, (response) => {
                this.adboardGroup = response.adboardGroup;
                this.adboardGroups = response.adboardGroups;
                this.touchpoints = response.touchpoints;
            });
        },

        /**
         * Clears search input string
         */
        clearSearch() {
            this.search = '';
        },

        /**
         * openAddNewItemModal Method
         */
        openAddNewItemModal() {
            MCMApp.AdboardModalActions.addNewItemModal(this.adboardGroup.id);
        },

        redirectToAddNewItem(){
            window.location.href = SiteVariables.urls.adboardItemsAdd+'/'+this.adboardGroup.id;
        },

    }

});
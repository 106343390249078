// welcome-page
//-------------------------------------------------------------

/**
 *  welcome-page component
 *
 *  Inline template
 */
Vue.component('welcome-page', {
    props: {
        url: {
            type: String,
            required: false
        }
    },
    data(){
        return {
            loginBranding: false,
            client: false,
        };
    },
    computed: {

        /**
         * Get current client background image as a css property
         *
         * @return {string}
         */
        getCurrentClientBgImage(){
            if (this.loginBranding) {
                const imageSrc = this.loginBranding.background.path;

                return `background-image: url("${imageSrc}")`;
            }
        },


        /**
         * Get current client header gradient as a css property
         *
         * @return {string}
         */
        getHeaderGradient(){
            if (this.loginBranding) {
                const primaryColor = this.loginBranding.primary_color,
                    secondaryColor = this.loginBranding.secondary_color;

                let cssProp = `background: -moz-linear-gradient(right, ${secondaryColor} 0%, ${primaryColor} 100%);`;
                cssProp += `background: linear-gradient(270deg, ${secondaryColor} 0%, ${primaryColor} 100%);`;

                return cssProp;
            }
        },


        /**
         * Return all current client styles in style tags
         *
         * @return {string}
         */
        getPageStyles(){
            const backgroundImage = `.welcome-page{${this.getCurrentClientBgImage}}`,
                headerGradient = `.welcome-page--banner{${this.getHeaderGradient}}`;

            return `<style type="text/css">${backgroundImage} ${headerGradient}</style>`;
        },


        /**
         * Return client logo path
         *
         * @return {string}
         */
        getClientLogoPath(){
            if (this.loginBranding) {
                return this.loginBranding.logo.path;
            }
        },


        /**
         * Get client name
         *
         * @return {string}
         */
        getClientName(){
            if (this.client) {
                return this.client.name.charAt(0).toUpperCase() + this.client.name.slice(1);
            }
        }
    },
    created(){
        this.getClientData();
    },
    methods: {

        /**
         * Load client data and branding from the props url
         */
        getClientData(){
            $.ajax({
                url: this.url,
                type: 'get',
                dataType: 'json',
                success: (data) => {
                    this.loginBranding = data.clientBranding ? data.clientBranding : false;
                    this.client = data.client ? data.client : false;
                },
                error: (data) => {
                    console.error(data);
                }
            });
        }
    }
});


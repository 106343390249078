Vue.component('stepped-form-numbers', {
    props:{
        steps : {
            type:Number,
            default:0,
            coerce: function (val) {
                return Number(val);
            }
        },
        currentStep : {
            type:Number,
            default:0,
            coerce: function (val) {
                return Number(val);
            }
        },
    },
    template: '#stepped-form-numbers-template'
});
/**
 * HCP Custom registration list
 * Shows only by current user
 * Accepts touchpoint-id as variable
 */
Vue.component('hcp-custom-registration-list', {
    template: '#hcp-custom-registration-list',
    props: ['touchpointId'],
    data: function () {
        return {
            touchpoint: false,
            registrations: false,
            loading: true
        };
    },
    created: function () {
        let _this = this;
        this.updateData();
        $('#app-modal').on('hidden.bs.modal', function (e) {
            _this.updateData();
        });
    },
    methods: {
        updateData: function () {
            let _this = this;
            _this.loading = true;
            $.get(SiteVariables.urls.userRegistrationController + '/view/' + this.touchpointId, {}, function (response) {
                _this.touchpoint = response.touchpoint;
                _this.registrations = response.registrations;
                _this.loading = false;
            });
        },
    }
});


/**
 * HCP Custom registration modal opener button component
 * Accepts touchpoint-id and created-by as variables
 */
Vue.component('hcp-custom-registration-button', {
    template: '#hcp-custom-registration-button',
    props: {
        touchpointId: null,
        createdBy: null,
        linkClass: {
            type: String,
            required: false,
            default: 'btn btn-info btn-raised'
        }
    },
    methods: {
        open: function () {
            MCMApp.ModalActions.openTouchpointRegisterModal(this.touchpointId, this.createdBy);
        }
    }
});

// register modal component
Vue.component('modal-delete-question', {
    template: '#modal-delete-question-template',
    methods: {
        yes(){
            this.$emit('close');
            this.$emit('delete');
        }
    }
});

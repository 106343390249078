// register modal component
Vue.component('modal-deny-hcp-success', {
    template: '#modal-deny-hcp-success-template',
    props: {

    },
    methods: {

    },
    computed: {

    }
});

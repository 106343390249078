/**
 * Media site stream component for touchpoints
 */
Vue.component('mediasite-stream', {
    template: '#mediasite-stream-template',
    props: {
        postExamLink: {
            type: String,
            required: false
        },
        link: {
            type: String,
            required: true
        },
        id: {
            type: [Number, String, Boolean],
            required: false,
            default: false
        },
        touchpointId: {
            type: [Number, String],
            required: true
        },
        playerId: {
            type: String,
            required: false,
            default: 'player'
        }
    },
    data(){
        return {
            loading: true,
            playerViewTime: 0
        };
    },
    ready(){
        const _this = this;
        this.initPusher();

        $(() => {
            if ($('#' + _this.playerId).length > 0) {
                this.initMediasiteStream();
            }
        });
    },
    methods: {

        /**
         * initialize pusher channel specific for touchpoint
         */
        initPusher(){
            if('undefined' === typeof(Pusher)) {
                return;
            }
            let pusher = new Pusher('0673a2812f4cb4cc4a92', {
                    cluster: 'eu',
                    encrypted: true
                }),
                channel = pusher.subscribe('mcm-webcast--' + this.touchpointId);

            channel.bind('refresh', (data) => {
                window.location.reload();
            });

            channel.bind('accreditation', (data) => {
                window.location = this.postExamLink;
            });
        },

        /**
         * Init media site stream in the iframe
         */
        initMediasiteStream(){
            const player = this.initMediaSitePlayer();

            this.recordStreamTimeForUser(player);
        },

        /**
         * Auto play media site player on page init
         * @param player MediasiteIFramePlayer
         */
        autoPlayMediaSitePlayer(player){
            if (player.isReady()) {
                player.play();
            }
        },

        /**
         * Record stream time for user
         * @param player MediasiteIFramePlayer
         */
        recordStreamTimeForUser(player){
            if (!this.id) {
                return;
            }
            player.addHandler({
                'currenttimechanged': (e) => {
                    let currentTime = Math.floor(e.currentTime);
                    const id = this.id;

                    if (currentTime % 10 === 0 && currentTime > this.playerViewTime) {
                        $.ajax({
                            url: "/webcast-stream/" + id,
                            type: 'PATCH',
                            dataType: 'JSON',
                            processData: false,
                            contentType: false
                        });

                        this.playerViewTime = currentTime;
                    }
                }
            });
        },

        /**
         * Init media site player on id player
         * @returns {MediasiteIFramePlayer}
         */
        initMediaSitePlayer(){
            const link = this.link,
                playerId = this.playerId,
                player = new Mediasite.Player(playerId, {
                    url: link,
                    events: {
                        'ready': (eventData) => {
                            this.autoPlayMediaSitePlayer(player);
                        }
                    }
                });

            this.loading = false;
            return player;
        }
    }
});

/**
 * HCP Custom registration list
 * Shows only by current user
 * Accepts touchpoint-id as variable
 */
Vue.component('touchpoint-survey-list', {
    template: '#touchpoint-survey-list-template',
    props: ['touchpointId','surveys'],
    data: function () {
        return {
            loading: true,
        };
    },
    created: function () {
        console.log(this.surveys);

    },
    methods: {

    }
});

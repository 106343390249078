Vue.component('video-platform-rating-experience', {
    template: '#video-platform-rating-experience-template',
    props: {
        actionUrls: {
            type: Object,
            required: true
        },
        area_id: {
            type: String | Number,
            required: true
        }
    },
    data(){
        return {
            showDeleteQuestionModal: false,
            showDeleteQuestionModalSuccess: false,
            loading: true,
            updatingLoader: false,
            questions: [],
            newQuestion: '',
            activeListItem: 'new',
            currentQuestionObj: {},
            currentQuestionBody: '',
            questionIsChanged: false
        };


    },
    created() {
        this.getQuestions();
    },
    methods: {
        getQuestions() {
            let url = this.actionUrls.urlGetQuestions;
            let _this = this;
            $.get(url, {}, function (response) {
                if (response.success === true) {
                    _this.questions = response.data;
                    _this.loading= false;
                }
            });
        },
        setActiveItem(item){
            this.questionIsChanged = false;
            this.activeListItem = item;
            if(item !== 'new'){
                this.currentQuestionObj = this.questions.filter(function(obj){return obj.id === item;})[0];
            } else {
                this.newQuestion = '';
                this.currentQuestionObj = {};
            }
        },
        isActiveItem(item){
            return this.activeListItem === item;
        },
        addNewQuestion(){
            let question = this.newQuestion.trim();
            if (question) {
                this.updatingLoader= true;
                let _this = this;
                var url = this.actionUrls.urlAddQuestion;
                var data = {
                    body: question,
                    area_id: this.area_id
                };
                $.post(url, data, (response) => {
                    if(response.success === true) {
                        _this.updatingLoader= false;
                        _this.newQuestion = '';
                        _this.getQuestions();
                    }
                });
            }
        },
        getCurrentMessageNewBody(e){
            //Value of new message
            this.currentQuestionBody = e;
            if(this.currentQuestionObj.body === this.currentQuestionBody || !this.currentQuestionBody.length){
                this.questionIsChanged = false;
            }else {
                this.questionIsChanged = true;
            }

        },
        updateQuestion(el){
            let _this = this;

            if(this.questionIsChanged && this.currentQuestionBody.length){
                this.updatingLoader= true;
                var url = this.actionUrls.urlUpdateQuestion;
                var data = {
                    id: el.id,
                    body: this.currentQuestionBody
                };

                $.post(url, data, (response) => {

                    if(response.success === true) {
                        _this.updatingLoader = false;
                        _this.activeListItem = 'new';
                        $('.question-list-col').scrollTop(0);
                        _this.getQuestions();
                    }
                });
            }
        },
        deleteQuestion(el){
            let _this = this;
            this.updatingLoader= true;

            var url = this.actionUrls.urlDeleteQuestion;
            var data = {
                id: el.id
            };

            $.post(url, data, (response) => {

                if(response.success === true) {
                    this.showDeleteQuestionModalSuccess = true;
                    _this.updatingLoader= false;
                    _this.activeListItem = 'new';
                    $('.question-list-col').scrollTop(0);
                    _this.getQuestions();
                }
            });

        },
        closeModal(dataModal){
            let _this = this;
            setTimeout(function(){
                _this[dataModal] = false;
            }, 100);

        }
    }

});




// Define meetingpoint actions for vue
//-------------------------------------------------------------

/**
 *
 * @type {{}}
 */
var MCMApp = MCMApp || {};


/**
 *
 * @type {{addNewAppointment: MCMApp.MeetingpointModalActions.addNewAppointment, addNewParticipants: MCMApp.MeetingpointModalActions.addNewParticipants, createNewTemplate: MCMApp.MeetingpointModalActions.createNewTemplate, editTemplate: MCMApp.MeetingpointModalActions.editTemplate}}
 */
MCMApp.MeetingpointModalActions = {

    /**
     * Add new appointment action in /meetingpoint/index
     * This is triggerd by InitAddNewAppointment
     * @param areaId
     * @returns {boolean}
     */
    addNewAppointment: function (areaId) {
        var data = {
            action: 'add-new-appointment',
            area_id: areaId
        };
        _handleModalActions('meetingpoint', data);
    },

    /**
     * Create new template action in /meetingpoint/index
     * This is triggerd when no template is found with addNewAppointment
     * @param areaId
     * @returns {boolean}
     */
    createNewTemplate: function (areaId) {
        var data = {
            action: 'create-new-template',
            area_id: areaId
        };
        _handleModalActions('meetingpoint', data);
    },

    /**
     * Edit template action in /meetingpoint/view
     * This is triggerd by editTemplate
     * @param areaId
     * @param touchpointId
     * @returns {boolean}
     */
    editTemplate: function (areaId, touchpointId) {
        var data = {
            action: 'edit-appointment',
            area_id: areaId,
            touchpoint_id: touchpointId
        };
        _handleModalActions('meetingpoint', data);
    },

    /**
     * Add new participants action in /meetingpoint/view
     * This is triggerd by addNewParticipantsModel
     * @param areaId
     * @returns {boolean}
     */
    addNewParticipants: function (touchpointId) {
        var data = {
            action: 'add-new-participants',
            touchpoint_id: touchpointId
        };
        _handleModalActions('meetingpoint', data);
    },

    /**
     * Add new speakers function in /meetingpoint/view
     * This is triggerd by addNewSpeakersModel
     * @param areaId
     * @param touchpointId
     */
    addNewSpeakers: function (areaId, touchpointId) {
        var data = {
            action: 'add-new-speakers',
            area_id: areaId,
            touchpoint_id: touchpointId
        };
        _handleModalActions('meetingpoint', data);
    },

    /**
     * Shows the options to send meetingpoint related emails
     * This is triggerd by sendMeetingpointEmailsModel
     * @param areaId
     * @param touchpointId
     */
    sendMeetingpointEmails: function (areaId, touchpointId) {
        var data = {
            action: 'send-mp-emails',
            area_id: areaId,
            touchpoint_id: touchpointId
        };
        _handleModalActions('meetingpoint', data);
    }
};
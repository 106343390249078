// Vue component template-emails-list
//-------------------------------------------------------------

Vue.component('template-emails-list', {
    template: '#template-emails-list',
    props: ['emailTemplates'],
    methods: {
        openPreview: function (id) {
            MCMApp.ModalActions.touchpointMailTemplatePreview(id);
        }
    }
});
// Vue touchpoint mixin
// -- Generic touchpoint mixin for holding and updating data
//-------------------------------------------------------------

var MCMApp = MCMApp || {};

MCMApp.touchpointMixin = {
    data: {
        touchpointId: false,
        touchpoint: false,
        loading: false
    },
    created: function () {
        var _this = this;
        this.refreshTouchpoint();
        $('#app-modal').on('hidden.bs.modal', function (e) {
            _this.refreshTouchpoint();
        });
    },
    events: {
        touchpointUpdateNeeded: function () {
            this.refreshTouchpoint();
        }
    },
    methods: {
        refreshTouchpoint: function () {
            this.loading = true;

            // If no touchpoint Id set TP to false and return
            if (this.touchpointId === false) {
                this.touchpoint = false;
                return;
            }

            $.get(SiteVariables.urls.touchpointsController + '/view/' + this.touchpointId, {}, (response) => {
                this.touchpoint = response.touchpoint;
                this.$emit('touchpointUpdated', this.touchpoint);
                if (response.touchpoint) {
                    this.loading = false;
                }
            });
        },
        updateTouchpoint: function () {
            this.refreshTouchpoint();
        },
        onSubmitSaveTouchpoint: function (e) {
            e.preventDefault();

            const data = $(e.currentTarget).serialize();
            const url = e.currentTarget.getAttribute('action');

            $.post(url, data, (response) => {
                if (typeof response !== 'object') {
                    response = JSON.parse(response);
                }
                if (response.id !== undefined) {
                    this.$emit('touchpointUpdateNeeded');
                    this.$emit('touchpointSubmitted', response);
                } else {
                    alert('There was an error while creating');
                }
            });
        },
        submitForm: function ($form) {
            //var _this = this;
            const url = $form.prop('action');
            const data = $form.serialize();
            $.post(url, data, (response) => {
                this.edit = false;
                this.refreshTouchpoint();
                this.$emit('formSubmitCompleted');
                this.$emit('touchpointUpdateNeeded');
                const parsedResponse = JSON.parse(response);
                if (parsedResponse.success !== undefined && parsedResponse.success === false) {
                    _.each(parsedResponse.message, function (messageItem) {
                        _.each(messageItem, function (messageText) {
                            $.snackbar({
                                content: messageText,
                                style: "text-center alert alert-danger",
                                timeout: 5000
                            });
                        });
                    });
                    return false;
                }
                $.snackbar({
                    content: 'Updated!',
                    style: "text-center alert alert-success",
                    timeout: 2000
                });
            });
        },
        onSubmit: function (event) {
            this.submitForm($(event.currentTarget));
        }
    }
};
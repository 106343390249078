var MCMApp = MCMApp || {};

MCMApp.Templates = MCMApp.Templates || {};
MCMApp.Templates.Adboard = MCMApp.Templates.Adboard || {};

MCMApp.Templates.Adboard = {
    approveCurrentVersionInstance: function () {
        return new Vue({
            el: '#adboard-approve-current-file',
            mixins: [
                MCMApp.VueMixins.closeAppModal,
                MCMApp.VueMixins.modalBoxInstance
            ],
            data: {},
            methods: {
                formSubmit: function (e) {
                    var _this = this;
                    var data = $(e.currentTarget).serialize();
                    var url = e.currentTarget.getAttribute('action');
                    $.post(url, data, function (response) {
                        _this.closeModal();
                    });
                }
            }
        });
    },

    addItemFinalScreen: function () {
        return new Vue({
            el: '#summarize-adboard-item-container',
            mixins: [
                MCMApp.VueMixins.closeAppModal,
                MCMApp.VueMixins.modalBoxInstance
            ],
        });
    },
    addNewGroup: function () {
        return new Vue({
            el: '#add-new-group-container',
            mixins: [
                MCMApp.VueMixins.closeAppModal,
                MCMApp.VueMixins.modalBoxInstance
            ],
            methods: {
                onSubmit: function (event) {
                    var _this = this;

                    var $form = $(event.currentTarget);

                    var url = $form.prop('action');
                    var data = $form.serialize();

                    $.post(url, data, function (response) {
                        _this.closeModal();
                    });
                }
            }
        });
    },

    addNewGroupOnItemCreation: function (adboardItemId) {
        return new Vue({
            el: '#add-new-group-container',
            mixins: [
                MCMApp.VueMixins.closeAppModal,
                MCMApp.VueMixins.modalBoxInstance
            ],
            data: {
                adboardItemId: adboardItemId
            },
            methods: {
                onSubmit: function (event) {
                    const $form = $(event.currentTarget);
                    const url = $form.prop('action');
                    const data = $form.serialize();

                    $.post(url, data, (response) => {
                        MCMApp.AdboardModalActions.uploadFileToItemModal(this.adboardItemId);
                    });
                }
            }
        });

    },
    addNewItem: function () {
        return new Vue({
            el: '#add-new-group-container',
            data: {
                createNewBoard: false,
            },

            compiled: function () {
                McmPlatformMainElementsInit();
            },
            methods: {

                openNextModal: function (itemId) {
                    if (this.createNewBoard) {

                        MCMApp.AdboardModalActions.createNewBoardOnItemCreation(itemId);
                    } else {
                        MCMApp.AdboardModalActions.uploadFileToItemModal(itemId);
                    }
                },

                onSubmit: function (event) {
                    const $form = $(event.currentTarget);
                    const url = $form.prop('action');
                    const data = $form.serialize();

                    $.post(url, data, (response)=> {

                        //Go next step if exists
                        if (response.adboardItem) {
                            this.openNextModal(response.adboardItem.id);
                        }
                        // or close and go to edit/view page
                        //MCMApp.Modals.appModal.close();
                    });
                }
            }
        });
    },
    editItem: function () {
        return new Vue({
            el: '#add-new-group-container',
            data: {
                steps: [
                    'info',
                    'upload'
                ],
                displayedStep: 'info'
            },
            methods: {

                getNextStep: function () {

                },

                openNextStep: function () {

                },

                onSubmit: function (event) {
                    var _this = this;

                    var $form = $(event.currentTarget);

                    var url = $form.prop('action');
                    var data = $form.serialize();

                    $.post(url, data, function (response) {

                        //Go next step if exists
                        // or close and go to edit/view page
                        MCMApp.Modals.appModal.close();
                    });
                }
            }
        });
    },

    editGroup: function () {
        return new Vue({
            el: '#add-new-group-container',
            ready: function () {
                McmPlatformMainElementsInit();
            },
            methods: {
                onSubmit: function (event) {

                    var $form = $(event.currentTarget);


                    var url = $form.prop('action');
                    var data = $form.serialize();

                    $.post(url, data, function (response) {

                        MCMApp.Modals.appModal.close();
                    });
                }
            }
        });
    },

    seeAdboardVersions: function (adboardItemUrl) {
        return new Vue({
            el: '#item-versions-container',
            data: {
                adboardItem: false,
                currentFile: false,
                parent: MCMApp.AdboardItemDetail
            },

            ready: function () {
                this.updateAdboardItem();
            },
            methods: {
                updateAdboardItem: function () {
                    $.get(adboardItemUrl, {}, (response) => {
                        if (response.adboardItem) {
                            this.adboardItem = response.adboardItem;
                        }
                    });
                },
                setCurrentFile: function (file) {
                    this.currentFile = file;
                    McmVueMainAppInstance.$broadcast('current-file-changed', this.currentFile);
                }
            }
        });
    },

    /**
     *
     * @param adboardItemUrl
     * @returns {Vue}
     */
    uploaderFormForNewFile: function (adboardItemUrl) {
        return new Vue({
            el: '#add-new-group-container',
            data: {
                adboardItem: false,
                fileUploading: false,
                fileUploaded: false,
                fileSelected: false,
                description: ''
            },

            ready: function () {
                this.updateAdboardItem();
            },
            methods: {

                updateAdboardItem: function () {
                    $.get(adboardItemUrl, {}, (response) => {
                        if (response.adboardItem) {
                            this.adboardItem = response.adboardItem;
                        }
                    });
                },
                setFileUploaded: function () {
                    this.fileUploading = false;
                    this.fileUploaded = true;
                },
                setFileUploading: function () {
                    this.fileUploading = true;
                },
                setFileSelected: function () {
                    this.fileSelected = true;
                },

                openNextScreen: function () {

                    //update uploader desc
                    uploader.settings.multipart_params.adboard_file_description = UploaderFormVM.description;

                    uploader.start();
                    //MCMApp.Modals.appModal.close();
                }
            }
        });
    },
    uploaderForm: function (adboardItemUrl) {
        return new Vue({
            el: '#add-new-group-container',
            data: {
                adboardItem: false,
                fileUploading: false,
                fileUploaded: false,
            },
            ready: function () {
                this.updateAdboardItem();
            },
            methods: {

                updateAdboardItem: function () {
                    $.get(adboardItemUrl, {}, (response) => {
                        if (response.adboardItem) {
                            this.adboardItem = response.adboardItem;
                        }
                    });
                },
                setFileUploaded: function () {
                    this.fileUploading = false;
                    this.fileUploaded = true;
                },
                setFileUploading: function () {
                    this.fileUploading = true;
                },

                openNextScreen: function () {
                    return MCMApp.AdboardModalActions.addItemFinalScreen(this.adboardItem.id);
                }
            }
        });
    }
};



/**
 * Showing basic mailing reports click and operate
 */

Vue.component('mailing-basic-reports', {
    template: '#mailing-basic-reports-template',
    props: ['touchpointid', 'mailtype'],
    ready: function (done) {
        this.update();
    },
    data: function () {
        return {
            reportData: false,
            loading: true
        };
    },
    watch: {
        reportData: function (val, oldVal) {
            var _this = this;
            var chartOpeningRate = c3.generate({
                data: {

                    // iris data from R
                    columns: [
                        ['Open', _this.reportData.uniqueOpens],

                    ],
                    type: 'gauge',

                },
                gauge: {
                    label: {
                        format: function (value, ratio) {
                            return value;
                        },
                        show: true // to turn off the min/max labels.
                    },
                    min: 0, // 0 is default, //can handle negative min e.g. vacuum / voltage / current flow / rate of change
                    max: _this.reportData.totalMails, // 100 is default
                    units: ' sent',
                    expand: true
                },
                size: {
                    height: 100,
                    width: 150
                },
                bindto: d3.select('.line-chart-opening--'+_this.mailtype),
            });

            var chartClickRate = c3.generate({
                data: {

                    // iris data from R
                    columns: [
                        ['Click', _this.reportData.uniqueClicks],

                    ],
                    type: 'gauge',


                },
                gauge: {
                    label: {
                        format: function (value, ratio) {
                            return value;
                        },
                        show: true // to turn off the min/max labels.
                    },
                    min: 0, // 0 is default, //can handle negative min e.g. vacuum / voltage / current flow / rate of change
                    max: _this.reportData.totalMails, // 100 is default
                    units: ' sent',
                    expand: true
                },
                size: {
                    height: 100,
                    width: 150
                },
                bindto: d3.select('.line-chart-clicked--'+_this.mailtype),
            });
        }
    },
    methods: {
        update: function () {
            var _this = this;
            var url = '/reports/generic/events-json/' + this.mailtype + '?touchpoint_id=' + this.touchpointid;

            this.loading = true;

            $.get(url, {}, function (response) {
                console.log(response);
                _this.loading = false;
                if (response) {
                    _this.$set('reportData', response);
                }
            });
        }
    }
});
Vue.component('adboard-group-list-item', {
    template: '#adboard-group-list-item-template',
    props: ['adboard', 'selected'],
    computed : {
        direction: function () {
            return (this.selected) ? 'down' : 'right';
        },
        classes : function () {
            return {
                'br-primary--bg': this.selected,
                'board-selected' : this.selected
            };
        }
    },
    methods: {
        toggleSelectedGroup: function () {
            McmEventBus.$emit('selected-group-toggled', this.adboard);
        },
        editGroup: function () {
            MCMApp.AdboardModalActions.editGroupModal(this.adboard.id);
        }
    }

});
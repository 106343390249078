/**
 * Media site stream component for touchpoints
 */
Vue.component('mediasite-ondemand-user-view', {
    template: '#mediasite-ondemand-user-view-template',
    props: {
        questions: {
            type: Array,
            required: false
        },
        video: {
            type: Object,
            required: true
        },
        accreditationMode: {
            type: [Boolean, String],
            default: false
        },
        examUrl: {
            type: String,
            default: '',
            required: false
        },
        touchpointId: {
            type: Number,
            required: true
        },
        canSkipVideo: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            player: false,
            loading: true,
            hasPlayed: false,
            videoReady: false,
            currentVideoSeconds: 0,
            videoDuration: 0,
            currentVideoState: false,
            pollSkipped: false,
            /**
             * we store answered question ids here
             * so we can check before deciding to show
             * them again
             */
            answeredQuestionsIds: [],
            videoLogSavedSeconds: []
        };
    },
    computed: {
        /**
         * Html id of player
         * @returns {string}
         */
        playerId() {
            return 'ms-player-' + this.video.id;
        },

        /**
         * Video uses a different player to hide standard buttons so we make sure player param is there
         * But if we are using in pause-able mode we can use its own methods
         *
         * @returns {string}
         */
        videoUrl() {
            let url = '';
            if (Boolean(this.canSkipVideo)) {
                url = this.video.url;
            } else {
                url = this.video.url + (this.video.url.indexOf("?") === -1 ? "?" : "&") + "player=MediasiteIntegration";
            }

            return url.trim();
        },

        currentVideoTime() {
            return this.convertSecondsToTime(this.currentVideoSeconds);
        },

        /**
         * If we can show the custom controls for the video or not
         * Custom controls are used in accreditation mode to prevent
         * controls other than play and pause
         *
         * @returns {boolean}
         */
        showCustomControls() {
            return (this.accreditationMode && this.videoReady);
        },

        /**
         * buttons like Go To Post Exam are shown when video is
         * about to finish. Checks duration if video is in last X seconds
         * By default it is 30 seconds
         * TODO: make settable from video settings
         *
         * @returns {boolean}
         */
        videoAboutToFinish() {
            if (this.videoDuration === 0 || this.currentVideoSeconds === 0) {
                return false;
            }

            return ( Number(this.videoDuration - this.currentVideoSeconds) < this.video.tolerance_seconds );
        },

        /**
         * To show the Post Exam button or not to show
         * That is the question
         * Answer depends...
         *
         * @return {boolean}
         */
        showPostExamButton() {
            return (this.accreditationMode && this.videoAboutToFinish && this.examUrl.length > 0);
        },

        isVideoPlaying() {
            return (this.currentVideoState === 'playing');
        }
    },

    created() {
        McmEventBus.$on('ondemandPollQuestionDisplayed', this.ondemandPollQuestionDisplayed);
        McmEventBus.$on('ondemandPollQuestionReplied', this.ondemandPollQuestionReplied);
        McmEventBus.$on('ondemandPollQuestionSkipped', this.ondemandPollQuestionSkipped);
    },

    ready() {
        this.initMediaSitePlayer();
    },

    watch: {
        currentVideoSeconds(val, oldVal) {

            if (this.isVideoPlaying && this.videoLogSavedSeconds.indexOf(parseInt(val)) === -1 && (parseInt(val) % 5 === 0 || oldVal === 0)) {
                this.videoLogSavedSeconds.push(parseInt(val));

                this.updateVideoLog(parseInt(val));
            }
        }
    },

    methods: {

        /**
         * Init media site player on id player
         */
        initMediaSitePlayer() {
            this.player = new Mediasite.Player(this.playerId,
                {
                    url: this.videoUrl,
                    events: {}
                }
            );

            this.player.addHandler("ready", (e) => {
                this.player.seekTo(0);
                this.videoReady = true;
                this.videoDuration = this.player.getDuration();
                this.currentVideoState = this.player.getPlayState();
            });

            this.player.addHandler("currenttimechanged", (data) => {
                if (data.currentTime) {
                    this.currentVideoSeconds = data.currentTime;
                }
            });

            this.player.addHandler("playstatechanged", (data) => {
                this.currentVideoState = data.playState;
            });

            this.loading = false;
        },


        /**
         * Plays the video
         */
        restartVideo() {
            this.player.seekTo(0);
            this.player.play();
        },

        /**
         * Plays the video
         */
        playVideo() {
            if (this.player.getPlayState() === 'playing') {
                return;
            }
            if (this.player.getPlayState() === 'paused') {
                return this.player.play();
            }
            if (false === this.hasPlayed) {
                this.hasPlayed = true;
                return this.restartVideo();
            }


        },
        /**
         * Plays the video
         */
        pauseVideo() {
            if (this.player.getPlayState() === 'playing') {
                this.player.pause();
            }
        },
        /**
         * Plays the video
         */
        stopVideo() {
            this.player.stop();
        },

        reloadVideoPage() {
            window.location.reload();
        },

        convertSecondsToTime(seconds) {
            const dateObj = new Date(null);
            dateObj.setSeconds(seconds);
            return moment(dateObj).format('mm:ss');
        },

        /**
         * Event handler for ondemandPollQuestionDisplayed event triggered
         * Gets question object and url to post as parameters
         *
         * By default triggers a modal action
         *
         * @param question Object
         * @param url String
         * @param player Object
         */
        ondemandPollQuestionDisplayed(question, url, player) {
            if (this.player.getPlayState() === 'playing') {
                _handleModalActions('accreditation', {action: 'show-poll-question'}, null, {
                    initialize: true,
                    'id': 'app-modal-instance',
                    'data': {
                        question: question,
                        url: url,
                        player: player,
                    }
                });

                this.pauseVideo();
            }
        },

        /**
         * Event handler for ondemandPollQuestionReplied event triggered
         * Gets question object and player as parameters
         *
         * - closes
         *
         * @param question Object
         * @param player Object
         */
        ondemandPollQuestionReplied(question, player) {

            setAppModalClosingSideEffects(false);

            //close app modal
            closeAppModal();

            //continue video
            this.playVideo();

        },
        /**
         * Event handler for ondemandPollQuestionSkipped event triggered
         * Gets question object and player as parameters
         *
         * - closes
         *
         * @param question Object
         */
        ondemandPollQuestionSkipped(question) {

            setAppModalClosingSideEffects(false);

            //close app modal
            closeAppModal();

            //continue video
            this.stopVideo();

            this.pollSkipped = true;
        },

        updateVideoLog(second) {


            console.log(this.videoLogSavedSeconds);
            const method = (this.videoLogSavedSeconds.length > 1) ? 'PATCH' : 'POST';


            $.ajax({
                url: SiteVariables.urls.videoLogs,
                type: method,
                data: {
                    videoId: this.video.id,
                    touchpointId: this.touchpointId,
                    accreditation: !!this.accreditationMode
                },
                success: (data) => {
                    if (data.response) {
                        this.$parent.updateList();
                        this.speakersList = [];
                    }
                }
            });
        }

    }
});
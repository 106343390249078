// Meetingpoint index page - Add new appointment and render view
//---------------------------------------------------------------------

var MCMApp = MCMApp || {};

/**
 *
 * @type {{InitAddNewAppointment: MCMApp.AddNewAppointment.InitAddNewAppointment}}
 */
MCMApp.AddNewAppointment = {

    /**
     * This is initilized in /meetingpoint/index/
     * @returns {Vue}
     * @constructor
     */
    InitAddNewAppointment: function () {
        return new Vue({
            mixins: [MCMApp.touchpointMixin],
            el: '#add-appointment-flow-container',
            data: {
                displayed: {
                    title: false,
                    template: false,
                    date: false,
                    speakers: false,
                    add_participants: false,
                    final: false
                },
                //This is display order or steps
                //Title always needs to be first
                flowSteps: [
                    'title',
                    'date',
                    'template',
                    'speakers',
                    'final'
                ],
                updateInProgress : false,
                currentStep: false,
                nextStep: false,
                prevStep: false,
                touchpointId: false,
                invalidEmails: []
            },
            events: {

                /**
                 * Update touchpoints event
                 */
                dataUpdateNeeded: function () {
                    this.updateTouchpoint();
                }
            },
            ready: function () {
                //set first step true
                this.setSteps(this.flowSteps[0]);
                McmPlatformMainElementsInit();
            },
            watch: {

                /**
                 * Current step method
                 * @param val
                 * @param oldVal
                 */
                currentStep: function (val, oldVal) {
                    this.closeAllDisplayed();
                    this.updateInProgress = false;
                    McmPlatformMainElementsInit();
                }
            },
            methods: {

                /**
                 * Close all displayed method
                 */
                closeAllDisplayed: function () {
                    for (var key in this.displayed) {
                        this.displayed[key] = false;
                    }
                },

                /**
                 * Open previous option method
                 */
                openPrevStep: function () {
                    if (this.prevStep) {
                        this.closeAllDisplayed();
                        this.setSteps(this.prevStep);
                    }
                },

                /**
                 * Open next option method
                 */
                openNextStep: function () {
                    if (this.nextStep) {
                        this.setSteps(this.nextStep);
                    }
                },

                /**
                 * Set steps method
                 * @param currentStep
                 */
                setSteps: function (currentStep) {
                    var stepName = currentStep;
                    var currentIndex = this.flowSteps.indexOf(currentStep);

                    this.closeAllDisplayed();
                    this.displayed[stepName] = true;
                    this.currentStep = stepName;
                    if (this.flowSteps[currentIndex + 1]) {
                        this.nextStep = this.flowSteps[currentIndex + 1];

                    } else {
                        this.nextStep = false;
                    }
                    if (this.flowSteps[currentIndex - 1]) {
                        this.prevStep = this.flowSteps[currentIndex - 1];
                    } else {
                        this.prevStep = false;
                    }
                },

                /**
                 * Add speakers method
                 */
                addCustomSpeakerInput: function () {
                    var $parentEl = $('#custom-speaker-inputs');
                    var $template = $('#custom-speaker-template');
                    var $clone = $template.clone();
                    //reset value and append
                    $clone.find('input').val('');
                    $clone.appendTo($parentEl);
                },

                /**
                 * Create appointment on submit method
                 * @param e
                 */
                onSubmitCreate: function (e) {
                    var _this = this;
                    var data = $(e.currentTarget).serialize();
                    var url = e.currentTarget.getAttribute('action');
                    this.updateInProgress = true;
                    $.post(url, data, function (response) {
                        if (response.touchpoint !== undefined) {
                            _this.touchpoint = response.touchpoint;
                            _this.touchpointId = response.touchpoint.id;
                            _this.$emit('dataUpdateNeeded');
                            _this.openNextStep();
                        } else {
                            alert('There was an error while creating');
                        }
                        this.updateInProgress = false;
                    });
                },

                /**
                 * Save method on submit
                 * @param e
                 */
                onSubmitSave: function (e) {
                    e.preventDefault();
                    var _this = this;
                    var data = $(e.currentTarget).serialize();
                    var url = e.currentTarget.getAttribute('action');
                    this.updateInProgress = true;
                    $.post(url, data, function (response) {
                        this.updateInProgress = false;
                        if (typeof response !== 'object') {
                            response = JSON.parse(response);
                        }
                        _this.$emit('dataUpdateNeeded');
                        if (response.id !== undefined || response.listing !== undefined) {
                            _this.openNextStep();
                        } else {
                            alert('error');
                        }

                    });
                },

                /**
                 * Save method submit participants
                 * @param e
                 */
                onSubmitParticipants: function (e) {
                    //first check for validation of email addresses
                    var _this = this;
                    var emailLines = $(e.currentTarget).find('textarea.participant-email-list').val().split('\n');
                    var constraints = {
                        emailaddress: {
                            email: true
                        }
                    };
                    this.invalidEmails = [];
                    _.each(emailLines, function (val) {
                        if (validate({emailaddress: val}, constraints) !== undefined) {
                            _this.invalidEmails.push(val);
                        }
                    });
                    if (this.invalidEmails.length === 0) {
                        this.onSubmitSave(e);
                    }
                }
            }
        });
    }
};
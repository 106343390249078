Vue.component('project-card', {
    template: '#project-card-template',
    props: {
        project: {
            type: [Object],
            required: true
        }
    },
    computed: {
        isSingleEvent() {
            return (this.project.touchpoints.length === 1);
        },
        isMultipleEvent() {
            return (this.project.touchpoints.length > 1);
        },
        projectType: function () {
            if (this.project.touchpoints.length === 1) {
                return 'single';
            }
            return 'campaign';
        }

    }
});
Vue.component('video-platform-home-page-management-tabs', {
    template: "#video-platform-home-page-management-tabs-template",
    mixins: [MCMApp.videoPlatformTabsMixin, MCMApp.videoPlatformLoaderMixin],
    props: {
        emailTemplate: {
            type: String,
            required: true
        },
        hcps: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            selectedHCPS: {
                "users": {},
                "lists":{}
            }
        };
    },
    methods: {
        removeHCP(data) {
            Vue.delete(this.selectedHCPS[data.target], data.id);
        },
        addHCP(data) {
            Vue.set(this.selectedHCPS[data.target], data.id, data.name);
        },
        sendInvitation(data) {
            this.startLoader();

            this.$emit('send-invitation', data);

        },
        back() {
            this.$emit('back');
        },
    },
    computed: {
        hasHCPS() {
            return Object.keys(this.hcps).length;
        }
    }
});
// Adboard-schedule-meeting-survey
//-------------------------------------------------------------

/**
 *  Adboard-schedule-meeting-survey-answer component
 */
Vue.component('adboard-schedule-meeting-survey-answer', {
    template: '#adboard-schedule-meeting-survey-answer-template',
    props: {
        answer: {
            type: Object,
            required: false,
            default: function() {
              return {
                body: ""
              };
            }
        }
    },
    data() {
        return {
        };
    },

    ready: function() {
    },

    methods : {
    }
});

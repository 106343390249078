// Adboard-schedule-meeting-survey-wrapper
//-------------------------------------------------------------

/**
 *  Adboard-schedule-meeting-survey-wrapper component
 */
Vue.component('adboard-schedule-meeting-survey-wrapper', {
    props: {
        selectedSurvey: {
            type: Object,
            required: false
        },
        clientId: {
            type: Number
        }
    },
    data() {
        return {
            selectedSurveyId: this.selectedSurvey ? this.selectedSurvey.id : 0
        };
    },

    watch: {
      selectedSurveyId() {
        let _this = this;
        $.ajax({
            url: SiteVariables.urls.surveys,
            async: false,
            data: {surveyId: _this.selectedSurveyId},
            dataType: "json",
            success: ((data) => {

                if (data.selectedSurvey !== null) {
                    _this.selectedSurvey = data.selectedSurvey;
                } else {

                    _this.selectedSurvey = _this.defineSelectedSurvey();

                }

            })
        });
      }
    },

    ready: function() {
        let newOption,
            surveyListDom = $('.surveyListSelect');

        McmPlatformMainElementsInit();

        if (!this.selectedSurvey) {
            this.selectedSurvey = this.defineSelectedSurvey();
        }

        McmEventBus.$on('surveyModalNeedsRefresh', ((survey) => {

            if( typeof(survey.survey_items) === "undefined") {
                survey.survey_items = [];
                survey.survey_items.push(this.defineNewQuestion());
                
            }else {

                survey.survey_items.forEach((question) => {
                    if (typeof(question.child_survey_items) === "undefined") {
                        question.child_survey_items = [];
                    }
                });
            }
            surveyListDom.find('option[value="' + survey.id + '"]').remove();
            this.selectedSurvey = survey;
            newOption = document.createElement("option");
            surveyListDom.find('option[selected="selected"]').attr('selected', '');
            newOption.innerHTML = survey.name;
            newOption.setAttribute('value', survey.id);
            newOption.setAttribute('selected','selected');
            surveyListDom.append(newOption);
        }));
    },

    methods : {

        defineSelectedSurvey: function () {
            return {
                id: 0,
                name: "",
                survey_items: [],
                client_id: this.clientId
            };
        },

        defineNewQuestion: function () {
            return {
                body:"",
                child_survey_items: [],
                question_type: 'radio',
                client_id: this.clientId
            };
        },

        addNewQuestion: function() {
            this.selectedSurvey.survey_items.push(this.defineNewQuestion());
        },

        saveSurvey: function(e) {
            const form = $(e.currentTarget),
                data = this.selectedSurvey;
            let url = form.attr('action'),
                method = form.attr('method');

            this.filterEmptySurveyItems();

            $.ajax({
                url: url,
                type: method,
                data: data,
                dataType: 'json',
                success: (data) => {
                    if(data.response.status === '200') {
                        McmEventBus.$emit('surveyModalNeedsRefresh', data.response.survey);
                    }
                }
            });

        },

        updateSurvey: function (e) {
            const data = this.selectedSurvey;
            let url = SiteVariables.urls.updateSurvey,
                method = 'post';

            this.filterEmptySurveyItems();

            $.ajax({
                url: url,
                type: method,
                data: data,
                dataType: 'json',
                success: (data) => {
                    if(data.response.status === '200') {
                        McmEventBus.$emit('surveyModalNeedsRefresh', data.response.survey);
                    }
                }
            });

        },
        
        filterEmptySurveyItems: function () {
            let questionArray = [],
                localAnswers;

            let questions = this.selectedSurvey.survey_items.filter((question)=> {
                return question.body !== '' && question.body !== 'undefined';
            });

            questions.forEach((question) => {
                if(question.child_survey_items !== 'undefined') {
                    localAnswers = question.child_survey_items.filter((answer) => {
                        return answer.body !== '' && answer.body !== 'undefined';
                    });
                    question.child_survey_items = localAnswers;
                    questionArray.push(question);
                }
            });

            this.selectedSurvey.survey_items = questionArray;
        }
    }
});

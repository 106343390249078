Vue.component('modal-registration-field-edit', {
    template: '#modal-registration-field-edit',
    props: {
        editFieldUrl: {
            type: String,
            required: true,
        },
        field: {
            type: Object,
        },
    },
    data() {
        return {
            loading: false,
            error: false,
            model: {
                name: this.field.name,
                label: this.field.label,
                required: this.field.required.toString(),
                type: this.field.type,
            },
        };
    },
    methods: {
        save() {
            this.loading = true;
            this.error = false;

            const changedValues = Object.assign({ id: this.field.id }, this.model);
            if (changedValues.name === this.field.name) {
                delete changedValues.name;
            }

            $.post(this.editFieldUrl, changedValues)
                .done(() => { this.$emit('close'); })
                .fail((res) => {
                    console.error(res);
                    this.error = true;
                })
                .always(() => { this.loading = false; });
        },
    },
});

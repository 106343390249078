// Vue component listing-double-optin-toggle
//-------------------------------------------------------------

Vue.component('listing-double-optin-toggle', {
    template: '#listing-double-optin-toggle',
    props : ['listing'],
    methods: {
        update: function () {
            var _this = this;
            var url = SiteVariables.urls.listingsController + '/edit/' + this.listing.id;
            //console.log('changed',this.listing,this.listing.double_optin_needed);
            $.post(url,{double_optin_needed:this.listing.double_optin_needed},function(response){
                //console.log('updated',response);
            });
        }
    }
});

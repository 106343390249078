Vue.component('color-sample-display', {
    template: '#color-sample-display-template',
    props: ['color'],
    computed: {
        colorComputed: function () {
            if (this.color.length === 6) {
                return '#' + this.color;
            }
            return this.color;
        },
    }

});
var MCMApp = MCMApp || {};

MCMApp.Forms = MCMApp.Forms || {};

MCMApp.Forms.SpeakerFormViewClass = Backbone.View.extend({

	events: {
		"change .form-input-file": "imageUploadChange",
		"click .add-new-input-to-section" : "addNewInputToParent"
	},

	initialize: function() {

	},

	imageUploadChange : function(e){
		var $el = $(e.currentTarget);


		this.readURL(e.currentTarget);
	},

	readURL : function(input) {
		var $el = $(input);
		var previewElSelector = $el.data("preview");

	    if (input.files && input.files[0]) {
	        var reader = new FileReader();

	        reader.onload = function (e) {
	            $(previewElSelector).attr('src', e.target.result);
	        };


	        reader.readAsDataURL(input.files[0]);
	    }
	},




});

if($("#add-speaker-form-container").length){

	MCMApp.Forms.SpeakerFormView = new MCMApp.Forms.SpeakerFormViewClass({
		"el" : '#add-speaker-form-container'
	});
}

/**
 * Card displayed for adboard group on groups page
 */
Vue.component('adboard-group-sidebar', {
    template: '#adboard-group-sidebar-template',
    props: {
        adboardGroup: {
            type: Object,
            required: true
        },
        touchpoints: {
            type: [Object, Boolean, Array],
            required: false
        },
        adboardItems: {
            type: Array,
            required: false,
            default: []
        }
    },
    data() {
        return {
            isOpened: true
        };
    },
    ready() {
        //init scrollbar and refresh on window resize
        this.initializeScrollbar();
        $(window).resize(this.initializeScrollbar);
    },
    watch : {
        isOpened(val,oldVal){
            McmEventBus.$emit('AdboardSidebarToggled',val);
        }
    },
    methods: {

        /**
         * Toggle sidebar open and close
         */
        toggle() {
            this.isOpened = !this.isOpened;
        },

        /**
         * Go to manage users page
         */
        goToManageUsersPage() {
            window.location.href = this.adboardGroup.manage_users_url;
        },

        initializeScrollbar() {
            $('.adboard-group-sidebar__content-area').height(window.innerHeight - $('.mcm-navigation-wrapper.mcm-navigation-wrapper--fixed').height());
            $('.adboard-group-sidebar__content-area').mCustomScrollbar({
                theme: "dark"
            });
        }
    }
});


/**
 * Upcoming meetings in adboard group sidebar
 */
Vue.component('adboard-group-sidebar-upcoming-meetings', {
    template: '#adboard-group-sidebar-upcoming-meetings-template',
    props: {
        touchpoint: {
            type: Object,
            required: true
        },
        adboardItems: {
            type: Array,
            required: true,
            default: []
        },
        adboardItemUrl: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            adboardItem: null
        };
    },
    computed: {

        /**
         * Return adboard item url
         * @return {String}
         */
        itemUrl() {
            if (this.adboardItem !== null) {
                return String(this.adboardItemUrl + '/' + this.adboardItem.id);
            }

            return '#';
        }
    },
    created() {
        this.setAdboardItem();
    },
    methods: {

        /**
         * Get adboard item and map it with the current touchpoint
         */
        setAdboardItem() {
            if (this.adboardItems && this.adboardItems.length) {
                this.adboardItems.forEach((adboardItem) => {
                    if (adboardItem.project && !_.isEmpty(adboardItem.project)) {
                        let touchpoints = adboardItem.project.touchpoints;

                        if (touchpoints && touchpoints.length > 0) {
                            touchpoints.forEach((touchpoint) => {
                                if (touchpoint.id === this.touchpoint.id) {
                                    this.adboardItem = adboardItem;
                                    return true;
                                }
                            });
                        }
                    }
                });
            }
        }
    }
});

/**
 * members of board on adboard group sidebar
 */
Vue.component('adboard-group-sidebar-member', {
    template: '#adboard-group-sidebar-member-template',
    props: {
        member: {
            type: Object,
            required: true
        },
        adboardGroup: {
            type: Object,
            required: true
        }
    },
    data: function () {
        return {
            popupDisplayed: false,
        };
    },
    created() {

        McmEventBus.$on('adboardMemberPopupDisplayed', (member) => {
            if (this.member.id !== member.id) {
                this.popupDisplayed = false;
            }
        });
    },
    ready() {

       // document.addEventListener("click", this.popupListener);
    },
    watch : {
        /**
         * always listen mouse clicks of the user in order to hide / show popup
         */
        popupDisplayed(val,oldVal) {
            if(val === true) {
                document.addEventListener("click", this.popupListener);
            } else {
                document.removeEventListener("click", this.popupListener);
            }
        }
    },
    computed: {
        popupPositionStyles() {
            return {
                left: event.pageX + 'px',
                top: event.pageY - 60 + 'px'
            };
        }
    },

    methods: {

        /**
         *  displays popup when have been clicking to specific member which is on board members section in sidebar
         * @param event
         */
        toggleMemberCard(event) {
            McmEventBus.$emit('adboardMemberPopupDisplayed', this.member);

            this.popupDisplayed = !this.popupDisplayed;
        },

        /**
         * call to action in order to remove given user from relevant adboard group
         * remove button action which is on member popup card
         */
        removeUser() {

            const url = SiteVariables.urls.adboardGroupsUsersController + '/handle',
                data = {
                    adboardGroupId: this.adboardGroup.id,
                    memberId: this.member.id,
                    action_name: 'deleteUser'
                };
            $.ajax({
                url: url,
                dataType: 'JSON',
                type: 'POST',
                data: data,
                success: (response) => {
                    McmEventBus.$emit('adboardGroupViewDataNeedsRefresh');
                }
            });
        },

        /**
         * TODO: remove and find a better solution
         * @param event
         */
        popupListener(event) {
            let element = $(event.target),
                allCard = $(document).find('.adboard-member-card__popup'),
                pointer_popup = element[0].closest('.adboard-member-card__popup'),
                pointer_card = element[0].closest('.card-item');

            if ((!pointer_popup && !pointer_card) || (pointer_popup && pointer_card)) {
                allCard.hide();
            }
        }
    }
});


Vue.component('video-platform-single-video', {
    template: '#video-platform-single-video-template',
    props: {
        video: {
            type: Object,
            required: true
        },
        actionUrls: {
            type: Object,
            required: true
        },
        global: {
            type: String | Number,
            default: 0
        },
    },
    computed: {
        isGlobal() {
            return !!Number(this.global);
        },
        uploadedAt() {
            return moment(this.video.created).format('DD-MM-YYYY');
        },
        img_section_style: function img_section_style() {
            return {
                "background-image": "url(" + this.video.thumbnail_url + ")"

            };
        },
        isHomePageManagement() {
            return !!this.actionUrls.invite;
        },
        eventTime() {
            return new Date(this.video.event_date).toLocaleString("en-us", {
                year: "numeric",
                month: "short"
            });
        }
    },
    methods: {
        viewVideo() {
            MCMApp.VideoPlatformModalActions.viewVideoModal(this.video.external_id, this.video.title, this.video.source);
        },
        deleteVideo() {
            MCMApp.VideoPlatformModalActions.deleteVideoModal(this.video.id, this.video.title, this.isGlobal);
        },
        editVideo: function () {
            window.location.href = this.actionUrls.edit + "/" + this.video.id;
        },
        featuredVideo() {
            $.ajax({
                url: this.actionUrls.featured + '/' + this.video.id,
                type: "POST",
                success: () => {
                    this.$emit('update');
                }
            });
        },
        checkIfVideoIsSaved() {
            return this.video.title && this.video.approval_code && this.video.speaker && this.video.tags.length && this.video.event_date && !this.video.published_at;
        },
        getStatus() {
            return this.video.published_at ? (this.isGlobal ? "Available to be published on all channels" : "Published") : (this.checkIfVideoIsSaved() ? "Saved" : "Missing information");
        }
    }
});

/**
 *
 */

Vue.component('adboard-group-calendar-container', {
    props: {
        sourceUrl: {
            type: String,
            required: true
        }
    },
    data: function () {
        return {
            user: null,
            touchpoints: null,
            touchpointsCount: 0,
            calendar: []
        };
    },
    created: function () {

        $.mCustomScrollbar.defaults.scrollButtons.enable = true;

        McmEventBus.$on('AdboardGroupCalendarMeetingsRefreshNeeded', this.getMeetings);

    },
    ready: function () {
        this.getMeetings();


        $('.meetings__list').mCustomScrollbar({
            theme: "dark",
            scrollInertia: 100
        });
    },
    methods: {
        getMeetings() {
            let calendarObject = $.fullCalendar;

            $.ajax({

                url: this.sourceUrl,

                success: (response) => {
                    this.user = response.user;
                    if (response.touchpoints) {

                        this.touchpoints = response.touchpoints;
                        this.touchpointsCount = response.touchpoints.length;
                    }
                },

                complete: () => {
                    _.each(this.touchpoints, (touchpoint, key) => {
                        touchpoint.start_calendar = moment(new Date(touchpoint.start)).format('YYYY-MM-DD');

                        this.calendar.push({
                            title: '<time datetime="' + touchpoint.start + '">' + touchpoint.eventstarttime + '</time> ' + touchpoint.title,
                            start: touchpoint.start_calendar,
                            touchpoint: touchpoint
                        });
                    });
                    this.initCalendar();
                }
            });
        },

        initCalendar() {

            let _this = this;

            $('#calendar').fullCalendar({

                events: _this.calendar,
                eventLimit: 3,
                height: 600,
                eventBackgroundColor: '#78A12E',
                eventBorderColor: '#78A12E',
                header: {
                    left: 'prev',
                    center: 'title',
                    right: 'next'
                },
                columnFormat: 'dddd',

                eventRender: function (event, element, view) {
                    let title = element.find('.fc-title');
                    title.html(title.text());
                },

                dayClick: function (date, jsEvent, view) {
                    // console.log('Clicked on: ' + date.format());
                    // console.log('Coordinates: ' + jsEvent.pageX + ',' + jsEvent.pageY);
                    // console.log('Current view: ' + view.name);
                },

                eventClick: function (calEvent, jsEvent, view) {

                    // console.log('Event: ', calEvent);
                    // console.log('Event: ' + calEvent.title);
                    // console.log('Coordinates: ' + jsEvent.pageX + ',' + jsEvent.pageY);
                    // console.log('View: ' + view.name);
                    McmEventBus.$emit('calendarMeetingDetailDisplayed', calEvent.touchpoint);
                }
            });
        }
    }

});
var MCMApp = MCMApp || {};

MCMApp.Forms = MCMApp.Forms || {};

MCMApp.Forms.AddVideoFormViewClass = Backbone.View.extend({

    events: {

		"change .video-url-input" : "getVideoPreview",
		//"paste .video-url-input" : "getVideoPreview",



    },

    initialize: function() {

    },


    getVideoPreview : function(e){
        var _this =this;
		var $el = $(e.currentTarget);

        var data = {
            url : $el.val()
        };

        $('#tp-video-preview').html('<i class="fa fa-spinner fa-spin"></i>');
        //query embed code and title
        $.post(SiteVariables.urls.getUrlEmbed,data,function(response){

            _this.updateVideoPreview(response);
        });
	},

    updateVideoPreview : function(response){
        if(response.code) {

            $("#tp-video-preview").html(response.code);
            $("#video-name-input").val(response.name);
            $("#video-description-input").val(response.description);

        } else {

            $("#tp-video-preview").html('');
            alert('Embedding of this video is not possible.');

        }
    },



});


MCMApp.Forms.AddVideoForm = new MCMApp.Forms.AddVideoFormViewClass({
    "el": '#add-video-form-container'
});

/**
 * Media site stream component for touchpoints
 */
Vue.component('mediasite-poll-admin-view', {
    template: '#mediasite-poll-admin-view-template',
    props: {
        questions: {
            type: [Array, Boolean],
            required: false
        },
        video: {
            type: Object,
            required: true
        },
        pausable: {
            type: [Boolean, String],
            default: false
        },
        triggerPoll: {
            type: [Boolean, String],
            default: true
        }
    },
    data() {
        return {
            player: false,
            loading: true,
            //if video has ever played
            hasPlayed: false,
            currentVideoSeconds: 0,

            /**
             * we store answered question ids here
             * so we can check before deciding to show
             * them again
             */
            answeredQuestionsIds: []
        };
    },
    computed: {

        /**
         * Html id of player
         * @returns {string}
         */
        playerId() {
            return 'ms-player-' + this.video.id;
        },

        /**
         * Video uses a different player to hide standard buttons so we make sure player param is there
         * But if we are using in pause-able mode we can use its own methods
         *
         * @returns {string}
         */
        videoUrl() {
            if(!this.video.url.includes('mediasite')) {
                return this.video.url.trim();
            }
            let url = '';
            if (Boolean(this.pausable)) {
                url = this.video.url;
            } else {
                url = this.video.url + (this.video.url.indexOf("?") === -1 ? "?" : "&") + "player=MediasiteIntegration";
            }

            return url.trim();
        },

        isMediasiteVideo(){
            return !!this.video.url.includes('mediasite');
        },

        embedCode(){
            return this.isMediasiteVideo ? "":EmbedVideo(this.videoUrl);
        },

        pausableBoolean() {
            if(this.pausable === 'false') {
                return false;
            }
            return Boolean(this.pausable);
        },

        shouldTriggerPoll() {
            if(this.triggerPoll === 'false') {
                return false;
            }
            return Boolean(this.triggerPoll);
        },

        /**
         * get currentVideoTime
         * @return {*}
         */
        currentVideoTime() {
            return this.convertSecondsToTime(this.currentVideoSeconds);
        }
    },
    created() {
        McmEventBus.$on('ondemandPollQuestionDisplayed', this.ondemandPollQuestionDisplayed);
        McmEventBus.$on('ondemandPollQuestionReplied', this.ondemandPollQuestionReplied);

        this.$on('video-player-ready', () => {

            if (!this.video.length || parseInt(this.video.length) !== parseInt(this.player.getDuration())) {
                console.log('I am ready', this.video.length, this.player.getDuration());
                $.ajax({
                    url: SiteVariables.urls.videoControllerHandler,
                    type: 'PATCH',
                    data: {
                        video_id: this.video.id,
                        length: this.player.getDuration(),
                    },
                    success: (response) => {

                        console.log(response);
                    }
                });
            }
        });
    },
    ready() {
        this.initMediaSitePlayer();
    },

    methods: {

        /**
         * Init media site player on id player
         */
        initMediaSitePlayer() {
            const _this = this;

            if(!this.isMediasiteVideo) {
                return;
            }

            this.player = new Mediasite.Player(this.playerId,
                {
                    url: this.videoUrl,
                    events: {}
                }
            );

            this.player.addHandler("ready", (e) => {


                this.$emit('video-player-ready');

                this.player.seekTo(0);
            });

            this.player.addHandler("currenttimechanged", function (data) {
                if (data.currentTime) {
                    _this.currentVideoSeconds = data.currentTime;
                }
            });

            this.loading = false;
        },


        /**
         * Plays the video
         */
        restartVideo() {
            this.player.seekTo(0);
            this.player.play();
        },


        /**
         * Plays the video
         */
        playVideo() {
            if (this.player.getPlayState() === 'playing') {
                return;
            }
            if (false === this.hasPlayed) {
                this.hasPlayed = true;
                return this.restartVideo();
            }
            this.player.play();

        },
        /**
         * Plays the video
         */
        pauseVideo() {
            if (this.player.getPlayState() === 'playing') {
                this.player.pause();
            }
        },


        /**
         * convertSecondsToTime Method
         * @param seconds
         */
        convertSecondsToTime(seconds) {
            const dateObj = new Date("Thu Oct 19 2017 00:00:00");
            dateObj.setSeconds(seconds);
            return moment("1900-01-01 00:00:00").add(Number(seconds), 'seconds').format("HH:mm:ss");
        },

        /**
         * Event handler for ondemandPollQuestionDisplayed event triggered
         * Gets question object and url to post as parameters
         *
         * By default triggers a modal action
         *
         * @param question Object
         * @param url String
         * @param player Object
         */
        ondemandPollQuestionDisplayed(question, url, player) {
            if(!this.shouldTriggerPoll) {
                return;
            }
            if (this.player.getPlayState() === 'playing') {
                _handleModalActions('accreditation', {action: 'show-poll-question'}, 'lg', {
                    initialize: true,
                    'id': 'app-modal-instance',
                    'data': {
                        question: question,
                        url: url,
                        player: player,
                    }
                });

                this.pauseVideo();
            }
        },

        /**
         * Event handler for ondemandPollQuestionReplied event triggered
         * Gets question object and player as parameters
         *
         * - closes
         *
         * @param question Object
         * @param player Object
         */
        ondemandPollQuestionReplied(question, player) {
            setAppModalClosingSideEffects(false);

            //close app modal
            closeAppModal();

            //continue video
            this.playVideo();
        },


    }
});


Vue.component('mediasite-poll-question-timer', {
    template: '#mediasite-poll-question-timer-template',
    props: {
        question: {
            type: Object,
            required: true
        },
        video: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showTime: ''
        };
    },
    computed: {

        /**
         * showTimeInSeconds Method
         * @return {*}
         */
        showTimeInSeconds() {
            if (this.showTime) {

                console.log(this.showTime);
                let parsed = this.showTime.split(':');
                if (parsed.length === 2) {
                    return (Number(parsed[0]) * 60) + Number(parsed[1]);
                }
                if (parsed.length === 3) {
                    return (Number(parsed[0]) * 60 * 60) + (Number(parsed[1]) * 60) + Number(parsed[2]);
                }
            }
            return '';
        },

        /**
         * currentVideoTime Method
         * @return {mediasite-poll-question-timer.computed.currentVideoTime|mediasite-poll-admin-view.computed.currentVideoTime|mediasite-ondemand-user-view.computed.currentVideoTime}
         */
        currentVideoTime() {
            return this.$parent.currentVideoTime;
        }
    },
    ready() {
        if (this.question.show_time) {
            this.showTime = this.convertSecondsToTime(Number(this.question.show_time));
        }
    },

    methods: {

        /**
         * setCurrentTime Method
         * @return {*}
         */
        setCurrentTime() {

            let currentVideoSeconds = this.$parent.player.getCurrentTime();
            if (!currentVideoSeconds) {
                return alert('Video is not available or stopped');
            }

            this.showTime = this.convertSecondsToTime(Number(currentVideoSeconds));
            this.updateShowTimeForQuestion();
        },


        /**
         * updateShowTimeForQuestion Method
         * @todo: validate the time
         */
        updateShowTimeForQuestion() {
            const url = SiteVariables.urls.accreditationQuestions;
            let data = {
                question_id: this.question.id,
                show_time: this.showTimeInSeconds,
                video_id: this.video.id,
                //edit action heavily relies on answer data
                //impossible to path just question
                action_name: 'update'
            };

            $.ajax({
                url: url,
                type: 'put',
                data: data,
                dataType: 'json'
            });
        },

        /**
         * convertSecondsToTime Method
         * @param seconds
         */
        convertSecondsToTime(seconds) {
            const dateObj = new Date("Thu Oct 19 2017 00:00:00");
            dateObj.setSeconds(seconds);
            return moment("1900-01-01 00:00:00").add(Number(seconds), 'seconds').format("HH:mm:ss");
        }
    }
});

/**
 * List of participants list connected to touchpoint
 * shown as a list with name and no of participants and
 * linked to the actions
 */
Vue.component('touchpoint-participants-lists', {
    template: '#touchpoint-participants-lists-template',
    props: [
        'projectId',
        'touchpointId'
    ],
    data: function () {
        return {
            lists: false,
            loading: false,
        };
    },
    created: function () {
        this.fetchLists();
    },
    methods: {
        toggleLoading: function () {
            this.loading = !this.loading;
        },
        fetchLists: function () {
            let _this = this;
            let url = SiteVariables.urls.projectsListingsController + '/lists-summary/' + this.touchpointId;

            this.toggleLoading();
            $.get(url, {}, function (response) {
                if (response) {
                    if (response.projectsListings) {
                        var lists = [];
                        _.each(response.projectsListings, function (el, key) {
                            lists.push(el.listing);
                        });
                        _this.lists = lists;
                    }
                }

                _this.toggleLoading();
            });
        }
    }
});


/**
 * List of participants list connected to touchpoint
 * shown as a list with name and no of participants and
 * linked to the actions
 */
Vue.component('touchpoint-participants-lists-row', {
    template: '#touchpoint-participants-lists-row-template',
    props: ['list'],
    data: function () {
        return {
            downloadFile: false
        };
    },
    ready: function () {
        McmPlatformMainElementsInit();
    },
    methods: {
        displayListDetailsOnSubSection: function () {
            if (!this.downloadFile) {
                this.$dispatch('openSubSection', 'participants', 'listDetails', {list: this.list});
            } else {
                setTimeout(() => {
                    this.downloadFile = false;
                }, 1000);
            }
        },
        stopDisplayList: function () {
            this.downloadFile = true;
        }
    },
});


/**
 * List of participants list connected to touchpoint
 * shown as a list with name and no of participants and
 * linked to the actions
 */
Vue.component('touchpoint-participants-listing-table', {
    template: '#touchpoint-participants-listing-table-template',
    props: ['list'],
    data: function () {
        return {
            participants: false,
            loading: false,
        };
    },
    created: function () {
        this.fetchParticipants();
    },
    methods: {
        fetchParticipants: function () {
            let _this = this;
            let url = SiteVariables.urls.listingsController + '/view/' + this.list.id;

            this.loading = true;
            $.get(url, {}, function (response) {
                _this.loading = false;
                _this.participants = response.listing.hcp_metas;

            });
        }
    }
});
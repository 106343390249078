var MCMApp = MCMApp || {};

MCMApp.Forms = MCMApp.Forms || {};

MCMApp.Forms.SuperadminViewClass = Backbone.View.extend({

	events: {
		"change .form-input-file": "imageUploadChange",
		"click .add-new-input-to-section" : "addNewInputToParent"

	},

	initialize: function() {
		console.log("started");
	},

	imageUploadChange : function(e){
		var $el = $(e.currentTarget);


		this.readURL(e.currentTarget);
		console.log($el);
	},

	readURL : function(input) {
		var $el = $(input);
		var previewElSelector = $el.data("preview");

	    if (input.files && input.files[0]) {
	        var reader = new FileReader();

	        reader.onload = function (e) {
	            $(previewElSelector).attr('src', e.target.result);
	        };


	        reader.readAsDataURL(input.files[0]);
	    }
	},

	addNewInputToParent : function(e){
		var $el = $(e.currentTarget);
		var $parent = $el.parent();
		var $inputToCopy = $parent.find(".input-to-copy").first().clone();
		console.log($inputToCopy);
		//clear value
		$inputToCopy.val('');
		//append to the list
		$inputToCopy.appendTo($parent.find('.form-section__list-inputs'));
	}


});

if($("#add-client-form-container").length){

	MCMApp.Forms.SuperadminForm = new MCMApp.Forms.SuperadminViewClass({
		"el" : '#add-client-form-container'
	});
}

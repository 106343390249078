

Vue.directive('has-comment-popups', {
    bind: function () {
        var _this = this;
        $(this.el).on('click', '.adboard-item-comment__reply', function () {

            var $elem = $('#adboard-comment-pin--' + $(this).data('id'));
            var elemVM = $elem[0].__vue__;


            MCMApp.AdboardItemDetail.$emit('comment-reply-requested', elemVM.comment);
        });
    }
});
Vue.component('adboard-item-pin', {
    template: '#adboard-item-pin',
    props: ['comment', 'currentkey'],

    data: function () {
        return {
            popoverTimeout: false
        };
    },

    events: {
        'coordinateChanged': function () {
            //update comment coordinates in db
            this.updateCommentCoordinates();
        }
    },
    computed: {
        pinstyle: function () {
            if (this.comment.pin_coordinates.top && this.comment.pin_coordinates.left) {
                return {
                    left: this.comment.pin_coordinates.left + '%',
                    top: this.comment.pin_coordinates.top + '%',
                };
            }
            return false;

        },

        commentkey: function () {
            return this.currentkey + 1;
        }

    },
    methods: {
        popover: function (e) {
            var _this = this;
            var $item = $(e.currentTarget);

            $('.adboard-item-pin').not('#' + $item.attr('id')).popover('destroy');
            //$('.adboard-item__doc-pins .popover').remove();


            $item.popover({
                html: true,
                content: function () {
                    var html = '';
                    // TODO fix for more suitable version for templating
                    // maybe with a partial
                    html += '<div class="adboard-popover-comment">';
                    html += '<div class="adboard-popover-comment__name">';
                    html += _this.comment.user.fullname;
                    html += '</div>';
                    html += '<div class="adboard-popover-comment__message">';
                    html += _this.comment.message;
                    html += '</div>';
                    html += '<a class="adboard-item-comment__reply cursor-pointer" data-id="' + _this.comment.id + '">Reply</a>';
                    html += '</div>';
                    return html;
                },
                viewport: $item.parent(),
                placement: "right"
            });

        },

        /**
         * Calculate and update comment coordinates in db
         * TODO: move calculations to a general function
         * for reusablity
         *
         */
        updateCommentCoordinates: function () {
            var _this = this;

            var $img = $('#adboard-item-viewed-image');


            //get clicked coordinates
            var offset = $img.offset();
            var pinOffset = $(this.$el).offset();
            var pinCoordinate = {
                left: pinOffset.left - (offset.left),
                top: pinOffset.top - (offset.top)
            };

            //get image size
            var imageSize = {
                width: $img.width(),
                height: $img.height(),
            };

            //convert them to percentage to saving
            var pinCoordinatesPerc = {
                left: Math.round((pinCoordinate.left * 100) / imageSize.width),
                top: Math.round((pinCoordinate.top * 100) / imageSize.height)
            };

            //We send it in left:top format like 85:75 and it is handled while saving
            var coordinates = pinCoordinatesPerc.left + ':' + pinCoordinatesPerc.top;

            $.post(this.comment.edit_url, {
                id: this.comment.id,
                pin_coordinates: coordinates
            }, function (response) {

            });
        }


    }

});


Vue.transition('appearoutofnowhere', {
    // Vue will now only care about `animationend` events
    // for this transition
    type: 'animation'
});

Vue.transition('disappearoutofnowhere', {
    // Vue will now only care about `animationend` events
    // for this transition
    type: 'animation'
});
// Vue component
//-------------------------------------------------------------

/**
 * Page title controls
 */
if (document.getElementById('page-title-instance')) {
    var pageTitleComponent = new Vue({
        el: '#page-title-instance',
        components: {
            'page-title-controls': {
                template: '#page-title-controls-template',
                props: {
                    controls: {
                        default: false
                    },
                    icon: {
                        type: String,
                        default: 'search'
                    },
                    selector: {
                        type: String,
                        default: '.mcm-sidebar-flex'
                    },
                    animationClass: {
                        type: String,
                        default: 'active animated slideInRight'
                    }
                },
                data: function () {
                    return {
                        overlayElement: '<div class="mcm-overlay"></div>',
                        isActive: false,
                    };
                },
                computed: {
                    currentIcon: function () {
                        return (this.isActive) ? 'close' : this.icon;
                    }
                },
                watch: {
                    isActive: function (val, oldVal) {
                        if (val) {
                            $('body').append(this.overlayElement);
                        } else {
                            $('.mcm-overlay').remove();
                        }

                        $(this.selector).toggleClass(this.animationClass);
                    }
                },
                methods: {
                    toggleControls: function (e) {
                        this.isActive = !this.isActive;
                    }
                }
            }
        }
    });
}
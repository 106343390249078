Vue.component('video-platform-home-page-management-tabs-hcp-list', {
    template: "#video-platform-home-page-management-tabs-hcp-list-template",
    props: {
        hcps: {
            type: Object,
            required: true
        },
        selectedHcps: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            search: "",
            focused: {
                target: "",
                id: 0
            },
            languages: {
                "en_US": "English",
                "fr_Fr": "French",
                "es-Es": "Spanish",
                "pt_Br": "Portuguese"
            },
        };
    },
    created() {
        this.choseFocused(this.hcps);
    },
    methods: {
        setFocused(id, target) {
            this.focused.id = id;
            this.focused.target = target;
        },
        checkFocused(id, target) {
            return (this.focused.id == id && this.focused.target == target);
        },
        choseFocused(obj) {
            if (Object.keys(obj.users).length) {
                this.focused.target = "users";
            } else if (Object.keys(obj.lists).length) {
                this.focused.target = "lists";
            } else {
                this.focused.target = "";
            }

            this.focused.id = 0;
        },
        removeHCPFromList(id, target) {
            this.$emit('remove-hcp', {target: target, id: id});
        },
        addHCPFromList(id, name, target) {
            this.$emit('add-hcp', {id: id, name: name, target: target});
        },
        checkSelected(id, target) {
            return this.selectedHcps[target][id];
        }
    },
    computed: {
        filteredList: function () {
            if (this.search) {
                let search = this.search.toLowerCase();
                let newObj = {};

                if (Object.keys(this.hcps.users).length) {
                    newObj.users = this.hcps.users.filter(hcp => {

                        if(typeof(hcp.firstname) == 'undefined'){hcp.firstname = '';}
                        if(typeof(hcp.lastname) == 'undefined'){hcp.lastname = '';}
                        if(typeof(hcp.email) == 'undefined'){hcp.email = '';}
                        if(typeof(hcp.info.title) == 'undefined'){hcp.info.title = '';}
                        if(typeof(hcp.info.expertise) == 'undefined'){hcp.info.expertise = '';}
                        if(typeof(hcp.info.institution) == 'undefined'){hcp.info.institution = '';}

                        return (
                            hcp.firstname.toLowerCase().match(search) ||
                            hcp.lastname.toLowerCase().match(search) ||
                            hcp.email.toLowerCase().match(search) ||
                            hcp.info.title.toLowerCase().match(search) ||
                            hcp.info.expertise.toLowerCase().match(search) ||
                            hcp.info.institution.toLowerCase().match(search)
                        );
                    });
                }

                if (Object.keys(this.hcps.lists).length) {
                    newObj.lists = this.hcps.lists.filter(list => {
                        return list.name.toLowerCase().match(search);
                    });
                }

                this.choseFocused(newObj);

                return newObj;
            } else {
                this.choseFocused(this.hcps);

                return this.hcps;
            }
        }
    }
});
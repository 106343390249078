var MCMApp = MCMApp || {};

(function () {
    'use strict';
    MCMApp.Utilities = {

        formHeightTimer: false,

        init: function () {
            var _this = this;

            if ($(".has-equal-fs-height-children").length) {

                this.hasEqualFormSectionHeightHandler();

                this.formHeightTimer = setInterval(function () {
                    _this.hasEqualFormSectionHeightHandler();
                }, 2000);
            }

            this.stickyFormHeaders();

            $(window).load(function () {
                _this.appModal();
                _this.modalActionsInit();

                _this.flashAlerts();
            });
        },

        /*
         *	Makes children of .has-equal-fs-height-children elements form-sections height equal
         */
        hasEqualFormSectionHeightHandler: function () {
            $(".has-equal-fs-height-children").each(function () {
                var heights = [];

                var biggestHeight = false;
                var $children = $(this).children();
                if ($children.length > 1) {

                    $children.each(function () {
                        heights.push($(this).height());
                    });

                    biggestHeight = _.max(heights, function (height) {
                        return height;
                    });

                    $children.each(function () {
                        $(this).find('.form-section:last-child').css('minHeight', biggestHeight + "px");
                    });
                }
            });
        },


        stickyFormHeaders: function () {
            if ($(".form-stick-section").length) {

                var fromTop = $(".form-stick-section").offset().top;
                $(window).scroll(function () {
                    if ($(window).scrollTop() > fromTop) {
                        $(".form-stick-section").addClass('fixed');
                    } else {
                        $(".form-stick-section").removeClass('fixed');

                    }
                });
            }
        },

        appModal: function () {

            MCMApp.Modals.appModal = new MCMApp.Classes.Modal({
                el: '#app-modal'
            });

        },

        modalActionsInit: function () {

            MCMApp.ModalActions.init();

        },

        flashAlerts: function () {
            $(".form-alert-messages .alert").each(function () {
                var $el = $(this);
                var hasError = false;
                var options = {
                    content: $el.html(),


                    htmlAllowed: true, // allows HTML as content value
                    onClose: function () {
                    } // callback called when the snackbar gets closed.
                };
                if ($el.hasClass('alert-warning') || $el.hasClass('alert-danger')) {
                    options.timeout = 0;
                    hasError = true;
                }

                var $bar = $.snackbar(options);

                //add classes
                var classList = $el.attr('class').split(/\s+/);
                _.each(classList, function (className) {
                    $bar.addClass(className);
                });

            });
        },


        selectionBoxesSelect: function () {
            $('body').on('click', '.selection-boxes .selection-box', function () {
                //unselect others
                var $boxesEl = $(this).parent();
                $boxesEl.find('.selection-box').each(function () {

                    $(this).find('input').prop('checked', false);

                    $(this).removeClass('selection-box--selected');
                });
                //make this select this one's radio selected
                $(this).find('input').prop('checked', true);
                $(this).addClass('selection-box--selected');


            });
        },

        /**
         * Runs js init functions for general form elements
         * on vuejs next tick to update ui
         */
        renewFormStyles: function () {
            Vue.nextTick(function () {
                $.material.init();
                McmPlatformMainElementsInit();
            });
        }
    };

    MCMApp.Utilities.init();

})(jQuery);
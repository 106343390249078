// Speakers view page actions
//-------------------------------------------------------------

var MCMApp = MCMApp || {};

/**
 *
 * @type {{InitEditSpeaker: MCMApp.EditSpeaker.InitEditSpeaker}}
 */
MCMApp.EditSpeaker = {

    /**
     *
     * @param speakerId
     * @returns {Vue}
     * @constructor
     */
    InitEditSpeaker: function (speakerId) {

        return new Vue({
            el: '#app-instance',
            data: {
                speakerId: speakerId,
                edit: false
            },
            watch: {
                edit: function () {
                    McmPlatformMainElementsInit();
                    McmInitEditor();
                }
            },
            created: function () {
                McmInitEditor();
                McmPlatformMainElementsInit();
            },
            events: {

                /**
                 * Speaker update event
                 * @param speaker
                 */
                speakerUpdated: function (speaker) {
                    this.edit = false;
                },

                /**
                 * Speaker submitted event
                 */
                speakerSubmitted: function () {
                    MCMApp.Modals.appModal.close();
                }
            },
            methods: {

                /**
                 * Edit template action
                 * @returns {*}
                 */
                editSpeaker: function () {
                    return MCMApp.SpeakerModalActions.editSpeaker(this.speakerId);
                },

                /**
                 * Create event button fires an informative popup
                 * @returns {*}
                 */
                createEvent: function () {
                    return MCMApp.SpeakerModalActions.createEvent();
                },

                /**
                 * Submit edit form speaker with ajax
                 * @returns void
                 */
                submitEditSpeakerAjax: function (e) {
                    const form = $(e.currentTarget);
                    const data = form.serialize();

                    $.ajax({
                        url: form.attr('action'),
                        type: form.attr('method'),
                        data: data,
                        dataType: 'json',
                        success: (data) => {
                            if (data.response) {
                                MCMApp.Modals.appModal.close();
                            }
                        },
                        error: (data) => {
                            console.error(data);
                        }
                    });
                }

            }
        });
    }
};
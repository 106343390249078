Vue.component('touchpoint-meta-details', {
    template: '#touchpoint-meta-details-template',
    props: ['metaValue', 'metaKey', 'touchpoint', 'updateUrl'],

    data() {
        return {
            newValue: this.metaValue.content,
            editMode: false,
            showKey: false
        };
    },

    computed: {
        isTypeString() {
            return (this.metaValue.type === 'string');
        },
        isTypeText() {
            return (this.metaValue.type === 'text');
        },
        isTypeBoolean() {
            return (this.metaValue.type === 'boolean');
        }
    },

    watch : {
        editMode(){
            McmPlatformMainElementsInit();
        }
    },

    methods: {
        toggleEditMode() {
            this.editMode = !this.editMode;
        },
        toggleKey() {
            this.showKey = !this.showKey;
        },
        closeEditMode() {
            this.editMode = false;
        },

        updateMetaValue(e) {
            $.post(this.updateUrl, {
                key_name: this.metaKey,
                content: this.newValue
            }, (response) => {
                this.closeEditMode();
                McmEventBus.$emit('refreshCurrentTouchpointNeeded');
            });
        },

        restoreDefault() {
            $.post(this.updateUrl, {
                key_name: this.metaKey,
                restore: true
            }, (response) => {

                console.log(response);
                this.closeEditMode();
                McmEventBus.$emit('refreshCurrentTouchpointNeeded');
                this.newValue = this.metaValue.default;
            });
        }
    }
});


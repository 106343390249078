Vue.component('video-platform-hcp-management-new-hcp', {
    template: '#video-platform-hcp-management-new-hcp-template',
    props: {
        actionUrls: {
            type: Object,
            required: true
        },
        areaName: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            request: {
                firstname: '',
                lastname: '',
                email: '',
                title: '',
                expertise: '',
                institution: '',
                country: this.areaName,
                default_language: '',
            },
            languages: {
                "en_US":"English",
                "fr_Fr":"French",
                "es-Es":"Spanish",
                "pt_Br":"Portuguese",
                "vi":"Vietnamese"
            },
            canSubmit : false
        };
    },
    methods: {
        activateSubmit(){

            if(
                this.request.firstname != '' && this.request.lastname != '' && this.request.email != '' && this.request.title != '' &&
                this.request.expertise != '' && this.request.institution != '' && this.request.default_language != ''
            ){
                this.canSubmit = true;
            } else {
                this.canSubmit = false;
            }

        },
        sendInvitation() {
            var data = this.request;
            $.post(this.actionUrls.get, data, (response) => {
                if (response.success === true) {
                    if(response.data.length){
                        MCMApp.VideoPlatformModalActions.InviteHcpErrorModal(response.data);
                    } else {
                        MCMApp.VideoPlatformModalActions.InviteHcpModal();
                    }
                }
            });
        }
    }
});

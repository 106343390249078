// Vue components adboard
//-------------------------------------------------------------


/**
 * Main listing in adboard. This component is a page container component
 * So it has a inline template instead.
 *
 * Wrapper component
 */
Vue.component('adboard-items-dashboard', {
    data: function () {
        return {
            adboardItems: [],
            loading: false
        };
    },
    created: function () {
        this.updateAdboards();
        $('#app-modal').on('hidden.bs.modal', (e) => {
            this.updateAdboards();
        });
    },
    methods: {
        updateAdboards: function () {
            const url = SiteVariables.urls.adboardItemsController;
            this.loading = true;
            this.$broadcast('dataUpdateNeeded');
            $.get(url, {}, (response) => {
                this.adboardItems = response.adboardItems;
                this.loading = false;
            });
        }
    },
    watch: {
        loading: function (val, oldVal) {
            this.$broadcast('loadingActivity', val, oldVal);
        }
    }
});


/**
 * User activity list for adboard
 */
Vue.component('adboard-user-activity', {
    template: '#adboard-user-activity-template',
    props: {
        limit: {
            type: [Number, Boolean],
            default: false,
            coerce: function (val) {
                return parseInt(val);
            }
        }
    },
    data: function () {
        return {
            notifications: false,
            loading: false
        };
    },
    ready: function () {
        this.$emit('dataUpdateNeeded');
    },
    events: {
        dataUpdateNeeded: function () {
            this.update();
        }
    },
    methods: {
        update: function () {
            const url = SiteVariables.urls.adboardItemsController + '/user-activities';
            this.loading = true;

            console.log(this.notifications);
            $.ajax({
                url: url,
                data: {limit: this.limit},
                type: 'get',
                success: (response) => {
                    this.notifications = response.notifications;
                    this.loading = false;
                }
            });
        },
        loadMore: function () {
            this.limit = this.limit + 5;
            this.$emit('dataUpdateNeeded');
        }
    }
});


/**
 * Adboard sidebar index page
 */
Vue.component('adboard-index-sidebar', {
    template: '#adboard-index-sidebar-template'
});


/**
 * Adboard content index page
 */
Vue.component('adboard-index-content', {
    template: '#adboard-index-content-template',
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    data: function () {
        return {
            loading: false
        };
    },
    events: {
        loadingActivity: function (val, oldVal) {
            this.loading = val;
        }
    }
});



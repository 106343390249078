Vue.component('video-platform-hcp-management-tabs', {
    template: "#video-platform-hcp-management-tabs-template",
    mixins: [MCMApp.videoPlatformTabsMixin],
    props: {
        actionUrls: {
            type: Object
        }
    },
    data() {
        return {
            activeTab: 0, // First tab is always chosen by default
            numOfRequests: ''
        };
    },
    created() {
        this.getNumOfRequests();
    },
    methods: {
        selectTab(id) {
            this.getNumOfRequests();
            this.activeTab = parseInt(id);
        },
        isActiveTab: function (id) {
            return this.activeTab === parseInt(id);
        },
        getNumOfRequests() {
            $.post(this.actionUrls.getNumOfPendingRequests, {}, (response) => {
                if (response.success === true) {
                    this.numOfRequests = response.data.hcps.length;
                }
            });
        },
        switchTab(tab){
            this.activeTab = tab;
        }
    },
});
Vue.component('project-cards', {
    template: '#project-cards-template',
    props: {
        url: {
            type: String,
            required: true
        }
    },
    data: function () {
        return {
            projects: false,
            loading: false,
        };
    },
    computed : {
        initialCardNumber(){
            return Number(this.initialCount);
        }
    },
    created: function () {
        this.updateProjects();
        $('#app-modal').on('hidden.bs.modal', (e) => {
            this.updateProjects();
        });
    },

    events: {
        'projectsUpdated': function (val, oldVal) {
            Vue.nextTick(function () {
                 McmInitMasonryCardItems();
            });
        }
    },
    methods: {
        updateProjects: function () {
            this.loading = true;
            $.get(this.url, null, (response) => {
                this.loading = false;
                if (response.projects !== undefined) {
                    this.projects = response.projects;
                    this.$emit('projectsUpdated');
                }
            });
        }
    }
});
Vue.component('ondemand-poll', {
    template: '<div></div>',
    props: {
        questions: {
            type: Array,
            required: true,
            default: []
        },
        player: {
            type: Object,
            required: true
        },
        url: {
            type: String,
            required: true,
        },
        //can skip mode is mainly for accreditator role
        //who needs to check video fastly by skipping but
        //still being in accreditation mode
        //rarely used, brings complexity to flow but necessary
        //to get approval for accreditations from commities
        canSkipVideo: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {

            /**
             * we store answered and skipped question ids here
             * so we can check before deciding to show
             * them again
             */
            answeredQuestionsIds: [],
            skippedQuestionsIds: [],
            currentVideoTimeInSeconds: 0,
        };
    },
    computed: {

        /**
         * Question object of currently displayable question
         * But final decision to might depend on other factors
         * like if it is displayed before etc.
         */
        currentDisplayableQuestion() {

            //as canSkipVideo mode bring too much unnecessary complexity
            //to this code, this mode is processed in a different method
            //to keep this part clean and clear
            if (this.canSkipVideo === true) {
                return this.currentDisplayedQuestionInSkipMode();
            }

            //questions order by show_time asc so we can get first one while filtering
            //it is in reverse order so first question to filter will always be closer to current second
            let questions = _.sortBy(this.questions, (question) => question.show_time).reverse();

            let displayableQuestions = questions.filter((question) => {
                const notPassedTime = (this.currentVideoTimeInSeconds >= question.show_time),
                    notSkipped = (this.skippedQuestionsIds.indexOf(question.id) === -1);

                return (notPassedTime && notSkipped);
            });

            return (displayableQuestions[0] !== undefined) ? displayableQuestions[0] : false;
        },

        /**
         * displayedQuestion Method
         * @return {*}
         */
        displayedQuestion() {
            //if not exists yet or it is displayed already
            if (!this.currentDisplayableQuestion || this.answeredQuestionsIds.indexOf(this.currentDisplayableQuestion.id) > -1) {
                return false;
            }

            return this.currentDisplayableQuestion;
        }

    },
    created() {
        McmEventBus.$on('ondemandPollQuestionReplied', this.ondemandPollQuestionReplied);
        McmEventBus.$on('ondemandPollQuestionSkipped', this.ondemandPollQuestionSkipped);
    },
    ready: function () {
        this.player.addHandler("currenttimechanged", this.currentTimeChanged);

        if (this.questions && this.questions.length > 0) {
            this.checkInitialAnsweredQuestions();
        }
    },
    watch: {

        /**
         * When displayedQuestion is a question obj:
         * - reset current answer
         *
         * @param question
         * @param oldVal
         */
        displayedQuestion(question, oldVal) {
            if (false === oldVal && 'object' === typeof question) {
                McmEventBus.$emit('ondemandPollQuestionDisplayed', question, this.url, this.player);
            }
        },

        currentVideoTimeInSeconds(val, oldVal) {
            // this is used only in canSkipVideo mode as we can no more
            // rely on the linear watch mode
            // show poll question if its time is due but not displayed
            if (!this.canSkipVideo && !this.displayedQuestion) {
                return;
            }

            if (val > this.displayedQuestion.show_time) {
                McmEventBus.$emit('ondemandPollQuestionDisplayed', this.displayedQuestion, this.url, this.player);
            }
        }
    },
    methods: {

        /**
         * Event handler for player currenttimechanged event
         * @param data
         */
        currentTimeChanged(data) {
            if (data.currentTime) {
                this.currentVideoTimeInSeconds = Number(data.currentTime);
            }
        },

        /**
         * ondemandPollQuestionReplied Method
         * @param question
         */
        ondemandPollQuestionReplied(question) {
            this.answeredQuestionsIds.push(question.id);
        },

        /**
         * ondemandPollQuestionSkipped Method
         * @param question
         */
        ondemandPollQuestionSkipped(question) {
            this.skippedQuestionsIds.push(question.id);
        },

        /**
         * Checking if accreditation_results is filled for question
         * which means user already answered this question
         */
        checkInitialAnsweredQuestions() {
            let ids = [];

            this.questions.forEach((question) => {
                if (question.accreditation_results !== undefined) {
                    question.accreditation_results.map((result) => {
                        if (this.answeredQuestionsIds.indexOf(result.poll_id) === -1) {
                            this.answeredQuestionsIds.push(result.poll_id);
                        }
                    });
                }
            });
        },

        currentDisplayedQuestionInSkipMode() {
            //questions order by show_time asc so we can get first one while filtering
            //it is in reverse order so first question to filter will always be closer to current second
            let questions = _.sortBy(this.questions, (question) => question.show_time);

            let displayableQuestions = questions.filter((question) => {
                const notPassedTime = (this.currentVideoTimeInSeconds >= question.show_time),
                    notSkipped = (this.skippedQuestionsIds.indexOf(question.id) === -1),
                    notAnswered = (this.answeredQuestionsIds.indexOf(question.id) === -1);


                // for can skip video we dont check time past to trigger passedtime questions
                //we check for not answered instead
                return ((notSkipped && notAnswered));

            });

            return (displayableQuestions[0] !== undefined) ? displayableQuestions[0] : false;
        }
    }
});
// Vue video platform tabs mixin
// -- Generic mixin for holding and switching tabs
//-------------------------------------------------------------

var MCMApp = MCMApp || {};

MCMApp.videoPlatformTabsMixin = {
    props: {
        tabs: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            activeTab: 0, // First tab is always chosen by default
        };
    },
    methods: {
        selectTab(id) {
            this.activeTab = parseInt(id);
        },
        isActiveTab(id) {
            return this.activeTab === parseInt(id);
        },
    },
};

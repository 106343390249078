var MCMApp = MCMApp || {};

MCMApp.Forms = MCMApp.Forms || {};

MCMApp.Forms.AddTouchpointModalFormViewModel = Backbone.Model.extend({

    default : {
        selected : false,
    }
});


MCMApp.Forms.AddTouchpointModalFormViewClass = Backbone.View.extend({



    events: {
        "click .selection-box": "selectionBoxChange",
        "submit form": "formSubmit"
    },

    initialize: function () {

        this.model.on('change:selected',function(m){
            var selected = m.get('selected');
            if(true === selected) {
                $('.create-new-touchpoint__buttonbox').removeClass('hidden');
            } else {
                $('.create-new-touchpoint__buttonbox').addClass('hidden');

            }
        });

    },

    selectionBoxChange: function (e) {
        var _this = this;
        var $el = $(e.currentTarget);

        //unselect others
        var $boxesEl = $el.parent();
        $boxesEl.find('.selection-box').each(function () {

            $(this).find('input').prop('checked', false);

            $(this).removeClass('selection-box--selected');
        });
        //make this select this one's radio selected
        $el.find('input').prop('checked', true);
        $el.addClass('selection-box--selected');

        this.model.set('selected',true);

    },


    formSubmit : function(e){
        e.preventDefault();

        var _this = this;
        var $form = $(e.currentTarget);

        var $el = $('#button-for-next');
        $el.append('<i class="fa fa-refresh fa-spin fa-fw"></i>');
        $el.attr('disabled','disabled').addClass('btn-disabled').css({'opacity':0.3});

        $.post($form.attr('action'),$form.serialize(),function(response){

            //$form.append(response);
            response = $.parseJSON(response);

            $el.find('.fa-refresh').remove();
            $el.removeAttr('disabled').removeClass('btn-disabled').css({'opacity':1});

            if( response.success ) {
                // continue to next step on TP creation
                // TODO: there will be a predefined selection screen
                // General info is the temporary one
                // MCMApp.ModalActions.touchpointGeneralDetails(response.message.id);
                MCMApp.ModalActions.touchpointEasyFlow(response.message.id);

            } else {
                alert(response.message);
            }

        });
    }
});
// Add accreditation page actions
//-------------------------------------------------------------

var MCMApp = MCMApp || {};


/**
 * Accreditation add questions functions
 *
 * @type {{InitComponents: MCMApp.AccreditationAddQuestions.InitComponents}}
 */
MCMApp.AccreditationAddQuestions = {

    /**
     * Build all methods
     *
     * @constructor
     */
    InitAll: function () {
        this.InitComponents();
        this.InitModalAppInstance();
    },


    /**
     * Init app instance for vue comps
     *
     * @returns {Vue}
     * @constructor
     */
    InitModalAppInstance: function () {
        return new Vue({
            el: '#app-modal-instance',
            ready(){
                MCMApp.AccreditationAddQuestions.InitMcmElements();
            }
        });
    },


    /**
     * Init mcm elements editors
     *
     * @constructor
     */
    InitMcmElements: function () {
        McmPlatformMainElementsInit();
        McmInitEditor();
    },


    /**
     * Init all components
     *
     * @constructor
     */
    InitComponents: function () {
        this.InitAccreditationQuestion();
        this.InitAccreditationQuestionsAnswers();
        this.InitAccreditationAnswer();
        this.InitAccreditationAnswerCount();
    },


    /**
     * Accreditation question component settings
     *
     * @constructor
     */
    InitAccreditationQuestion: function () {
        Vue.component('accreditation-question', {
            template: '#accreditation-question-template',
            props: {
                url: {
                    type: String,
                    required: false,
                    default: ''
                },
                type: {
                    type: String,
                    required: false
                }
            },
            created: function () {
                this.editQuestion = (this.url && this.url !== null);

                if (this.editQuestion) {
                    this.loadQuestion(this.url);
                }
            },
            data: function () {
                return {
                    question: null,
                    correctAnswerString: null,
                    error: true,
                    noAnswersError: false,
                    wrongAnswerError: false,
                    uniqueAnswerError: false,
                    connectionError: false,
                    noQuestionError: false,
                    accreditationQuestion: null,
                    loading: false,
                    editQuestion: false
                };
            },
            methods: {

                /**
                 * Save questions method -> makes connection to backend action and also validates form before submiting
                 *
                 * @param e
                 */
                saveQuestions: function (e) {
                    const form = $(e.currentTarget),
                        data = form.serialize();

                    let url = form.attr('action'),
                        method = form.attr('method');

                    if (this.editQuestion && !_.isEmpty(this.accreditationQuestion)) {
                        url += '/' + this.accreditationQuestion.id;
                        method = 'put';
                    }

                    this.checkAnswersValue(true);

                    if (this.correctAnswerString !== null && this.error === false) {
                        $.ajax({
                            url: url,
                            type: method,
                            data: data,
                            dataType: 'json',
                            success: (data) => {
                                if (data.response) {
                                    McmEventBus.$emit('updateQuestions', data);
                                    McmEventBus.$emit('updateTouchpointAccreditationOnEditPage');
                                    MCMApp.Modals.appModal.close();
                                }
                            },
                            error: (data) => {
                                console.error(data);
                                this.handleError('connectionError', true);
                            }
                        });
                    } else {
                        if (!this.uniqueAnswerError) {
                            this.handleError('wrongAnswerError', true);
                        }
                    }
                },


                /**
                 * handleError method to handle different kind of errors as specified in the data function of the vue instance
                 *
                 * @param errorDataName
                 * @param errorVal
                 */
                handleError: function (errorDataName, errorVal) {
                    this.error = errorVal;
                    this.$set(errorDataName, errorVal);
                },


                /**
                 * checkAnswersValue method to validate and validate all child component answer fields
                 *
                 * @param checkUniqueAnswers
                 */
                checkAnswersValue: function (checkUniqueAnswers) {
                    checkUniqueAnswers = checkUniqueAnswers || false;

                    let childrenComponents = this.$children[0].$children;
                    let totalAnswerComponents = [];

                    childrenComponents.forEach((value) => {
                        if (value.constructor.name !== 'AccreditationAnswerCount') {
                            totalAnswerComponents.push(value.answer);
                        }
                    });

                    if (totalAnswerComponents.length > 1) {

                        if (_.uniq(totalAnswerComponents).length !== totalAnswerComponents.length && checkUniqueAnswers) {
                            this.handleError('uniqueAnswerError', true);
                            return;
                        }

                        totalAnswerComponents.forEach((value) => {
                            if (value === null || value === '') {
                                this.handleError('wrongAnswerError', true);
                            } else {
                                this.handleError('wrongAnswerError', false);
                            }
                        });

                    } else {
                        this.$emit('noAnswers', true);
                    }
                },


                /**
                 * Load existing question
                 *
                 * @param url
                 */
                loadQuestion: function (url) {
                    this.loading = true;

                    $.ajax({
                        url: url,
                        type: 'get',
                        dataType: 'json',
                        success: (data) => {
                            if (!_.isEmpty(data.accreditationQuestion) && data.accreditationQuestion.question.length > 0) {
                                this.accreditationQuestion = data.accreditationQuestion;
                                this.question = this.accreditationQuestion.question;
                                this.loading = false;
                            } else {
                                this.handleError('noQuestionError', true);
                            }
                        },
                        error: (data) => {
                            console.error(data);
                            this.handleError('connectionError', true);
                        }
                    });
                }
            },
            events: {

                /**
                 * Append correct answer string which is being sent from a child component.
                 * Also remove any errors if visible.
                 *
                 * @param answer
                 */
                correctAnswer: function (answer) {
                    this.correctAnswerString = answer;
                    this.handleError('wrongAnswerError', false);
                },


                /**
                 * Handle wrongAnswerError data from the child components
                 *
                 * @param errorVal
                 */
                hasError: function (errorVal) {
                    this.handleError('wrongAnswerError', errorVal);
                },


                /**
                 * Handle noAnswers data from the child components
                 *
                 * @param errorVal
                 */
                noAnswers: function (errorVal) {
                    this.handleError('noAnswersError', errorVal);
                },


                /**
                 * Validate all answers which is beign triggerd from a child component
                 */
                updateForm: function () {
                    this.checkAnswersValue();
                }
            },
            watch: {

                /**
                 * Check question input and validate form on input
                 *
                 * @param val
                 */
                question: function (val) {
                    if (val !== '') {
                        this.checkAnswersValue();
                    }
                },


                /**
                 * Watch loading init elements platform
                 *
                 * @param val
                 * @param oldVal
                 */
                loading: function (val, oldVal) {
                    if (val === false) {
                        MCMApp.AccreditationAddQuestions.InitMcmElements();
                    }
                }
            },
            computed: {

                /**
                 * Render accreditation answers to a computed data property
                 *
                 * @returns {*}
                 */
                accreditationAnswers: function () {
                    if (!_.isEmpty(this.accreditationQuestion) && this.accreditationQuestion.accreditation_answers.length > 0) {
                        return this.accreditationQuestion.accreditation_answers;
                    } else {
                        return [];
                    }
                }
            }
        });
    },


    /**
     * Accreditation questions answers component settings
     *
     * @constructor
     */
    InitAccreditationQuestionsAnswers: function () {
        Vue.component('accreditation-question-answers', {
            template: '#accreditation-question-answers-template',
            props: {
                answersData: {
                    type: Array,
                    required: false,
                    default: () => {
                        return [];
                    }
                }
            },
            created: function () {
                if (this.answersData.length > 0) {
                    this.answers = this.answersData;
                }
            },
            ready: function () {
                this.sortAnswers();
            },
            data: function () {
                return {
                    answers: ['new0', 'new1']
                };
            },
            methods: {

                /**
                 * sortAnswers method to sort all answers in the list
                 */
                sortAnswers: function () {
                    const answersContainer = $('#accreditation-question-empty-container');

                    if (answersContainer.length > 0) {
                        answersContainer.sortable();
                    }
                },


                /**
                 * Add new answer to the list
                 */
                addNewAnswer: function () {
                    let prevAnswersLen = this.answers.length;
                    let newAnswersCount = this.answers.length === 0 ? 1 : this.answers.slice(-1)[0] + 1;


                    console.log(prevAnswersLen, newAnswersCount);
                    this.answers.push(newAnswersCount);

                    //TODO: remove dispatch in favor of eventbus
                    if ((prevAnswersLen === 0 || prevAnswersLen === 1) && this.answers.length > 1) {
                        this.$dispatch('noAnswers', false);
                    } else {
                        this.$dispatch('updateForm');
                    }
                }
            },
            watch: {

                /**
                 * Watch the answers input and init mcm element
                 */
                answers(){
                    MCMApp.AccreditationAddQuestions.InitMcmElements();
                }
            },
            events: {

                /**
                 * removeAnswer method to remove a selected answer
                 *
                 * @param instance
                 * @param answersIndex
                 */
                removeAnswer: function (instance, answersIndex) {
                    this.$children.$remove(instance);
                    this.answers.splice(answersIndex, 1);

                    if (this.answers.length < 2) {
                        this.$dispatch('noAnswers', true);
                    } else {
                        this.$dispatch('updateForm');
                    }
                }
            }
        });
    },


    /**
     * Accreditation answer component settings
     *
     * @constructor
     */
    InitAccreditationAnswer: function () {
        Vue.component('accreditation-answer', {
            template: '#accreditation-answer-template',
            props: {
                answerIndex: {
                    type: Number,
                    required: true
                },
                answerValue: false
            },
            created: function () {

                if (this.answerValue) {
                    this.answerValueSet = !_.isEmpty(this.answerValue) && !_.isEmpty(this.answerValue.answer);
                }

                if (this.answerValueSet) {
                    this.answer = this.answerValue.answer;
                    this.correct = this.checked = this.answerValue.correct > 0;
                }
            },
            data: function () {
                return {
                    correct: false,
                    answer: null,
                    error: false,
                    checked: false,
                    answerValueSet: false
                };
            },
            watch: {

                /**
                 * Watch correct input and append correct answer to parent component
                 */
                correct: function () {
                    if (this.answer !== null && this.answer !== '') {
                        this.$dispatch('correctAnswer', this.answer);
                    } else {
                        this.handleError(true);
                    }
                },


                /**
                 * Watch answer input and change vals of correct and error handling
                 *
                 * @param val
                 */
                answer: function (val) {
                    if (val !== '') {
                        this.handleError(false);
                    } else {
                        this.handleError(true);
                    }

                    if (this.correct) {
                        this.$dispatch('correctAnswer', this.answer);
                    }

                    this.$dispatch('updateForm');
                }
            },
            methods: {

                /**
                 * removeAnswer method to remove a answer input and the index from the counts array for ordering
                 */
                removeAnswer: function () {
                    this.$dispatch('removeAnswer', this, this.answerIndex);
                    this.$remove();
                },


                /**
                 * handleError method to handle error and send to parent component
                 *
                 * @param errorVal
                 */
                handleError: function (errorVal) {
                    this.error = errorVal;
                    this.$dispatch('hasError', this.error);
                }
            },
            computed: {

                /**
                 * answerName method
                 *
                 * Answer name returns the name attribute for the answer input
                 * If it is a new answer its name will start with new
                 *
                 * @returns {*}
                 */
                answerName: function () {
                    if (this.answerValueSet) {
                        return 'answer[' + this.answerValue.id + ']';
                    }
                    return 'answer[new' + (this.answerIndex) + ']';

                },


                /**
                 * formInputCorrect method
                 *
                 * Return html input based on the data settings
                 *
                 * @returns {string}
                 */
                formInputCorrect: function () {
                    let input = '<input type="radio" name="correct" v-model="correct"';

                    if (this.checked) {
                        input += 'checked="checked"';
                    }

                    return input + ' />';
                }
            }
        });
    },


    /**
     * Accreditation answer count component settings
     *
     * @constructor
     */
    InitAccreditationAnswerCount: function () {
        Vue.component('accreditation-answer-count', {
            template: '#accreditation-answer-count-template',
            props: {
                answers: {
                    type: Array,
                    required: true
                }
            },
            events: {

                /**
                 * removeAnswerCount to remove the answer count
                 */
                removeAnswerCount: function () {
                    this.$remove();
                }
            }
        });
    }

};
var MCMApp = MCMApp || {};

MCMApp.Templates = MCMApp.Templates || {};


MCMApp.Templates.Services = {
    packageCompare: function (servicePackageId) {
        return new Vue({
            el: '#services_compare_container',
            data: {
                service_package_id: servicePackageId
            },
            compiled: function () {
                this.updateCompareList();
            },
            methods: {
                updateCompareList: function () {
                    const url = SiteVariables.urls.servicePackagesController + '/compare/' + this.service_package_id;

                    $.get(url, {}, function (response) {
                        $('#services_compare_content').html(response);
                    });

                },

            }
        });
    }
};
// Meetingpoint view page actions
//-------------------------------------------------------------

var MCMApp = MCMApp || {};

/**
 *
 * @type {{InitEditAppointment: MCMApp.EditAppointment.InitEditAppointment}}
 */
MCMApp.EditAppointment = {

    /**
     * This is initilized in /meetingpoint/view/
     * @param touchpointId
     * @param areaId
     * @param selector
     * @returns {Vue}
     * @constructor
     */
    InitEditAppointment: function (touchpointId, areaId, selector) {
        return new Vue({
            mixins: [MCMApp.touchpointMixin, MCMApp.hasTemplatePackageEditCards],
            el: selector,
            data: {
                touchpointId: touchpointId,
                displayed: {
                    details: true,
                    participants: false,
                    speakers: false,
                    reports: false,
                    surveys: false
                },
                currentStep: 'details',
                edit: false,
                invalidEmails: [],
                areaId: areaId
            },
            watch: {

                /**
                 * Watch for edit event
                 * @param val
                 * @param oldVal
                 */
                edit: function (val, oldVal) {
                    McmPlatformMainElementsInit();
                    McmInitEditor();
                }
            },
            created: function () {
                McmInitEditor();
                McmPlatformMainElementsInit();
            },
            events: {

                /**
                 * Toggle section event to show/hide content
                 * @param section
                 */
                toggleSection: function (section) {
                    this.toggleSection(section);
                },

                /**
                 * Touchpoint update event
                 * @param tp
                 */
                touchpointUpdated: function (tp) {
                    this.edit = false;
                },

                /**
                 * Touchpoint submitted event
                 */
                touchpointSubmitted: function () {
                    MCMApp.Modals.appModal.close();
                }
            },
            methods: {

                /**
                 * Toggle section action to show/hide content based on the options
                 * @param section
                 */
                toggleSection: function (section) {
                    var _this = this;
                    //Just a precaution
                    if (this.displayed[section] === undefined) {
                        return;
                    }
                    _.each(this.displayed, function (val, key) {
                        _this.displayed[key] = false;
                    });
                    this.displayed[section] = true;
                    this.currentStep = section;
                    this.edit = false;
                },

                /**
                 * Add new participants model
                 * @returns {*}
                 */
                addNewParticipantsModel: function () {
                    return MCMApp.MeetingpointModalActions.addNewParticipants(this.touchpointId);
                },

                /**
                 * Add new speaker models
                 * @returns {*}
                 */
                addNewSpeakersModel: function () {
                    return MCMApp.MeetingpointModalActions.addNewSpeakers(this.areaId, this.touchpointId);
                },

                /**
                 * Shows all available methods to send emails
                 * @returns {*}
                 */
                sendMeetingpointEmailsModel: function () {
                    return MCMApp.MeetingpointModalActions.sendMeetingpointEmails(this.areaId, this.touchpointId);
                },

                /**
                 * Edit template action
                 * @returns {*}
                 */
                editTemplate: function () {
                    return MCMApp.MeetingpointModalActions.editTemplate(this.areaId, this.touchpointId);
                },

                uncheckIfSelected: function (event) {
                    event.preventDefault();
                    event.stopPropagation();

                    const $el = $(event.currentTarget);

                    var $input = $('#' + $el.attr('for'));
                    $(".template-package-label").removeClass('template-package-label--selected');
                    if ($input.is(':checked')) {
                        $input.prop('checked', false);
                        $('#tp-detail-form').prepend('<input type="hidden" name="template_package_id" value="" id="hidden-template-id">');

                    } else {
                        $input.prop('checked', true);
                        $el.addClass('template-package-label--selected');
                        $('#hidden-template-id').remove();
                    }
                },


                /**
                 * Submit function of addNewParticipantsModel
                 * @param e event
                 */
                onSubmitParticipants: function (e) {
                    var _this = this;
                    //first check for validation of email addresses
                    var emailLines = $(e.currentTarget).find('textarea.participant-email-list').val().split('\n');
                    this.invalidEmails = [];
                    var constraints = {
                        emailaddress: {
                            email: true
                        }
                    };
                    _.each(emailLines, function (val) {
                        console.log(val, validate({emailaddress: val}, constraints));
                        if (validate({emailaddress: val}, constraints) !== undefined) {
                            _this.invalidEmails.push(val);
                        }
                    });
                    if (this.invalidEmails.length === 0) {
                        this.onSubmitSaveTouchpoint(e);
                    }
                }
            }
        });
    },

    InitAddParticipants: function (touchpointId) {
        return new Vue({
            el : '#mp-add-new-participants',
            mixins: [MCMApp.touchpointMixin,MCMApp.VueMixins.closeAppModal],
            data: {
                touchpointId: touchpointId,
                invalidEmails: [],
            },
            events : {
                formSubmitCompleted : function(){
                    return this.closeModal();
                }
            },
            methods : {

                /**
                 * Submit function of addNewParticipantsModel
                 * @param e event
                 */
                onSubmitParticipants: function (e) {
                    var _this = this;
                    //first check for validation of email addresses
                    var emailLines = $(e.currentTarget).find('textarea.participant-email-list').val().split('\n');
                    this.invalidEmails = [];
                    var constraints = {
                        emailaddress: {
                            email: true
                        }
                    };
                    _.each(emailLines, function (val) {
                        console.log(val, validate({emailaddress: val}, constraints));
                        if (validate({emailaddress: val}, constraints) !== undefined) {
                            _this.invalidEmails.push(val);
                        }
                    });
                    if (this.invalidEmails.length === 0) {
                        this.onSubmit(e);
                    }
                }
            }
        });
    }
};
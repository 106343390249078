var MCMApp = MCMApp || {};

MCMApp.Forms = MCMApp.Forms || {};



MCMApp.Forms.TouchpointGeneralDetailModalFormViewClass = Backbone.View.extend({

    events: {

        "submit form": "formSubmit",
        "keyup .touchpoint-link-input" : "updateLinkInput",
    },

    initialize: function () {

        McmPlatformMainElementsInit();

        $('.datepicker').datepicker({
            "dateFormat": "dd-mm-yy",
            minDate : new Date(),
            onSelect: function(dateText, inst) {
                //$('.date--tpdate').val(dateText);
                //$("#datepicker").datepicker("destroy");
            }
        });

        $.datetimepicker.setLocale('en');
        $('.datetimepicker').datetimepicker({
            format:'d-m-Y H:i:s',
            minDate:0,
            maxDate:moment(moment().add(2,'years')).format("Y/MM/DD"),
            step:30,
            validateOnBlur:true,
            yearStart:new Date().getFullYear(),
            yearEnd:new Date().getFullYear() + 2,
            dayOfWeekStart:1,

        });

        $('.timepicker').datetimepicker({
            format:'H:i',
            step:5,
            validateOnBlur:true,
            datepicker:false,


        });
    },
    updateLinkInput : function(e){
        var $el = $(e.currentTarget);
        $("#url-preview").text($el.val());
    },



    formSubmit: function (e) {
        e.preventDefault();

        var _this = this;
        var $form = $(e.currentTarget);

        console.log($form.attr('action'), $form.serialize());

        $form.find('.btn').addClass('hidden');

        // give loading message
        $form.find('.message-box').html(MCMApp.Helpers.Alerts.info('<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>'));

        $.post($form.attr('action'), $form.serialize(), function (response) {
            response = $.parseJSON(response);
            console.log(response);
            if (response.id > 0) {
                $form.find('.message-box').html(MCMApp.Helpers.Alerts.success('<i class="fa fa-check"></i> Update Successful'));
                // close modal
                MCMApp.Modals.appModal.closeAfter(1000);
                // refresh page
                window.location.reload();
            }
        });
    }


});
var MCMApp = MCMApp || {};

MCMApp.Forms = MCMApp.Forms || {};

MCMApp.Forms.HubFormViewClass = Backbone.View.extend({

    events: {

		"click .move-item-to-other-list" : "moveItemToOtherList",
    },

    initialize: function() {
        //init connected sortable list
        $( "#hub-originals, #hub-selected" ).sortable({
          connectWith: ".connectedSortable"
        }).disableSelection();
    },


    moveItemToOtherList : function(e){
        e.preventDefault();
        var _this =this;
		var $el = $(e.currentTarget);
        var $listItem = $el.parents('.hub_form__list-item');

        //find in which list and send it to the other
        var inOriginals = $listItem.parents('#hub-originals').length;

        //move to the other list
        if(inOriginals) {
            $listItem.detach().appendTo($('#hub-selected'));
        } else {
            $listItem.detach().appendTo($('#hub-originals'));
        }
	},




});


MCMApp.Forms.HubForm = new MCMApp.Forms.HubFormViewClass({
    "el": '#add-hub-form-container'
});

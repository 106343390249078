Vue.component('video-platform-request-status', {
    template: '#video-platform-request-status-template',

    props: {
        areaId: {
            type: Number,
            required: true,
        },
        actionUrls: {
            type: Object,
            required: true,
            validator(value) {
                const urls = ['get', 'update'];
                return urls.every(url => typeof value[url] === 'string');
            },
        },
    },

    data() {
        return {
            isLoading: false,
            displayArchived: false,
            consultationList: [],
            statusModel: {}
        };
    },

    computed: {
        filteredConsultationList() {
            return (this.displayArchived) ? this.consultationList : this.consultationList.filter(item => !item.archived);
        },
    },

    methods: {
        fetchRequestList() {
            this.isLoading = true;

            $.get(this.actionUrls.get, {
                area_id: this.areaId, // Show requests only for current channel
            })
                .done(response => {
                    if (response && response.success) {
                        this.consultationList = response.data;
                    }
                    else {
                        console.warn('Failed to fetch Consultation List', response);
                    }
                })
                .fail(err => {
                    console.warn('Failed to fetch Consultation List', err);
                })
                .always(() => {
                    this.isLoading = false;
                });
        },

        /**
         * @param {Number} requestId
         */
        updateRequestStatus(requestId) {
            this.update(requestId, { notes: this.statusModel[requestId], });
        },

        archiveRequest(request) {
            this.update(request.id, {
                archived: !request.archived,
                notes: this.statusModel[request.id],
            });
        },

        update(requestId, data) {
            this.isLoading = true;

            $.post(`${this.actionUrls.update}/${requestId}`, data)
            .done(response => {
                if (response.success) {
                    this.fetchRequestList();
                }
                else {
                    console.warn('Failed to update consultation request data');
                }
            })
            .fail(err => {
                console.warn('Failed to update consultation request data', err);
            })
            .always(() => {
                this.isLoading = false;
            });
        },

        formatDate(utcDate) {
            return moment(utcDate).format('YYYY-MM-DD, HH:mm');
        },
    },

    created() {
        this.fetchRequestList();
    },
});

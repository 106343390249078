var MCMApp = MCMApp || {};

MCMApp.Templates = MCMApp.Templates || {};


MCMApp.Templates.ServiceTypes = {
    serviceTypes : function (settingId,serviceTypesList) {
        return new Vue({
            el: '#service-types-page',
            data: {
                serviceTypes: serviceTypesList,
                settingId: settingId,
                parentItem: false,
                parentId: 0,
                service: false,
                formState: false
            },

            created: function () {
                var _this = this;
                this.refreshList();
            },
            ready: function () {
                var _this = this;
                $(window).load(function () {
                    _this.initSorting();

                });
                _this.initSorting();
            },

            compiled: function () {
                $(window).scroll(function () {

                    if (window.scrollY > 600) {

                        $('#service-types-page__form').addClass('service-types-page__form--fixed');
                    } else {

                        $('#service-types-page__form').removeClass('service-types-page__form--fixed');
                    }
                });


            },


            methods: {
                refreshList: function () {
                    var _this = this;
                    var url = SiteVariables.urls.serviceTypesController + '/threaded/' + this.settingId;

                    this.resetForms();

                    $.get(url, {}, function (response) {
                        serviceTypesList.list = JSON.parse(response);

                        Vue.nextTick(function () {
                            _this.initSorting();
                        });


                    });

                },


                initSorting: function () {
                    var _this = this;

                    $(".service-cards-list").sortable({
                        stop : function (event, ui) {
                            console.log('binded event', ui.item.find('.sorting-element').val());

                            _this.updateSort(ui.item);
                        }
                    });
                    $(".service-cards-list").disableSelection();

                },

                updateSort: function ($dragged) {
                    var _this = this;
                    var $parentItem = $dragged.parent();

                    var data = $parentItem.find('.sorting-element').serialize();


                    var url = SiteVariables.urls.servicesController + '/reorder/';

                    $.post(url, data, function (response) {

                        response = JSON.parse(response);


                        if (response.success === true) {

                            //reset and close forms
                            _this.resetForms();

                            //refresh values
                            _this.refreshList();


                            $.snackbar({
                                content: response.message,
                                style: "text-center alert alert-success",
                                timeout: 1000
                            });

                        } else {
                            $.snackbar({
                                content: response.message,
                                style: "text-center alert alert-danger",
                                timeout: 5000
                            });
                        }
                    });
                },

                resetForms: function () {
                    this.makeSelectedParent(false);
                    this.makeSelectedService(false);
                    this.changeFormsView(false);
                    $('.new-item-name,.new-item-price,.new-item-description').val('');
                },

                changeFormsView: function (formView) {
                    var _this = this;
                    //$('.new-item-name,.new-item-price,.new-item-desc,.reset-on-resetinput').val('');
                    this.formState = formView;
                    if (false !== this.formState) {
                        setTimeout(function () {
                            _this.scrollToForms();
                        }, 300);

                    }
                },

                makeSelectedParent: function (parentItem) {
                    if (parentItem === false) {
                        this.parentItem = false;
                        this.parentId = 0;
                    } else {
                        this.parentItem = parentItem;
                        this.parentId = this.parentItem.id;
                    }
                },

                makeSelectedService: function (service) {
                    //$('.new-item-name,.new-item-price,.new-item-desc,.reset-on-resetinput').val('');
                    this.service = service;
                    console.log(this.service);
                },

                addService: function (serviceType) {
                    this.resetForms();
                    this.makeSelectedParent(serviceType);
                    this.changeFormsView('add-service');

                },

                editService: function (service) {
                    this.resetForms();
                    this.makeSelectedService(service);
                    this.changeFormsView('edit-service');

                },

                closeEditing: function () {

                },

                addServiceType: function (serviceType) {
                    this.resetForms();
                    this.makeSelectedParent(serviceType);
                    this.changeFormsView('add-service-type');
                },

                editServiceType: function (serviceType) {
                    this.resetForms();
                    this.makeSelectedParent(serviceType);
                    this.changeFormsView('edit-service-type');
                },

                deleteServiceType: function (serviceType) {
                    var confirmation = confirm('Are you sure about deleting this service type?');

                    if (confirmation) {
                        var _this = this;
                        var url = SiteVariables.urls.serviceTypesController + '/delete/' + serviceType.id;
                        $.post(url, {}, function (response) {

                            _this.refreshList();
                        });
                    }
                },
                deleteService: function (service) {
                    var confirmation = confirm('Are you sure about deleting this service?');

                    if (confirmation) {
                        var _this = this;
                        var url = SiteVariables.urls.servicesController + '/delete/' + service.id;
                        $.post(url, {}, function (response) {

                            _this.refreshList();
                        });
                    }
                },

                scrollToForms: function () {


                    if ($('.service-card--active').length) {

                        var topPos = $('.service-card--active').position().top + 300;
                        $('body,html').animate({scrollTop: topPos}, 300);

                    }


                },

                onSubmit: function (event) {
                    var _this = this;

                    var $form = $(event.target);

                    var url = event.target.getAttribute('action');
                    var data = $form.serialize();
                    $.post(url, data, function (response) {



                        //reset and close forms
                        _this.resetForms();

                        //refresh values
                        _this.refreshList();

                    });

                }
            }

        });
    },
    serviceForms : function (serviceTypesList) {
        return new Vue({
            el: '#service-types-page__form',
            data: {
                serviceTypes: serviceTypesList,
            }
        });
    }




};
var MCMApp = MCMApp || {};

MCMApp.Helpers = MCMApp.Helpers || {};

MCMApp.Helpers.Alerts = {

    primary: function (content) {
        return this.alertTemplate('primary', content);
    },

    success: function (content) {
        return this.alertTemplate('success', content);
    },

    info: function (content) {
        return this.alertTemplate('info', content);
    },

    danger: function (content) {
        return this.alertTemplate('danger', content);
    },

    warning: function (content) {
        return this.alertTemplate('warning', content);
    },

    alertTemplate: function (type, content) {
        return '<div class="alert alert-' + type + '" role="alert">' + content + '</div>';
    }

};
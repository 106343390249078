// Define speakers actions for vue
//-------------------------------------------------------------

/**
 *
 * @type {{}}
 */
var MCMApp = MCMApp || {};

/**
 *
 * @type {{editTemplate: MCMApp.SpeakerModalActions.actionName}}
 */
MCMApp.SpeakerModalActions = {

    /**
     * Edit speaker in /speakers/view
     * @param speakerId
     * @param type
     */
    editSpeaker: function (speakerId, type) {
        type = type || null;

        let data = {
            action: 'edit-speaker',
            speaker_id: speakerId,
            type: type
        };
        _handleModalActions('speakers', data, 'lg');
    },

    /**
     * View speakers request in speakers overview index page
     * @param speakerId
     */
    viewSpeakerRequest: function (speakerId, touchpointId) {
        let data = {
            action: 'view-speaker-request',
            speaker_id: speakerId,
            touchpoint_id: touchpointId
        };
        _handleModalActions('speakers', data, 'lg');
    },

    /**
     * Show add speaker modal with an empty speaker entity
     */
    addSpeaker: function () {
        let data = {
            action: 'add-speaker'
        };
        _handleModalActions('speakers', data, 'lg');
    },

    /**
     * Show create event modal with instructions. (it doesn't really creates an event for now)
     */
    createEvent: function () {
        let data = {
            action: 'create-event'
        };
        _handleModalActions('speakers', data, 'lg');
    }

};
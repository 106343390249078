var MCMApp = MCMApp || {};
MCMApp.VueMixins = MCMApp.VueMixins || {};

/**
 * Gives the ability to close modals with closeModal function
 * @type {{methods: {closeModal: MCMApp.VueMixins.closeAppModal.methods.closeModal}}}
 */
MCMApp.VueMixins.closeAppModal = {

    methods: {
        appModalInstance : function(){
            return MCMApp.Modals.appModal;
        },
        closeModal : function(){
            MCMApp.Modals.appModal.close();
        }
    }
};


MCMApp.VueMixins.modalBoxInstance = {
    ready: function () {
        McmPlatformMainElementsInit();
    },
    methods: {

    }
};
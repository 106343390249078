// Define accreditation actions for vue
//-------------------------------------------------------------

/**
 *
 * @type {{}}
 */
var MCMApp = MCMApp || {};

/**
 * Render modals based on javascript call
 *
 * @type {{addQuestion: MCMApp.AccreditationModalActions.addQuestion}}
 */
MCMApp.AccreditationModalActions = {

    /**
     * Add question modal
     *
     * @param type -> to render view and type for accreditation questions
     * @param touchpointId
     */
    addQuestion(type, touchpointId) {
        if (touchpointId < 0) {
            throw 'Error: Parameters not correct !';
        }

        const data = {
            action: 'add-question',
            type: type,
            touchpointId: touchpointId
        };

        _handleModalActions('accreditation', data);
    },


    /**
     * Load question based on question id
     *
     * @param questionId
     * @param touchpointId
     */
    loadQuestion(questionId, touchpointId) {
        if (questionId < 0 || touchpointId < 0) {
            throw 'Error: Parameters not correct !';
        }

        const data = {
            action: 'load-question',
            questionId: questionId,
            touchpointId: touchpointId,
        };

        _handleModalActions('accreditation', data);
    },


    /**
     * Edit question based on question id
     *
     * @param questionId
     * @param touchpointId
     */
    editQuestion(questionId, touchpointId) {
        if (questionId < 0 || touchpointId < 0) {
            throw 'Error: Parameters not correct !';
        }

        const data = {
            action: 'edit-question',
            questionId: questionId,
            touchpointId: touchpointId,
        };

        _handleModalActions('accreditation', data);
    },


    /**
     * Load poll modal
     *
     * @param questionId
     * @param touchpointId
     */
    loadPoll(questionId, touchpointId) {
        if (questionId < 0 || touchpointId < 0) {
            throw 'Error: Parameters not correct !';
        }

        const data = {
            action: 'load-poll',
            questionId: questionId,
            touchpointId: touchpointId,
        };

        _handleModalActions('accreditation', data, null, [true]);
    }

};
/**
 * This gives ability to drag the comment pin
 */
Vue.directive('dragabble-comment-pin', {
    deep: true,
    bind: function () {
        $(this.el)
            .draggable({
                containment: "parent",
                delay      : 300,
                stop       : () => this.vm.$emit('coordinateChanged')
            })
            .css({position: 'absolute'});
    }
});
if (document.getElementById('page-program')) {

    new Vue({
        el: '#page-program',
        data: {},
        ready: function () {

        },
        methods: {
            remindSession: function (e) {

                const entryId = parseInt(e.target.value);
                var url = SiteVariables.urls.programEntryAlertsController + '/register-to-entry/' + entryId;

                if (!e.target.checked) {
                    url = SiteVariables.urls.programEntryAlertsController + '/unregister-to-entry/' + entryId;
                }

                if (isNaN(entryId)) {
                    return false;
                }

                $.post(url, {}, function (response) {
                    console.log(response);
                });
            }
        }
    });
}

// Adboard groups components
//-------------------------------------------------------------

/**
 * description will be added here
 */
Vue.component('adboard-groups-overview', {

    props: ['adboardItemUrl'],

    data: function () {
        return {
            item: [],
            file: []
        };
    },

    ready() {
    },

    methods: {

        onOff(event) {

            let element = $(event.target),
                box = element.parent(),
                elementId = box.data('box'),
                type = element.data('type');

            let _this = $('.' + type + '-box').filter((key, value) => {
                return $(value).data('ref') === elementId;
            });

            if (_this.hasClass('off')) {
                _this.removeClass('off');
                _this.addClass('on');
                element.html('&#45;');
            } else if (_this.hasClass('on')) {
                _this.removeClass('on');
                _this.addClass('off');
                element.html('&#43;');
            }

        },

        /**
         * Go to item view
         */
        goToView() {
            window.location.href = this.adboardItemUrl;
        },

        toggleVersionBox(boxId) {
            const $el = $('#version-box--' + boxId);
            $el.toggleClass('off').toggleClass('on');
            const iconHtml = ($el.hasClass('on')) ? '&#45;' : '&#43;';
            $('#toggle-version-box-icon--' + boxId).html(iconHtml);
        }
    }
});

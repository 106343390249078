// Vue component mcm pre loader
//-------------------------------------------------------------

Vue.component('mcm-loader', {
    template: document.getElementById('mcm-loader-gif-container').innerHTML,
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
});
Vue.component('video-platform-categories', {
    template: '#video-platform-categories-template',
    props: {
        actionUrls: {
            type: Object,
            required: true
        },
        area_id: {
            type: String | Number,
            required: true
        }
    },
    data() {
        return {
            showDeleteQuestionModal: false,
            showDeleteQuestionModalSuccess: false,
            isLoading: true,
            categories: [],
            categoriesLimit: 0,
            statusModel: {},
            editId: 0,
            isAddToggled: false
        };
    },
    created() {
        this.getCategories();
    },
    methods: {
        getCategories() {
            let url = this.actionUrls.urlGetCategories;
            $.get(url, {})
                .done(response => {
                    if (response.success) {
                        this.categories = response.data.categories;
                        this.categoriesLimit = response.data.limit;
                        this.isLoading = false;
                    } else {
                        console.warn('Failed to get the categories!');
                    }
                })
                .fail(err => {
                    console.warn('Failed to get the categories!', err);
                });
        },
        toggleEdit(id) {
            this.editId = id;
        },
        toggleAdd() {
            delete this.statusModel['new'];
            this.isAddToggled = !this.isAddToggled;
        },
        editCategory(categoryId) {
            this.isLoading = true;
            let url = this.actionUrls.urlEditCategory + '/' + categoryId;

            $.post(url, {
                title: this.statusModel[categoryId]
            })
                .done(response => {
                    if (response.success) {
                        this.toggleEdit(0);
                        this.getCategories();
                    } else {
                        console.warn('Failed to edit the category!');
                    }
                })
                .fail(err => {
                    console.warn('Failed to edit the category!', err);
                });
        },
        addCategory() {
            this.isLoading = true;
            let url = this.actionUrls.urlAddCategory;

            $.post(url, {
                title: this.statusModel['new'],
                channel_id: this.area_id
            })
                .done(response => {
                    if (response.success) {
                        this.toggleAdd();
                        this.getCategories();
                    } else {
                        console.warn('Failed to add the category!');
                    }
                })
                .fail(err => {
                    console.warn('Failed to add the category!', err);
                });
        },
        deleteCategory(categoryId) {
            if (confirm("Do you really want to delete this category?")) {
                this.isLoading = true;
                let url = this.actionUrls.urlDeleteCategory + '/' + categoryId;

                $.post(url, {})
                    .done(response => {
                        if (response.success) {
                            this.getCategories();
                        } else {
                            console.warn('Failed to add the category!');
                        }
                    })
                    .fail(err => {
                        console.warn('Failed to add the category!', err);
                    });
            }
        }
    },
    computed: {
        checkIfLimitIsHit() {
            return this.categories.length === this.categoriesLimit;
        }
    }

});

/**
 * enable or disable a bootstrap btn with directive
 * if value is true button gets a spinner icon and becomes disabled
 * if value is false returns to normal state
 */
Vue.directive('disable-button', function (value) {
    var $el = $(this.el);
    if(value) {
        $el.append('<i class="fa fa-refresh fa-spin fa-fw"></i>');
        $el.attr('disabled','disabled').addClass('btn-disabled').css({'opacity':0.5});
    } else {
        $el.find('.fa-refresh').remove();
        $el.removeAttr('disabled').removeClass('btn-disabled').css({'opacity':1});
    }
});
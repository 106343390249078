Vue.component('video-platform-hcp-management-all', {
    template: '#video-platform-hcp-management-all-template',
    props: {
        actionUrls: {
            type: Object,
            required: true
        },
        area_id: {
            type: String | Number,
            required: true
        }
    },
    data() {
        return {
            showDeleteHcpModal: false,
            showDeleteHcpModalSuccess: false,
            showDeleteListModal: false,
            showDeleteListModalSuccess: false,
            items: [],
            itemsInitial: [],
            hcp: {
                info: {}
            },
            numOfRequests: 0,
            active_el: 0,
            search: '',
            disabled: true,
            loading: true,
            focused: {
                index: "",
                id: 0
            },
            countries: "",
            downloadStartDate: null,
            downloadEndDate: null,
        };
    },
    created() {
        // Set initial values for export inputs
        // Start at 1 month in the past
        // End at current date
        var currentDate = new Date();
        this.downloadEndDate = currentDate.toISOString().substr(0, 10);
        currentDate.setMonth(currentDate.getMonth() - 1);
        this.downloadStartDate = currentDate.toISOString().substr(0, 10);

        this.getInitials();
    },
    ready() {
        this.getCountries();
        this.getHcps();
    },
    methods: {
        onUpdate(hcp) {
            this.hcp = hcp;
            this.selectNone();
            this.loading = false;
            this.getInitials();
        },
        getHcps() {
            $.post(this.actionUrls.get, {}, (response) => {

                if (response.success === true) {

                    this.$emit('refresh');

                    if (response.data.hcps.length || response.data.lists.length) {
                        this.loading = false;
                        this.items = response.data;
                        this.numOfRequests = this.items.hcps.length + this.items.lists.length;

                        if (this.numOfRequests) {
                            if (this.items.hcps.length) {
                                this.hcp = this.items.hcps[0];
                                this.focused.id = this.active_el = this.items.hcps[0].id;
                                this.focused.index = "hcps";
                            } else {
                                this.hcp = this.items.lists[0];
                                this.focused.id = this.active_el = this.items.lists[0].id;
                                this.focused.index = "lists";
                            }
                        }
                    } else {
                        this.loading = false;
                    }
                }
            });
        },
        refresh() {
            $.post(this.actionUrls.get, {}, (response) => {
                if (response.success === true) {
                    if (response.data.hcps.length || response.data.lists.length) {
                        this.items = response.data;
                        this.numOfRequests = this.items.hcps.length + this.items.lists.length;
                    }
                }
            });
        },
        getInitials() {
            $.post(this.actionUrls.get, {}, (response) => {
                if (response.success === true) {
                    if (response.data.hcps.length || response.data.lists.length) {
                        this.itemsInitial = response.data;
                    }
                }
            });
        },
        deleteHcp() {
            this.loading = true;
            var url = "/admin/video-platform/HcpUserManagement/deleteHcp/" + this.hcp.id + "/" + this.area_id;
            $.post(url, (response) => {
                if (response.success === true) {
                    this.getHcps();
                    this.showDeleteHcpModalSuccess = true;
                    this.loading = false;
                    if ((this.numOfRequests - 1) == 0) {
                        this.selectNone(); //empty the form and disable all the fields in it.
                        this.active_el = 0; //this is just to disable the search field.
                        this.numOfRequests = 0; //this is just to display the message "No pending requests found..!"
                    }
                }
            });
            //MCMApp.VideoPlatformModalActions.DeleteHcpModal(this.hcp.id, this.area_id, this.hcp.firstname, this.hcp.lastname);
        },
        deleteHcpList() {

            var url = "/admin/video-platform/HcpUserManagement/deleteHcpList/" + this.hcp.id + "/" + this.area_id;
            $.post(url, (response) => {
                if (response.success === true) {
                    this.getHcps();
                    this.showDeleteListModalSuccess = true;
                    if ((this.numOfRequests - 1) == 0) {
                        this.selectNone(); //empty the form and disable all the fields in it.
                        this.active_el = 0; //this is just to disable the search field.
                        this.numOfRequests = 0; //this is just to display the message "No pending requests found..!"

                    }
                }
            });

            //MCMApp.VideoPlatformModalActions.DeleteHcpListModal(this.hcp.id, this.area_id, this.hcp.name);
        },
        selectRequest: function (hcp, index, edit = undefined) {
            this.focused.id = hcp.id;
            this.focused.index = index;

            this.refresh();
            this.hcp = hcp;
            this.activate(hcp.id);

            if (edit !== undefined) {

                if (edit) {
                    if (this.disabled) {
                        this.disabled = false;
                    } else {
                        this.disabled = true;
                    }
                } else {
                    this.disabled = true;
                    if (index == 'hcps') {
                        this.showDeleteHcpModal = true;
                    } else {
                        this.showDeleteListModal = true;
                    }
                }
            }
        },
        activate: function (el) {
            if (this.active_el !== el) {
                this.disabled = true;
            }
            this.active_el = el;
        },
        selectNone() {
            this.refresh();
            this.active_el = -1;
            this.hcp = {};
            this.hcp.info = {};
        },
        getCountries() {
            $.get(this.actionUrls.countries, {}, (response) => {

                if (response.success === true) {
                    this.countries = response.data;
                    this.loading = false;
                } else {
                    this.loading = false;
                }
            });
        },
        closeModal(dataModal) {
            let _this = this;
            setTimeout(function() {
                _this[dataModal] = false;
            }, 100);
        },
        toggleAccreditatorRole(hcp) {
            this.loading = true;

            $.post(
                this.actionUrls.updateAccreditatorRole,
                {
                    id: hcp.id,
                    is_accreditator: !this.hasAccreditatorRole(hcp),
                },
                () => {
                    this.refresh();
                    this.loading = false;
                }
            );
        },
        hasAccreditatorRole(hcp) {
            return hcp.roles.some(role => role.name === 'accreditator');
        },
        download() {
            const url = new URL(location.href);
            url.pathname = this.actionUrls.getHcpExport;
            url.searchParams.set('start', this.downloadStartDate);
            url.searchParams.set('end', this.downloadEndDate);
            window.location.replace(url.href);
        }
    },
    computed: {
        filteredList: function () {
            let search = this.search.toLowerCase();
            let newObj = {};
            newObj.hcps = [];
            newObj.lists = [];

            if (Object.keys(this.items.hcps).length) {
                newObj.hcps = this.items.hcps.filter(hcp => {

                    if(typeof(hcp.firstname) == 'undefined'){hcp.firstname = '';}
                    if(typeof(hcp.lastname) == 'undefined'){hcp.lastname = '';}
                    if(typeof(hcp.email) == 'undefined'){hcp.email = '';}
                    if(typeof(hcp.info.title) == 'undefined'){hcp.info.title = '';}
                    if(typeof(hcp.info.expertise) == 'undefined'){hcp.info.expertise = '';}
                    if(typeof(hcp.info.institution) == 'undefined'){hcp.info.institution = '';}

                    return (
                        hcp.firstname.toLowerCase().match(search) ||
                        hcp.lastname.toLowerCase().match(search) ||
                        hcp.email.toLowerCase().match(search) ||
                        hcp.info.title.toLowerCase().match(search) ||
                        hcp.info.expertise.toLowerCase().match(search) ||
                        hcp.info.institution.toLowerCase().match(search)
                    );
                });
            }

            if (Object.keys(this.items.lists).length) {
                newObj.lists = this.items.lists.filter(list => {
                    return list.name.toLowerCase().match(search);
                });
            }

            return newObj;
        }
    }
});

// Vue component meetingpoint-invitations-table
//-------------------------------------------------------------

Vue.component('meetingpoint-invitations-table', {
    template: '#meetingpoint-invitations-table',
    props: ['touchpointid'],
    ready: function (done) {
        this.update();
    },
    data: function () {
        return {
            invitations: false,
            invitationCount: false,
            loading: true
        };
    },
    watch: {
        projectsListings: function (val, oldVal) {
            McmPlatformMainElementsInit();
        }
    },
    methods: {
        update: function () {
            var _this = this;
            var url = SiteVariables.urls.meetingpointInvitationsController + '/index/' + this.touchpointid;
            this.loading = true;
            $.get(url, {}, function (response) {
                //console.log(response);
                _this.loading = false;
                if (response.invitations) {
                    _this.$set('invitations', response.invitations);
                    _this.$set('invitationCount', response.invitationCount);
                }
            });
        }
    }
});

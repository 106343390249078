Vue.component('video-platform-request-virtual-event', {
    template: '#video-platform-request-virtual-event-template',
    props: {
        actionUrls: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            requestMessage: '',
            showRequestMessage: false,
            showSuccessMessage: false,
            sendingMessage: false
        };
    },
    methods: {
        submitMessage(){
           if(this.requestMessage.trim()){
               this.sendingMessage = true;
               let _this = this;

               //Request Data to send
               let url = this.actionUrls.urlRequestVirtualEvent;
               let data = {
                   msg: _this.requestMessage
               };

               $.post(url, data, function (response) {
                   if (response.success === true) {
                       //on success
                       _this.requestMessage = '';
                       _this.sendingMessage = false;
                       _this.showSuccessMessage = true;
                   }
               });
           }
        },
        toggleMessasgeBlock(){
           this.showRequestMessage = !this.showRequestMessage;
        },
        closeMessageBlock(){
            this.toggleMessasgeBlock();
            this.requestMessage = '';
            this.showSuccessMessage = false;
        }
    }
});
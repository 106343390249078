// Define users actions for vue
//-------------------------------------------------------------

/**
 *
 * @type {{}}
 */
var MCMApp = MCMApp || {};

/**
 *
 * @type {{editUser: MCMApp.UserModalActions.editUser}}
 */
MCMApp.UserModalActions = {

    /**
     *
     * @param userId
     */
    editUser: function (userId) {
        var data = {
            action: 'edit-user',
            user_id: userId
        };
        _handleModalActions('users', data, 'lg');
    },
    /**
     *
     * @param userId
     */
    addUser: function () {
        var data = {
            action: 'add-user',
        };
        _handleModalActions('users', data, 'lg');
    },
    /**
     *
     * @param userId
     */
    addUserWithInitialRole: function (roleName,redirectTo) {
        var data = {
            action: 'add-user',
            role: roleName,
            redirect_to:redirectTo
        };
        _handleModalActions('users', data, 'lg');
    }
};
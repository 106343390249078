Vue.component('video-platform-registration-fields', {
    template: '#video-platform-registration-fields-template',

    props: {
        areaId: {
            type: String|Number,
            required: true,
        },
        getFieldsUrl: {
            type: String,
            required: true,
        },
        addFieldUrl: {
            type: String,
            required: true,
        },
        deleteFieldUrl: {
            type: String,
            required: true,
        },
        setFieldPlacementUrl: {
            type: String,
            required: true,
        },
        setFieldTranslationsUrl: {
            type: String,
            required: true,
        },
        getCountriesUrl: {
            type: String,
            required: true,
        },
        editFieldUrl: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            fields: [],
            loading: false,
            error: null,
            addFieldModel: {
                name: '',
                label: '',
                required: false,
                type: 'TEXT',
            },
            countries: [],
            fieldTranslating: null,
            fieldEditing: null,
        };
    },

    created() {
        this.getFields();
        this.getCountries();
    },

    computed: {
        sortedFields() {
            // creates an array for each position/row and adds its fields from left to right
            return this.fields.reduce((fields, field) => {
                // initialize an array for every position/row in the form
                if (!fields[field.position - 1]) {
                    fields[field.position - 1] = [];
                }

                // add to it's position/row array
                fields[field.position - 1].push(field);

                // Sort so left comes before right
                fields[field.position - 1] = fields[field.position - 1].sort((fieldA) => {
                    if (fieldA.row === 'LEFT') {
                        return -1;
                    }
                    return 1;
                });

                return fields;
            }, []);
        },

        highestPosition() {
            const lastRow = this.sortedFields[this.sortedFields.length - 1];
            return lastRow[lastRow.length - 1].position;
        },
    },

    methods: {
        getFields() {
            this.error = null;
            this.handleRequest($.get(this.getFieldsUrl, {}));
        },

        getCountries() {
            $.get(this.getCountriesUrl, {})
                .done(({ data: countries }) => {
                    this.countries = countries;
                })
                .fail(({ responseJSON: { errors } }) => {
                    this.error = Object.values(errors).join('<br/>');
                });
        },

        openTranslationModal(field) {
            this.fieldTranslating = field;
        },

        closeTranslationModal() {
            // Fixes e.getAttribute is not a function error with jquery
            // copied fix from other component
            setTimeout(() => {
                this.fieldTranslating = null;
            }, 100);
            this.getFields();
        },

        openEditingModal(field) {
            this.fieldEditing = field;
        },

        closeEditingModal() {
            // Fixes e.getAttribute is not a function error with jquery
            // copied fix from other component
            setTimeout(() => {
                this.fieldEditing = null;
            }, 100);
            this.getFields();
        },

        addField() {
            this.error = null;
            const { name, label, required, type } = this.addFieldModel;

            if (
                name     === null ||
                label    === null ||
                required === null ||
                type     === null ||
                !name.length      ||
                !label.length
            ) {
                this.error = 'Please fill in all fields.';
                return;
            }

            this.handleRequest(
                $.post(
                    this.addFieldUrl,
                    {
                        name,
                        label,
                        required,
                        type,
                        areaId: this.areaId,
                        position: this.highestPosition + 1,
                    }
                )
                .done(() => {
                    this.addFieldModel.name = '';
                    this.addFieldModel.label = '';
                    this.addFieldModel.required = false;
                    this.addFieldModel.type = 'TEXT';
                })
            );
        },

        deleteField(fieldID) {
            this.error = null;
            this.handleRequest($.post(this.deleteFieldUrl, { id: fieldID }));
        },

        setPlacement(field, { row, position }) {
            this.error = null;
            const data = {
                id: field.id,
                row: row ? row : field.row,
                position: position ? position : field.position,
            };

            if (data.position <= 0) {
                this.error = 'Field can\'t be placed there because it is already in the first position.';
                return;
            }

            this.handleRequest($.post(this.setFieldPlacementUrl, data));
        },

        handleRequest(request) {
            this.loading = true;
            request
                .done(({ data: fields }) => {
                    this.fields = fields;
                })
                .fail(({ responseJSON: { errors } }) => {
                    // errors can be { email: 'invalid email' };
                    // but also { email: { required: 'required', invalid: 'invalid' } }
                    this.error = Object.values(errors)
                        .reduce((errorMessages, error) => {
                            if (typeof error === 'string') {
                                errorMessages.push(error);
                                return errorMessages;
                            }
                            errorMessages.push(...Object.values(error));
                            return errorMessages;
                        }, [])
                        .join(', ');
                })
                .always(() => { this.loading = false; });
        }
    },
});

/**
 * Card displayed for adboard group on groups page
 */
Vue.component('adboard-group-card', {
    template: '#adboard-group-card-template',
    props: {
        adboardGroup: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            showActions: false
        };
    },

    computed: {
        backgroundStyles() {
            if (!this.adboardGroup.background) {
                return {};
            }
            return {
                'backgroundImage': 'url(' + this.adboardGroup.background + ')'
            };
        }
    },
    methods: {
        goToView: function () {
            window.location.href = this.adboardGroup.view_url;
        },

        toggleActions() {
            this.showActions = !this.showActions;
        },

        /**
         * Edit item modal
         * @return {*|boolean}
         */
        editItem() {
            window.location.href = this.adboardGroup.edit_url;
        },

        /**
         * Delete group action
         * @return {*|boolean}
         */
        deleteGroup() {
            var confirmation = confirm('Do you confirm deleting:'+this.adboardGroup.title);
            if (!confirmation) {
                return;
            }

            $.ajax(SiteVariables.urls.adboardGroupsController + '/delete/' + this.adboardGroup.id, {
                type: 'DELETE',
                data: {},
                dataType: 'JSON',
            }).done(function () {
                window.location.reload();
            });
        },
    }

});

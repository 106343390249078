Vue.component('video-platform-fetch-videos', {
    template: '#video-platform-fetch-videos-template',
    props: {
        actionUrls: {
            type: Object,
            required: true
        },
        global: {
            type: String | Number,
            default: 0
        },
        tab: {
            type: String | Number,
            default: 1
        }
    },
    data() {
        return {
            videos: {},
            pagination: {},
            loading: true,
            page: ""
        };
    },
    ready() {
        this.fetchVideos();
    },
    methods: {
        fetchVideos(page = null) {
            this.videos = {};
            this.loading = true;

            if (page) {
                this.page = page;
            }

            $.get(this.getUrl(), {}, (response) => {
                if (response.success === true) {
                    if (this.tab == 1) {
                        this.videos = response.data.videos;
                        this.pagination = response.data.pagination;
                    } else if (this.tab == 2) {
                        this.videos = response.data;
                    }
                    this.loading = false;
                }
            });
        },
        getUrl() {
            return this.page ? (this.actionUrls.get + ("?page=" + this.page)) : this.actionUrls.get;
        }
    },
    created() {
        let $modal = $('#app-modal');
        let _this = this;
        $modal.on('hidden.bs.modal', function (e) {
            if ($modal.hasClass("delete-success")) {
                _this.fetchVideos();
            }
        });
    }
});

var MCMApp = MCMApp || {};

MCMApp.EmailCampaignModalActions = {
    /**
     * Url of this actions. They all point to same endpoint and
     * differentiate on action parameter
     * This is a method due to late initialization of SiteVariables object
     */
    url: function () {
        return SiteVariables.urls.ajaxModals + "/email-campaigns";
    },

    /**
     *
     * @param data
     * @returns {boolean}
     */
    modalAction: function (data) {
        MCMApp.Modals.appModal.openStatic();
        MCMApp.Modals.appModal.loadContentWithData(this.url(), data);
        return false;
    },

};

var MCMApp = MCMApp || {};

MCMApp.Templates = MCMApp.Templates || {};


MCMApp.Templates.Roles = {
    init : function (savedPermissions,permissionList) {
        return new Vue({
            el: '#add-role-form-container',

            data : {
                savedPermissions: savedPermissions,
                permissions: permissionList
            }
        });
    }
};
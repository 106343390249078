var MCMApp = MCMApp || {};

MCMApp.TouchpointModalActions = {
    /**
     * Url of this actions. They all point to same endpoint and
     * differentiate on action parameter
     * This is a method due to late initialization of SiteVariables object
     */
    url: function () {
        return SiteVariables.urls.ajaxModals + "/touchpoint-edit";
    },

    /**
     *
     * @param data
     * @returns {boolean}
     */
    modalAction: function (data) {
        MCMApp.Modals.appModal.openStatic();
        MCMApp.Modals.appModal.loadContentWithData(this.url(), data);
        return false;
    },

    /**
     * User registrar controls like adding editing users assigned to
     * Touchpoints to register HCPs
     * @param touchpointId
     * @returns {*}
     */
    userRegistrarControl: function (touchpointId) {
        var data = {
            action: 'user-registrar-control',
            touchpoint_id: touchpointId
        };
        return this.modalAction(data);
    }

};

// Vue component - Speakers index overview page
//-------------------------------------------------------------

Vue.component('speakers-overview-wrapper', {
    props: {
        url: {
            type: String,
            required: true
        }
    },
    methods: {
        getData: function (disableLoader) {
            this.$broadcast('loadingCheck', !disableLoader);
            $.get(this.url, null, (response) => {
                this.$broadcast('loadingCheck', false);
                if (response.speakers !== undefined && response.pendingRequests !== undefined) {
                    this.$broadcast('dataUpdated', response);
                }
            });
        }
    },
    events: {
        requestUpdate: function (loader) {
            this.getData(loader);
        }
    },
    ready: function () {
        this.getData();
    }
});

/**
 * Render speakers overview content index page
 */
Vue.component('speakers-overview-content', {
    template: '#speakers-overview-content-template',
    events: {
        dataUpdated: function (response) {
            McmPlatformMainElementsInit();
            this.speakers = response.speakers;
            this.mostBookedSpeakers = response.mostBookedSpeakers;
            this.allApprovedBookedSpeakers = response.allApprovedBookedSpeakers;
            this.approvedSpeakersBookedInUpcomingEvent = response.approvedSpeakersBookedInUpcomingEvent;
        },
        loadingCheck: function (toggle) {
            this.loading = toggle;
        }
    },
    data: function () {
        return {
            speakers: false,
            loading: false,
            mostBookedSpeakers: 0,
            allApprovedBookedSpeakers: 0,
            approvedSpeakersBookedInUpcomingEvent: 0
        };
    }
});

/**
 * Render top 3 speakers overview
 */
Vue.component('speakers-overview-content-row', {
    template: '#speakers-overview-content-row-template',
    props: {
        speaker: {
            type: Object,
            required: true
        }
    }
});

/**
 * Render overview controls
 */
Vue.component('speakers-overview-controls', {
    template: '#speakers-overview-controls-template',
    events: {
        dataUpdated: function (response) {
            McmPlatformMainElementsInit();
            this.requests = response.pendingRequests;
            this.count = response.pendingRequests.length;
        },
        loadingCheck: function (toggle) {
            this.loading = toggle;
        }
    },
    methods: {
        handleRequest: function (type, event) {
            const requestId = $('.stack__item--current').data('request-id');
            const url = '/admin/speakers/' + type + 'Request/' + requestId;
            const $this = this;
            $.ajax({
                url: url,
                type: 'get',
                success: function (data) {
                    if (data.response) {
                        $this.$dispatch('requestUpdate');
                    }
                }
            });
        },
        showRequestModal: function () {
            const speakerId = $('.stack__item--current').data('speaker-id');
            const tpId = $('.stack__item--current').data('touchpoint-id');
            if (speakerId > 0) {
                return MCMApp.SpeakerModalActions.viewSpeakerRequest(speakerId, tpId);
            }
        }
    },
    data: function () {
        return {
            requests: false,
            loading: false,
            count: 0
        };
    }
});

/**
 * Render each request card
 */
Vue.component('speakers-overview-controls-requests', {
    template: '#speakers-overview-controls-requests-template',
    props: {
        request: {
            type: Object,
            required: true
        }
    },
    methods: {
        initRequestsCards: function () {
            let itemsStack = document.getElementById('stack_yuda');
            if (itemsStack) {
                let yuda = new Stack(itemsStack);
                let buttonClickCallback = function (bttn) {
                    const button = bttn || this;
                    button.setAttribute('data-state', 'unlocked');
                };
                document
                    .querySelector('.button--accept[data-stack = stack_yuda]')
                    .addEventListener(clickeventtype, function () {
                        yuda.accept(buttonClickCallback.bind(this));
                    });
                document
                    .querySelector('.button--reject[data-stack = stack_yuda]')
                    .addEventListener(clickeventtype, function () {
                        yuda.reject(buttonClickCallback.bind(this));
                    });
            }
        }
    },
    ready: function () {
        this.initRequestsCards();
    }
});
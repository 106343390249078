// Adboard-schedule-meeting-survey
//-------------------------------------------------------------

/**
 *  Adboard-schedule-meeting-survey-question component
 */
Vue.component('adboard-schedule-meeting-survey-question', {
    template: '#adboard-schedule-meeting-survey-question-template',
    props: {
        question: {
          type: Object,
          required: false,
          default: function (){
            return {
                body: "",
                child_survey_items: []
            };
          }
        },
        clientId: {
            type: Number
        }
    },
    data() {
        return {
        };
    },

    ready: function() {
        this.addNewAnswer();
    },

    methods : {
        addNewAnswer: function () {
            this.question.child_survey_items.push({
              body:"",
              question_type: 'radio',
              client_id: this.clientId
            });
        }
    }

});

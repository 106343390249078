var MCMApp = MCMApp || {};

MCMApp.Pages = MCMApp.Pages || {};

MCMApp.Pages.ListingsUploadViewClass = Backbone.View.extend({

    events: {
        "change .listing-column__field-select": "fieldValueSelected",
        "submit #listing-assign-form": "validateForm",
    },

    selected : [],

    initialize: function() {
        this.fieldValueSelected();
    },

    validateForm: function(e) {
        var $el = $(e.currentTarget);

        if(this.selected.indexOf('email') === -1) {
            alert('You have to assign the Email field to a column!');
            e.preventDefault();
            return false;
        }
    },

    fieldValueSelected: function() {

        var selected = [];

        this.collectSelectedItems();

        //give disabled attr to non selecteds
        _.each(this.selected, function(item, key) {
            $('.listing-column__field-select option[value="' + item + '"]:not(:selected)').attr('disabled', 'disabled');
        });



    },


    collectSelectedItems : function(selected){
        var _this = this;
        this.selected = [];

        //iterate all selects
        $(".listing-column__field-select").each(function() {
            //enable the options first
            $(this).find('option').removeAttr("disabled");
            //get values and find non zeros
            //collect selected values
            var optValue = $(this).val();
            var letter = $(this).data("letter");
            if (optValue != "0") {
                _this.selected.push(optValue);
                //also make the container selected
                $("#listing-column--" + letter).addClass("listing-column--selected");
            } else {

                $("#listing-column--" + letter).removeClass("listing-column--selected");
            }
        });


    }




});

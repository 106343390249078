var MCMApp = MCMApp || {};

/**
 *
 * @type {{}}
 */
MCMApp.touchpointEditDeprecatedMixin = {

    computed : {
        transitionName: function () {
            return '';
        },

        /**
         * Temporary property for transitioning between design styles
         * @deprecated
         * @returns {*}
         */
        noPaddingClass: function () {
            if (this.noPaddingScreens.indexOf(this.currentScreen) > -1) {
                return 'no-padding-force';
            }
            return '';
        }
    }
};
// Adboard-schedule-meeting-survey
//-------------------------------------------------------------

/**
 *  Adboard-schedule-meeting-survey component
 */
Vue.component('adboard-schedule-meeting-survey', {
    template: '#adboard-schedule-meeting-survey-template',
    data() {
        return {};
    },

    ready: function() {
    },

    methods : {
    }
});


Vue.component('video-platform-hcp-management-hcp-form', {
    template: '#video-platform-hcp-management-hcp-form-template',
    props: {
        actionUrls: {
            type: Object,
            required: true,
            validator(value) {
                const urls = ['getRegistrationFields', 'update'];
                return urls.every(url => value.hasOwnProperty(url) && typeof value[url] === 'string');
            },
        },
        hcp: {
            type: Object,
            required: true,
            validator(value) {
                return value.hasOwnProperty('info');
            },
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['updated'],
    data() {
        return {
            languages: {
                "en_US": "English",
                "fr_Fr": "French",
                "es-Es": "Spanish",
                "pt_Br": "Portuguese"
            },
            newHcp: JSON.parse(JSON.stringify(this.hcp)),
            registrationFields: [],
            validationErrors: {},
            showCountryModal: false,
            showValidationErrorsModal: false,
            loading: true,
        };
    },
    watch: {
        hcp(newValue, oldValue) {
            this.newHcp = JSON.parse(JSON.stringify(newValue));
        },
    },
    computed: {
        fieldsToCheck() {
            return [
                {
                    name: 'default_language',
                    type: 'TEXT',
                },
                {
                    name: 'country',
                    type: 'TEXT',
                },
                {
                    name: 'bignumber',
                    type: 'TEXT',
                },
            ].concat(this.registrationFields);
        },
        canSubmit() {
            if (this.hcp.id !== this.newHcp.id) return false;
            if (this.disabled) return false;

            const hasChanged = this.fieldsToCheck.some(field => this.hasFieldChanged(field));
            if (!hasChanged) return false;

            if (!this.requiredFieldsFilled) return false;

            if (this.newHcp.info.country !== this.hcp.info.country) {
                this.showCountryModal = true;
                return false;
            }

            if (this.newHcp.info.country === 'Netherlands' && this.newHcp.info.bignumber == '') {
                return false;
            }

            return true;
        },
        requiredFieldsFilled() {
            return this.fieldsToCheck.every(field => {
                if (field.required === false) return true;

                let newValue = this.isMetaField(field.name) ? this.newHcp.info[field.name] : this.newHcp[field.name];
                if (field.type === 'TEXT' && newValue === '') return false;
                if (field.type === 'CHECKBOX' && Boolean(newValue) !== true) return false;

                return true;
            });

        },
    },
    created() {
        this.getRegistrationFields();
    },
    methods: {
        /**
         * Whether the field is safed in the .info object.
         *
         * @param {string} name
         */
        isMetaField(name) {
            return !['firstname', 'lastname', 'email', 'default_language'].includes(name);
        },
        /**
         * Whether the field has meaningfully changed.
         */
        hasFieldChanged({ name, type }) {
            let newValue = this.isMetaField(name) ? this.newHcp.info[name] : this.newHcp[name];
            let oldValue = this.isMetaField(name) ? this.hcp.info[name] : this.hcp[name];

            if (type === 'CHECKBOX') {
                newValue = Boolean(newValue);
                oldValue = Boolean(oldValue);
            }

            return newValue != oldValue && newValue !== "";
        },
        /**
         * Get the registration fields for the form.
         */
        getRegistrationFields() {
            this.loading = true;

            $.get(this.actionUrls.getRegistrationFields, {}, (response) => {
                if (!response.success) {
                    console.error(response);
                    return;
                }

                this.registrationFields = response.data;
            })
            .always(() => {
                this.loading = false;
            });
        },
        /**
         * Submits the user updates.
         */
        updateRequest() {
            this.loading = true;
            this.showCountryModal = false;

            $.post(this.actionUrls.update, this.newHcp, (response) => {
                if (!response.success) {
                    console.error(response);
                }
                
                MCMApp.VideoPlatformModalActions.UpdateHcpModal();
                this.hcp = JSON.parse(JSON.stringify(this.newHcp));
                this.$emit('updated', this.newHcp);
            })
            .fail((xhr) => {
                console.error(xhr);
                const err = JSON.parse(xhr.responseText);
                this.validationErrors = err.errors;
                this.showValidationErrorsModal = true;
            })
            .always(() => {
                this.loading = false;
            });
        },
    },
});

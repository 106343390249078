// Define meetingpoint hcp actions for vue
//-------------------------------------------------------------

/**
 *
 * @type {{}}
 */
var MCMApp = MCMApp || {};

/**
 *
 * @type {{triggerMeetingpointSurveyAction: MCMApp.MeetingpointHcpModalActions.triggerMeetingpointSurveyAction}}
 */
MCMApp.MeetingpointHcpModalActions = {

    /**
     * Trigger and show meetingpoint modal
     * Actions is going to \Controller\AjaxModalsController.php -> meetingpoint-hcp
     * Template is beign renderd in \Template\AjaxModals\meetingpoint-hcp\trigger-meetingpoint-survey
     * @param touchpointId
     */
    triggerMeetingpointSurveyAction: function (touchpointId) {
        var data = {
            action: 'trigger-meetingpoint-survey',
            touchpoint_id: touchpointId
        };
        _handleModalActions('meetingpoint-hcp', data);
    }
};
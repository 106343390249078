var MCMApp = MCMApp || {};

/**
 * Touchpoint edit accreditation mixin
 *
 * @type {{}}
 */
MCMApp.touchpointEditOnDemandVideosMixin = {
    data: {
        current_ondemand_video: false,
        edit_ondemand_video: false
    },

    methods: {


        deleteOnDemandVideo: function (video) {
            let confirmation = confirm('Are you sure about deleting this video?');

            if (confirmation) {
                this.$emit('touchpointUpdating');
                const url = '/admin/videos/delete-ajax/' + video.id;
                $.post(url, {})

                    .done((data) => {

                        this.edit = false;
                        this.refreshTouchpoint();
                    });
            }

        },

        editOnDemandVideo: function (video) {
            this.current_ondemand_video = video;
            this.edit_ondemand_video = true;
        },

        closeEditOnDemandVideo: function (video) {
            this.current_ondemand_video = false;
            this.edit_ondemand_video = false;
        },

        handleServerError(err, err2, err3) {

            console.log(err, err2, err3);
        },

        updateOnDemandVideo: function () {

            this.$emit('touchpointUpdating');
            const currentVideo = this.current_ondemand_video;

            console.log(currentVideo);

            $.ajax({
                url: SiteVariables.urls.videoControllerHandler,
                data: currentVideo,
                type: 'PATCH',
                dataType: 'JSON',
                success: (response) => {
                    this.edit = false;
                    this.edit_ondemand_video = false;
                    this.refreshTouchpoint();
                },
                error: (err, err2, err3) => this.handleServerError(err, err2, err3)
            });


            $.snackbar({
                content: 'Updated!',
                style: "text-center alert alert-success",
                timeout: 2000
            });
        },

    }
};
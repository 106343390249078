Vue.component('video-platform-manage', {
    template: '#video-platform-manage-template',

    props: {
        showRegistrationForm: Boolean,
        showRequestStatus: Boolean,
    },

    data() {
        const tabs = {
            0: 'My videos',
            1: 'Home page management',
            2: 'HCP management',
            3: 'HCP messages',
            4: 'Categories',
        };

        if (this.showRequestStatus) {
            tabs[5] = 'Request Status';
        }

        if (this.showRegistrationForm) {
            tabs[6] = 'Registration Form';
        }

        return {
            tabs,
        };
    },
    ready() {
        // McmPlatformMainElementsInit();
        // McmInitEditor();
    }
});

/**
 * Accreditation view in TP editing page
 */
Vue.component('touchpoint-accreditation', {
    template: '#touchpoint-accreditation-template',
    props: {
        touchpointId: {
            type: Number
        },
        accreditationData: {
            type: Object
        },
        type: {
            type: String,
            required: false
        }
    },
    methods: {

        /**
         * Open accreditation add/edit/delete page
         */
        openSubSectionQuestions(){
            this.openSubSection('accreditation', this.accreditationData.accreditation);
        },

        /**
         * Open polls add/edit/delete page
         */
        openSubSectionPolls(){
            this.openSubSection('polls', this.accreditationData.accreditation);
        },

        /**
         * Trigger subsection opening
         * @param sectionName
         * @param questions
         */
        openSubSection(sectionName, questions){
            McmEventBus.$emit('openTouchpointEditSubSection', sectionName, 'questions', {
                type: sectionName,
                touchpointId: this.touchpointId,
                questions: questions
            });
        }
    },
    computed: {

        /**
         * Get count of questions
         *
         * @return {number}
         */
        getCount(){
            return this.accreditationData ? this.accreditationData.length : 0;
        }
    }
});

Vue.component('adboard-item-version', {
    template: '#adboard-item-version-template',
    props: ['currentFile', 'currentParentFile', 'adboardFile', 'isApproved'],


    data() {
        return {
            totalCount: 0,
            processedCount: 0,
        };
    },
    computed: {
        /**
         * It is the current file if actually the file or one of
         * its children is the current file
         * @return {boolean}
         */
        isCurrentFile: function () {
            return (this.currentFile.id === this.adboardFile.id || this.currentFile.parent_file_id === this.adboardFile.id);
        },

        countProcessText(){
            return this.processedCount+'/'+this.totalCount;
        }

    },

    created() {
        McmEventBus.$on('adboardChildFileProcessed', (data)=>this.childFileProcessed(data));
        McmEventBus.$on('adboardChildFilePageCounted', (data)=>this.pagesCounted(data));
        McmEventBus.$on('adboardChildFilesProcessingFinished', (data)=>this.fileProcessed(data));
    },

    ready() {
        McmPlatformMainElementsInit();
    },


    methods: {
        /**
         * If file is a parent file set first one as currentfile
         */
        setCurrentFile() {
            if (this.adboardFile.child_adboard_files.length > 0) {
                McmEventBus.$emit('adboardCurrentFileChanged', this.adboardFile.child_adboard_files[0]);
            } else {
                McmEventBus.$emit('adboardCurrentFileChanged', this.adboardFile);
            }
        },

        childFileProcessed(data) {
            if (data.adboard_file_id !== this.adboardFile.id) {
                return;
            }

            this.totalCount = data.total_count;
            this.processedCount = data.processed_count;

        },

        pagesCounted(data) {
            if (data.adboard_file_id !== this.adboardFile.id) {
                return;
            }

            this.totalCount = data.total_count;
        },

        fileProcessed(data) {
            if (data.adboard_file_id !== this.adboardFile.id) {
                return;
            }
            this.setCurrentFile();
        },

        deleteFile() {
            var confirmation = confirm('Do you confirm deleting this version?');
            if(!confirmation){
                return;
            }

            $.ajax(SiteVariables.urls.adboardFilesHandler+'/'+this.adboardFile.id,{
                type: 'DELETE',
                data: {},
                dataType: 'JSON',
            }).done(function() {
                McmEventBus.$emit('adboardItemNeedsRefresh');
            });
        }
    }

});

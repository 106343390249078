var MCMApp = MCMApp || {};

MCMApp.Forms = MCMApp.Forms || {};

MCMApp.Forms.PurchaseEventsViewClass = Backbone.View.extend({

    events: {
        'click .clonable-input-area__add-btn' : "cloneableInputAreaClone"
    },

    initialize: function() {

    },


    cloneableInputAreaClone : function(e){
        var _this = this;
        var $el = $(e.currentTarget);

        var $area = $el.parents('.clonable-input-area');
        var $template = $area.find('.clonable-input-area__template');
        var $clone = $template.clone();
        //reset value and append
        $clone.find('input').val('');
        $clone.removeClass('clonable-input-area__template');
        $clone.appendTo($area.find('.clonable-input-area__inputs'));
        
    }

});
